//---------------------------------------------c-section-video-bg
.c-section-video-bg {
	z-index: 1;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	overflow: hidden;

	&:before {
		z-index: 2;

		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;


		background: #000;

		pointer-events: none;
		opacity: .2;
	}

	.plyr__poster {
		background-size: cover;
	}
}

.c-section-video-bg__frame {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%);

	height: 110%;

	pointer-events: none;
}

.c-section-video-bg__frame__in {
	position: absolute;
	top: 0;
	right: 50%;

	width: 100%;
	height: 100%;
}

.c-section-video-bg__frame__in__video {
	position: absolute;
	left: 0;
	top: 0;

	width: 100%;
	height: 100%;
}

$btn_padding: 1.6rem;

.c-section-video-bg__control {
	z-index: 10;
	position: absolute;

	width: 5.5rem;
	height: 5.5rem;
	padding: $btn_padding;

	color: $white;
	cursor: pointer;
	background-color: transparent;
	border-radius: 50%;

	background-color: rgba(getColor('light'), .9);

	transition: opacity .2s ease-in-out .4s, transform .2s ease;

	&.c-section-video-bg__control--pause-mod {
		transition: opacity .2s ease, transform .2s ease;
		opacity: 1;
	}
}

.c-section-video-bg__control__icon {
	position: absolute;
	top: $btn_padding;
	left: $btn_padding;
	right: $btn_padding;
	bottom: $btn_padding;

	&.c-section-video-bg__control__icon--play-mod {
		opacity: 0;
		margin-left: 3px;

		.c-section-video-bg__control--pause-mod & {
			opacity: 1;
		}
	}

	&.c-section-video-bg__control__icon--pause-mod {
		.c-section-video-bg__control--pause-mod & {
			opacity: 0;
		}
	}
}

// .c-section-video-bg__in {
// 	// z-index: 1;
// 	// position: absolute;
// 	// top: 0;
// 	// left: 0;

// 	width: 100% !important;
// 	height: 100% !important;

// 	object-fit: cover;
// }


@include responsiveFrom('xxl'){
	.c-section-video-bg__frame {
		width: 108vw;
	}
}

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	.c-section-video-bg__control {
		opacity: 0;

		left: 50%;
		bottom: 50%;
		transform: translate(-50%, 50%);
		width: 5.5rem;
		height: 5.5rem;
	}

	.c-section-video-bg.c-section-video-bg--desktop-mod {
		display: none;
	}
}
//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-section-video-bg__control {
		left: 3rem;
		bottom: 3rem;
	}
}
//---------------------------------------------TABLET ONLY###

@include responsiveFrom('md') {
	.c-section-video-bg.c-section-video-bg--mobile-mod {
		display: none;
	}
	.c-section-video-bg__control {

		&:focus {
			opacity: 1;
			outline: 2px solid getColor('light');
		}
	}
}
//---------------------------------------------DESKTOP ONLY

@include responsiveFrom('lg') {
	.c-section-video-bg__control {
		left: 3rem;
		bottom: 2.6rem;
	}
}
//---------------------------------------------DESKTOP ONLY###

//---------------------------------------------c-section-video-bg###


@include responsiveFromTo('xxl', 'xl') {
	.c-section-video-bg__frame {
		width: calc((100vh - 14rem) * 2.6);
	}
}

@include responsiveFromTo('xl', 'lg') {
	.c-section-video-bg__frame {
		width: calc(50.2rem * 2.6);
	}
}

@include responsiveTo('lg'){
	.c-section-video-bg__frame {
		width: calc(55.6rem * 2.2);
	}
}