@use "sass:map";


//VIEWPORTS
$viewports: (
        fullhd: (
                base: 1920
        ),
        xxl:(
                base: 1601
        ),
        xxl_2:(
                base: 1440
        ),
        xl: (
                base: 1201
        ),
        lg: (
                base: 1025
        ),
        lg_2: (
                base: 1024
        ),
        md: (
                base: 769
        ),
        md_2: (
                base: 640,
        ),
        sm: (
                base: 375
        ),
        sm_2: (
                base: 376
        )
);

@mixin responsiveTo($viewportRef) {
  $currBreak: map.get($viewports, $viewportRef);
  $selectedViewport: map.get($currBreak, 'base');
  $viewportTotal: $selectedViewport - 1;
  @media screen and (max-width: $viewportTotal*1px) {
    @content;
  }
}


@mixin responsiveFrom($viewportRef) {
  $currBreak: map.get($viewports, $viewportRef);
  $selectedViewport: map.get($currBreak, 'base');
  $viewportTotal: $selectedViewport;
  @media screen and (min-width: $viewportTotal*1px) {
    @content;
  }
}

@mixin responsiveFromTo($from, $to) {
  $currFrom: map.get($viewports, $from);
  $currTo: map.get($viewports, $to);
  $selectedViewportFrom: map.get($currFrom, 'base');
  $selectedViewportTo: map.get($currTo, 'base');
  $viewportTotalFrom: $selectedViewportFrom - 1;
  $viewportTotalTo: $selectedViewportTo;
  @media (max-width: $viewportTotalFrom*1px) and (min-width: $viewportTotalTo*1px) {
    @content;
  }
}

@mixin mainContainer() {
  $currBreak: map.get($viewports, 'xxl');
  $maxWidth: map.get($map: $currBreak, $key: 'base');
  width: calc(100% - (var(--main-container-pad-x) * 2px));
  max-width: $maxWidth * 1px;
  margin-left: auto;
  margin-right: auto;
}

@mixin grid-responsive() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: toRem(10);
  margin:0;

  @include responsiveFrom(md) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: toRem(10);
  }

  @include responsiveFrom(lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: toRem(10);
  }
}

@mixin grid-mobile() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: toRem(10);
  margin: 0;
}

@mixin grid-tablet() {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: toRem(10);
  margin: 0;
}

@mixin grid-desktop() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: toRem(10);
  margin: 0;
}

@mixin grid-responsive-margin() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: toRem(10);
  margin: 0 toRem(20);

  @include responsiveFrom(md) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: toRem(10);
  }

  @include responsiveFrom(lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: toRem(10);
  }
}

@mixin grid-mobile-margin() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: toRem(10);
  margin: 0 toRem(10);
}

@mixin grid-tablet-margin() {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: toRem(10);
  margin: 0 toRem(20);
}

@mixin grid-desktop-margin() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: toRem(10);
  margin: 0 toRem(20);
}


