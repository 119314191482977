// custom scss for components
.banner__image__with__carousel {
  @media (max-width: 1024px) and (min-width: 769px) {
    .banner__col.banner {
      padding: 0;
    }
  }
}


.banner__image__with__carousel {
  .section__in {
    .banner {

      //revers mood: img dx
      @include responsiveFrom('lg') {
        height: 100%;
      }

      @include responsiveFrom('md') {
        flex-wrap: nowrap;
        @include grid-tablet;
        padding: 0;
        column-gap: 0;
      }

      div.banner__col.banner__col--content-mod {
        flex-basis: 100%;
        padding: 0 $space-s;

        @include responsiveFrom('md') {
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: unset;
          min-width: unset;
          padding: 0;

          grid-column: 5 / 9;
          height: 100%;

        }

        .banner__col__content {

          @include responsiveFrom('md') {
            height: 100%;
          }


          .c-slider-controls__button {
            margin-right: $space-s;
            width: 100%;
            height: auto;
            margin: 0;
            text-align: center;

            &.c-slider-controls__button--next-mod {
              right: 0;
              grid-column: 4 / 5;

              @include responsiveFrom('md') {
                padding-right: 24%; //custom
              }

              @include responsiveFrom('lg') {
                padding-right: 55%; //custom
              }
            }

            &.c-slider-controls__button--prev-mod {
              left: 0;
              grid-column: 1 / 2;
              top: 50%;
              padding: 0 0 0 6%; //custom

              @include responsiveFrom('md') {
                padding: 0 24% 0 0; //custom
              }

              @include responsiveFrom('lg') {
                padding: 0 55% 0 0; //custom
              }

              div {
                transform: rotate(180deg) translateY(-50%);
              }
            }

            i {
              font-size: 3rem;
            }
          }

          .banner__col__slider {

            height: 100%;
            display: flex;
            flex-direction: column;

            .banner__col__slider__title {
              @extend %typo-h4;
              font-weight: 400;
              color: getColor('primary');
              padding: $space-xs $space-s;
              margin-bottom: $space-s;

              @include responsiveFrom('md') {
                padding: $space-xs $space-l;
                margin-bottom: 0;
              }
            }

            .banner__col__slider__in {
              @include grid-mobile;
              max-width: unset;

              @include responsiveFrom('md') {
                padding: $space-l 0 $space-l $space-l;
                flex-basis: 100%;
                align-content: center;
              }

              @include responsiveFrom('lg') {
                padding: $space-l;
              }

              .swiper.swiper-initialized.swiper-horizontal {
                grid-column: 2 / 4;
                width: 100%;

                .c-product.c-product--banner-mod {
                  padding: 0;

                  .c-product__in {

                    @include responsiveFrom('lg') {
                      margin: 0 -.5rem; //custom
                    }

                    .c-product-label {
                      @include responsiveFrom('md') {
                        margin: $space-s;
                      }

                      @include responsiveFrom('lg') {
                        margin: $space-m;
                        top: 0;
                        left: 0;
                      }
                    }

                    .c-product__image-wrapper {
                      margin: 0;
                      height: auto;
                      width: auto;

                      .c-product__image {
                        position: static;
                      }
                    }
                  }

                  .c-product__info {
                    padding: $space-l $space-s;
                  }
                }

              }
            }

          }
        }
      }


      .banner__col__bg {
        //reverse mood: img dx
        display: block;
        padding: 0;
        height: 100%;
        margin-bottom: $space-xl;

        @include responsiveFrom('md') {
          margin: 0;
          grid-column: 1 / 5;
        }

        @include responsiveFrom('lg') {
          margin-left: 0;
          margin-right: 6.4rem;
        }

        img {
          @include responsiveTo('md') {
            position: static;
          }
        }
      }

      // -------- REVERSE MOOD ---------------------------------------------------------------------------

      &.banner--reverse-mod {
        div.banner__col.banner__col--content-mod {
          grid-column: 1 / 5;
          grid-row: 1/2;


          .banner__col__slider__in {

            @include responsiveFrom('md') {
              padding: $space-l $space-l $space-l 0;
            }
          }
        }

        .banner__col__bg {

          //reverse mood: img dx
          @include responsiveFrom('md') {
            grid-row: 1/2;
            grid-column: 5 / 9;
          }
        }
      }

    }
  }

}