
@import "utils/u_mixins";
@import "utils/u_grid";
@import "utils/u_variables";
@import "utils/u_functions";
@import "utils/u-rem";
@import "utils/u-colors";
@import "utils/u-z-indexes";
@import "utils/u-shortcuts";
@import "utils/u-typography-placeholders";
@import "utils/u-generic-placeholders";
@import "utils/u_extends";

//@import "./utils";//NOTE: This index file is injected as scss resource into webpack
@import "./basics";
@import "./vendors";

/* COMMONS COMPONENTS/LAYOUTS TO BE COMPILED INTO main.css and not as chunks */
@import "./components";
@import "./layouts";

// scss is imported in main.scss to avoid fouc
// (other scss are asyncronously imported by js components):
@import './vendors/plyr';
@import "./components/c-section-video-bg.scss";
@import './components/products/c-product-filter-nav';
@import './components/products/c-product-filter';
@import './components/products/c-product-list';
@import './components/products/c-product';
@import './components/products/c-product-info';
@import './components/c-product-size-select';
@import './components/c-header-mini';
@import './components/c-hero';
@import './components/c-slider-controls';
@import './components/с-banner-carousel';
@import './components/c-global-message';
@import './components/size-guide/c-size-guide-popup';
@import './components/cart/c-cart-item';
@import './components/cart/c-cart';
@import './components/c-switchcountry-popup';
@import 'swiper/css';
@import 'components/spinning-wheel';
@import 'components/c-banner-fullimage-with-text';
@import 'components/c-banner-image-with-text';
@import 'components/c-banner-fullimage-with-text-overlay';
@import 'components/c-banner-fullimage-video';
@import 'components/c-banner-image';
@import 'components/c-banner-image-with-quote';
@import 'components/c-banner-image-with-carousel';
@import 'components/c-search-popup';
@import 'components/c-cancel-order-popup';
@import 'components/c-editorial-newsletter';
@import 'components/c-newsletter-popup.scss';

:root {

}

html,
body{
	width:100%;
	height: 100%;
	//scroll-snap-type: y proximity;
	// font-size: 10px;

}

html {
	@include responsiveFrom('fullhd') {
		font-size: calc(100vw / 1920 * 10);
	}
	@include responsiveTo('fullhd') {
		font-size: 10px;
	}
}

body {
	--input-offset: 0 1.4rem .4rem;

	position: relative;

	display: flex;
	height: 100%;

	margin: 0;
	padding: 0;

	overflow-x: hidden;
	overflow: auto;
	opacity: 0;

	background: getColor('light');

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@supports (display: grid) {
		display: block;
	}

	&.body-blocked {
		overflow: hidden;
		touch-action: none;
		-ms-touch-action: none;
		position: relative;
	}

	&.open-zoom-mod {
		position: fixed;
	}

	&.u-no-focus-outline *:focus {
		outline: none;
	}

	&.body--macos_state {
		--input-offset: .4rem 1.4rem 0;
	}
}

.wrapper {
	z-index: $z-i-main;
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;

	body.product-banner-mod & {
		padding-bottom: 5.8rem;
	}
}

.base {
	position: relative;

	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	overflow: hidden;

	width: 100%;
	padding: var(--header-height) 0 0;

	body.disable_top_bar_state & {
		padding-top: calc(var(--header-height) - var(--c-header-top-height));
	}

	body.body--bg-mod & {
		background-color: getColor('gray');
	}
}

.row{
	display:flex;
}

.form-field__error {
	display: none;
}

// TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.base {

		body.header-v2-mod & {
			padding: var(--header-v2-height) 0 0;
		}
	}
}

// MOBILE
@include responsiveTo('md'){
	body {
		&.popup-open-mod,
		&.open-menu-mod {
			overflow: hidden;
		}

		//&.popup-open-mod {
		//	touch-action: pan-y, pan-x;
		//}
	}
	.wrapper {
		// body.listing-page-mod & {
		// 	padding-bottom: 6rem;
		// }
	}

	.base {

		body.header-v2-mod & {
			padding: var(--header-v2-height) 0 0;
		}
	}
}

.grecaptcha-badge {
    z-index: 999999999 !important;
}

// DESKTOP
@include responsiveFrom('lg') {
	.base {

		body.header-v2-mod & {
			padding: var(--header-v2-height) 0 0;
		}
	}

}

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	:root {
		--gl-indent: 1rem;
	}
}
//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	:root {
		--gl-indent: 2rem;
	}
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {

}
//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {
	:root {
		--gl-indent: 2rem;
	}
}
//---------------------------------------------DESKTOP ONLY###