#popup_language {

  @include responsiveTo('md') {
    padding-top: 9.5rem;
  }
  @include responsiveFrom('lg') {
    width: 99rem;
  }

  .c-close-button_custom {

    display: block;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    z-index: 10;

    .c-header-menu__close-btn {
      display: block;
      margin: $space-l;

      i {
        font-size: 2rem;
        @include responsiveFrom('md'){
          transform: translateX(-.6rem);
          display: block;
        }
      }
    }

  }

  .popup-country {

    @include responsiveFrom('md') {
      background-color: getColor('gray');
      display: flex;
      justify-content: space-between;
      gap: $space-xxl;
      padding: 0 $space-l $space-xxl;
    }
  }

  .popup-country__head {
    margin: 0px;
    padding: 0 0 $space-l;
    max-width: unset;
    @include responsiveFrom('md') {
      padding-bottom: $space-xl;
    }
  }

  .popup__title.popup__title--offset-2-mod {
    @extend %typo-h4;
    color: getColor('primary');
    font-weight: 400;
    margin-bottom: $space-s;
    text-align: center;

  }

  .popup__text.popup__text--lg-mod {
    @extend %typo-text-1;
    color: getColor('primary');
    font-weight: 400;
  }

  .popup-country__block__title {
    @extend %typo-text-1;
    color: getColor('primary');
    font-weight: 700;
    padding: $space-l $space-s;
    @include responsiveTo('md') {
      flex-wrap: nowrap;
    }
    @include responsiveFrom('md') {
      padding: $space-l 0;
      // !custom!
      font-size: 2rem;
      line-height: 2.4rem; /* 120% */
      letter-spacing: -0.4px;
    }
  }

  .popup-country__in {
    @include responsiveFrom('md') {
      max-height: unset;
      margin: 0;
      flex-basis: 50%;
    }
  }

  .popup-country__in:has(.popup-country__block--active-mod) {
    border-bottom: none;
  }

  .popup-country__block:first-child {
    @include responsiveFrom('md') {
      max-width: unset;
      padding: 0;
    }
  }

  .popup-country__list {
    @include responsiveTo('md') {
      padding: 0 $space-l $space-l;
    }
    margin: 0;
  }


  .popup-country__list__item.popup-country__list__item {
    margin: $space-l 0;

    @include responsiveFrom('md') {
      margin: 0 0 $space-l;
      flex-basis: 33%;
      padding: 0;
      width: unset;

    }

    .popup-country__link {
      @extend %typo-text-1;
      color: getColor('primary');
      font-weight: 400;
      @include responsiveFrom('md') {
        // !custom!
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem; /* 142.857% */
        letter-spacing: -0.28px;
      }
    }
  }

  .popup-country__block__title__trigger .icon.icon-arrow.right {
    font-size: 1.2rem;
    transition: transform .2s ease;
  }

  .popup-country__block.popup-country__block--active-mod .popup-country__block__title__trigger {
    transform: rotate(0deg);

    .icon.icon-arrow.right {
      transform: rotate(90deg);
      transition: transform .2s ease;
    }
  }

}