#PopupGigyaNewsletterComponent {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;

    z-index: -1 !important;


    @include responsiveFrom('md') {
        justify-content: center;
    }

    &.popup--actived {
        z-index: 6 !important;
    }

    .popup__in {
        pointer-events: auto;
        margin: $space-l $space-s;
        padding: 0;
        background-color: getColor('light');
        width: -webkit-fill-available;
        max-width: 93.5rem;

        @include responsiveFrom('md') {
            display: flex;
            margin: 0 $space-s;
        }

        .popup_close {
            display: block;

            @include responsiveTo('md') {
                position: static;
                padding: $space-m $space-s;
                height: auto;
                width: auto;
                margin-left: auto;
                display: block;
            }

            top: $space-l;
            right: $space-l;

            i {
                font-size: 1.5rem;
            }

        }

        .c-account-hero__bg__picture {
            flex-basis: 50%;

            img {
                @include responsiveFrom('md') {
                    display: block;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }

        .popup-storefront-gigya-wrapper {
            padding: $space-xl $space-s;


            @include responsiveFrom('md') {
                flex-basis: 50%;
                padding: $space-3xl $space-xxl;
            }

        }

        &:has(#gigya-newsletter-thakyou-popup) {

            display: block;

            @include responsiveFrom('md') {
                padding: $space-4xl $space-xxl;
            }

            .c-account-hero__bg__picture {
                display: none;
            }

            .popup-storefront-gigya-wrapper {

                @include responsiveFrom('md') {
                    padding: 0;
                }

            }
        }
    }

}