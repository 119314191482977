.c-slider-controls__button {
	z-index: 8;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: getColor('primary');

	transition: opacity .3s ease, color .3s ease;

	&.swiper-button-lock {
		opacity: 0;
		visibility: hidden;
	}

	.c-product-tabs &,
	.c-editorial-slider__w &,
	.c-full-gallery &,
	.c-product-description &,
	.c-interested-products.c-interested-products--disable-arrows-mod & {

		&.swiper-button-disabled {
			color: getColor('gray-dark');
			pointer-events: none;
		}
	}

	&:disabled {
		color: getColor('gray-dark');
		pointer-events: none;
	}

	&:not([disabled]) {

		&:hover,
		&:focus {
			color: getColor('primary');
		}
	}

	&.c-slider-controls--display-none {
		display: none;
	}

	.icon-arrow_2_right {
		transform: rotate(180deg);
	}
}

.c-editorial-slider__w {
	.c-slider-controls__button--prev-mod {
		div {
			margin-right: 3.2rem;
		}
	}
}

.c-interested-products__slider__w .icon-arrow_left {
	transform: rotate(180deg);
}

@mixin horizonal_pos($pos) {
	&.c-slider-controls__button--next-mod {
		right: 29rem;

		@include responsiveTo('md') {
			right: 1.6rem;
		}

		@include responsiveFrom('md') {
			right: 2.7rem;
		}
		@include responsiveFrom('lg') {
			right: 3.9rem;
		}
	}

	&.c-slider-controls__button--prev-mod {
		transform: rotate(180deg);
		left: 28.9rem;

		left: 1.8rem; //custom
		top: 48%;

		@include responsiveFrom('md') {
			left: 3rem; //custom
		}
		@include responsiveFrom('lg') {
			left: 3.9rem; //custom
		}

		@include responsiveTo('sm') {
			left: 1.8rem;
			top: 48%;

		}
		
		.c-product-hero__gallery__controls & {
			transform: none;
		}
	}
}

.c-slider-controls__icon {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	i {
		font-size: 2rem;
		display: block;

		&::before {
			display: block;
			width: 100%;
		}

		.c-full-gallery & {
			font-size: 1.5rem;

			@include responsiveFrom('md'){
				font-size: 2rem;
				padding-right: $space-xs;
			}
		}
	}
}

.c-not-found-content .c-slider-controls__button,
.c-full-gallery .c-slider-controls__button,
.c-editorial-slider__w .c-slider-controls__button {
	@include horizonal_pos(0);

	&::after {
		z-index: -1;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		background-color: $black;
		content: '';

		transform-origin: center;
		border-radius: .4rem;
	}
}

.c-banner-carousel .c-slider-controls__button {
	color: getColor('light');
}

@mixin gallery_controls($decor_offset, $decor_height, $decor_width, $icon_height, $icon_width) {
	&.c-slider-controls__button--prev-mod::after {
		left: -1.9rem;

		@include responsiveTo('lg') {
			left: -.9rem;
		}

		@include responsiveTo('md') {
			left: -.3rem;
		}
	}

	&.c-slider-controls__button--next-mod::after {
		right: -1.9rem;

		@include responsiveTo('lg') {
			right: -.9rem;
		}

		@include responsiveTo('md') {
			left: -.3rem;
		}
	}

	&::after {
		width: 4.9rem;
		height: 6rem;

		@include responsiveTo('lg') {
			width: 2.9rem;
			height: 4rem;
		}

		@include responsiveTo('md') {
			width: 1.5rem;
			height: 2.3rem;
		}
	}


	.c-slider-controls__icon {
		transform: rotate(180deg);
		height: 2rem;
		width: .9rem;

		color: white;

		@include responsiveTo('md') {
			width: .7255rem;
			height: 1.5rem;
		}
	}
}

@include responsiveFrom('lg') {
	.c-full-gallery .c-slider-controls__button {
		@include gallery_controls(-4.3rem, 12.4rem, 12.6rem, 3.7rem, 2.1rem);

	}

	.c-not-found-content .c-slider-controls__button,
	.c-editorial-slider__w .c-slider-controls__button {
		@include gallery_controls(-3.3rem, 16rem, 16rem, 4.3rem, 2.4rem);
	}

	.banner--slider .c-slider-controls__button,
	.c-must-have-products .c-slider-controls__button {

		@include horizonal_pos(0);
	}

	.banner--slider .c-slider-controls__button {
		margin-top: -4.6rem;
	}

	.c-banner-carousel .c-slider-controls__button {
		width: 3.4rem;
		height: 6rem;
		margin-top: 6rem;

		@include horizonal_pos(14rem);
	}

	.c-model-accessory-list .c-slider-controls__button {
		width: 3.2rem;
		height: 5.7rem;

		margin-top: -5rem;

		@include horizonal_pos(-.8rem);
	}

	.c-interested-products__slider__w .c-slider-controls__button {
		width: 3.2rem;
		height: 4.7rem;

		margin-top: -.8rem;

		@include horizonal_pos(-1.4rem);
	}

	.c-cart-interested-products__slider__w .c-slider-controls__button {
		width: 2rem;
		height: 3.5rem;

		margin-top: -.8rem;

		@include horizonal_pos(-1.9rem);
	}

	.product-tabs__content .c-model-accessory-list .c-slider-controls__button {
		margin-top: 0;
		top: 50%;

		&.c-slider-controls__button--next-mod {
			right: 0; //custom

			i.icon-arrow.right:before {

				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&.c-slider-controls__button--prev-mod {
			left: 0; //custom
			top: 44%; //custom

			i.icon-arrow.left:before {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(180deg);
			}
		}

	}

}

@include responsiveFromTo('lg', 'md') {
	.c-full-gallery .c-slider-controls__button {
		@include gallery_controls(-2.4rem, 7.2rem, 7.4rem, 2.3rem, 1.3rem);
	}

	.c-not-found-content .c-slider-controls__button,
	.c-editorial-slider__w .c-slider-controls__button {
		@include gallery_controls(-2.4rem, 7.2rem, 7.2rem, 2.3rem, 1.3rem);
	}

	.c-product-description .c-slider-controls__button {
		width: 1.4rem;
		height: 2.5rem;

		top: calc((100vh - var(--header-height)) / 2);

		&.c-slider-controls__button--next-mod {
			right: calc(50% + 1.1rem);
		}

		&.c-slider-controls__button--prev-mod {
			left: 1.1rem;
		}
	}

	.c-must-have-products .c-slider-controls__button {

		&.c-slider-controls__button--next-mod {
			right: 0;
			transform: translate(100%, -50%);
		}

		&.c-slider-controls__button--prev-mod {
			left: 0;
			transform: translate(-100%, -50%);
		}
	}

	.banner--slider .c-slider-controls__button {
		width: 2rem;
		height: 3.5rem;
		margin-top: -3rem;

		@include horizonal_pos(0);
	}

	.c-banner-carousel .c-slider-controls__button {
		width: 2.3rem;
		height: 4.2rem;

		@include horizonal_pos(4.2rem);
	}

	.c-model-accessory-list .c-slider-controls__button {
		width: 1.2rem;
		height: 2.1rem;

		margin-top: -2.8rem;

		@include horizonal_pos(.6rem);
	}

	.c-interested-products__slider__w .c-slider-controls__button {
		width: 2.2rem;
		height: 1.7rem;

		margin-top: -.4rem;

		@include horizonal_pos(-2rem);
	}

	.c-cart-interested-products__slider__w .c-slider-controls__button {
		width: 1.4rem;
		height: 2.5rem;

		margin-top: .2rem;

		@include horizonal_pos(0);
	}


	.product-tabs__content .c-model-accessory-list .c-slider-controls__button {
		margin-top: 0;
		top: 48%;

		i.icon-arrow.left {
			transform: rotate(180deg);
		}

		&.c-slider-controls__button--next-mod {
			right: -1rem; //custom
		}

		&.c-slider-controls__button--prev-mod {
			left: -1rem; //custom
			top: 44%; //custom
		}

	}
}

@include responsiveTo('md') {
	.c-full-gallery .c-slider-controls__button {
		@include gallery_controls(-1.2rem, 4.2rem, 4rem, 1.5rem, .9rem);

		.icon.icon-arrow {
			padding-right: 1rem;

			&.left {
				padding: 0 .9rem 0 0;
			}
		}
	}

	.c-not-found-content .c-slider-controls__button,
	.c-editorial-slider__w .c-slider-controls__button {
		@include gallery_controls(-2rem, 6rem, 6rem, 2rem, 1.2rem);
	}

	.c-product-description .c-slider-controls__button {
		width: 1.2rem;
		height: 2.1rem;

		// top: calc((100vh - var(--header-height)) / 2);
		@include horizonal_pos(-.2rem);
	}

	.c-must-have-products .c-slider-controls__button {

		@include horizonal_pos(0);
	}

	.banner--slider .c-slider-controls__button {
		width: 1.2rem;
		height: 2.1rem;
		margin-top: -4.8rem;

		@include horizonal_pos(0);
	}

	.c-banner-carousel .c-slider-controls__button {
		width: 1.9rem;
		height: 3.4rem;
		margin-top: 2rem;

		@include horizonal_pos(.9rem);
	}

	.c-model-accessory-list .c-slider-controls__button {
		width: 1.2rem;
		height: 2.1rem;

		margin-top: -4.8rem;

		@include horizonal_pos(-.7rem);
	}

	.c-interested-products__slider__w .c-slider-controls__button {
		width: 2rem;
		height: 1.4rem;

		margin-top: 0;

		@include horizonal_pos(-1.6rem);
	}

	.c-cart-interested-products__slider__w .c-slider-controls__button {
		width: 2rem;
		height: 1.4rem;

		margin-top: .2rem;

		@include horizonal_pos(-1.6rem);
	}

	.product-tabs__content .c-model-accessory-list .c-slider-controls__button {
		margin-top: 0;
		top: 48%;

		i.icon-arrow.left {
			transform: rotate(180deg);
		}

		&.c-slider-controls__button--next-mod {
			right: -1rem; //custom
		}

		&.c-slider-controls__button--prev-mod {
			left: -1rem; //custom
			top: 44%; //custom
		}

	}

}