.c-show-products{
	display: none;
	align-items: center;
	justify-content: center;
	margin: 32px 0 40px;

	button {
		text-align: center;

		.icon {
			font-size: 2rem;
			text-align: center;
			margin: 0 auto;
		}

		h2 {
			text-decoration: underline;
		}
	}
	
}

//---------------------------------------------c-product-list
.c-product-list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	//filter: drop-shadow(0px 0px 4rem rgba(0, 0, 0, 0.1));
}

//---------------------------------------------c-product-list###

@include responsiveFrom('xl') {
	.c-product-list__item {
    // min-width: 30rem;
		width: 25%;
	}
}

@include responsiveFromTo('xl', 'lg') {
	.c-product-list__item {
		width: 33.333%;
	}
}

//---------------------------------------------DESKTOP All
@include responsiveFrom('lg') {
	.c-product-list {
		margin: 0 -3.3rem -2.8rem 0;
		//padding: 0 3rem;
	}

	.c-product-list__item {
    // min-width: 30rem;
		padding: 0 3.3rem 2.8rem 0;
	}
}
//---------------------------------------------DESKTOP All###

//---------------------------------------------TABLET
@include responsiveFromTo('lg', 'md') {
	.c-product-list {
		margin-bottom: -3.6rem;
	}

	.c-product-list {
		margin: 0 -.9rem -1rem 0;
	}

	.c-product-list__item {
    // min-width: 20rem;
		padding: 0 .9rem 1rem 0;
		width: 25%;
	}
}

// @include responsiveFrom('md') {
// 	.c-product-list__item {
// 		flex: 1 1 25%;
// 	}
// }

//---------------------------------------------TABLET###
//---------------------------------------------MOBILE
@include responsiveTo('md') {
	.c-product-list {
		margin: 0 -.9rem -1rem 0;
	}

	.c-product-list__item {
		width: 50%;
		padding: 0 .9rem 1rem 0;
	}
}
//---------------------------------------------MOBILE###