@include font_face("Inter-Regular", "Inter", $font_path, 400);
@include font_face("Inter-Medium", "Inter", $font_path, 600);
@include font_face("Inter-Bold", "Inter", $font_path, 700);

//@include font_face("icomoon", "icomoon", $font_path, 400);

@font-face {
  font-family: "icomoon";
  src: url($font_path + "/icomoon.ttf"), 
       url($font_path + "/icomoon.woff"),
       url($font_path + "/icomoon.eot");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}