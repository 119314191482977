// custom scss for components
.banner__image__with__quote {

  .banner--quote {

    @include responsiveFrom('lg') {
      @include grid-desktop;
      column-gap: 0;
      padding-left: $space-l;
    }

    &.banner--reverse-mod {
      @include responsiveFrom('lg') {
        padding-right: $space-l;
        padding-left: 0;
      }

      .banner__col__bg {
        @include responsiveFrom('lg') {
          grid-column: 1 / 7;
        }
      }

      .banner__col.banner__col--content-mod {
        @include responsiveFrom('lg') {
          grid-column: 8 / 12;
        }
      }
    }

    .banner__col__bg {
      display: block;
      padding: 0;
      height: 100%;

      @include responsiveFrom('md') {
        flex-basis: 50%;
      }

      @include responsiveFrom('lg') {
        grid-column: 7 / 13;
      }
    }

    .banner__col__bg__img {
      object-fit: contain;
      position: static;
    }

    .banner__col.banner__col--content-mod {
      padding: $space-l $space-s;


      @include responsiveFrom('md') {
        padding: 0 $space-xl;
        flex-basis: 50%;
      }

      @include responsiveFrom('lg') {
        grid-column: 2 / 6;
        grid-row: 1 / 2;
        padding: 0;
      }

      .banner__col__content {
        max-width: unset;
      }

    }

    .banner__col__quote {

      @include responsiveTo('md') {
        padding: 0;
      }

      .banner__col__quote__icon {
        position: static;
      }

      .banner__col__quote__icon i.icon {
        font-size: 2.5rem;

        @include responsiveFrom('lg') {
          font-size: 3rem;
        }
      }

      .banner__col__quote__icon.banner__col__quote__icon--start-mod {
        margin-bottom: $space-xxs;
        padding-bottom: $space-l;

        @include responsiveFrom('lg') {
          padding-bottom: $space-xl;
        }

      }

      .banner__col__quote__icon.banner__col__quote__icon--end-mod {
        padding-top: $space-l;

      }

    }

    .banner__col__quote__text {
      padding: $space-xxs 0;
      margin-bottom: $space-xxs;
    }

    .banner__col__quote__author_text {
      padding: $space-l 0 $space-xxs;
      margin-bottom: $space-xxs;
    }
  }



}