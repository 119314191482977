// custom scss for components
.banner__fullimage__with__text {

  .section__in {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    max-height: unset;



    @include responsiveFrom('md') {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    @include responsiveFrom('lg') {
      @include grid-desktop;
    }


    .banner {
      text-align: center;
      color: white;
      height: 100%;
      min-height: unset;

      @include responsiveFrom('md') {
        display: block;
        height: 100%;
      }

      @include responsiveFrom('lg') {
        grid-column: 2 / 12;
        padding: 0
      }

      div.banner__col.banner__col--content-mod {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;


        @include responsiveFrom('md') {
          margin: 0;
          padding: $space-l $space-xxl;
        }

        @include responsiveFrom('lg') {
          margin: 0;
          padding: $space-3xl;
        }

        a {
          max-width: 30.5rem;
          min-width: unset;
        }

        .banner__col__content {
          padding: $space-l;
          margin: 0 $space-s;

          @include responsiveFrom('md') {
            margin: 0;
            padding: $space-xl;
          }

          @include responsiveFrom('lg') {
            padding: 0;
          }
        }

        .banner__col__content__title {
          @extend %typo-h4;
          font-weight: 400;
          color: getColor('light');

          padding: $space-xxs;
          margin-bottom: $space-xxs;

          @include responsiveTo('lg') {}

          @include responsiveTo('md') {}

        }

        .banner__col__content__subtitle {
          @extend %typo-h6;
          font-weight: 400;
          color: getColor('light');
          margin: 0;
        }

        .banner__col__content__descr {
          @extend %typo-text-1;
          font-weight: 400;
          color: getColor('light');
          padding: $space-l 0 $space-xl;
          margin: 0;
        }
      }
    }
  }

  picture {
    display: block;
    height: inherit;
    max-height: none;
    position: static;
  }

}