.c-product-hero {
  @extend %global_flex_block_row_nowrap_flex-start;
  // max-width: 164.8rem;
  width: 100%;
}

.c-product-hero__content {
  margin-bottom: $space-xl;
}

.c-product-hero__gallery__nav {
  z-index: 2;
  position: absolute;
  top: 4.7rem;
  left: 3.3rem;
}

.c-product-hero__gallery__nav__item {
  margin-bottom: $space-m;

  &:last-child {
    margin: 0;
  }
}



.c-product-description .c-slider-controls__button.c-slider-controls__button--prev-mod.c-slider-controls__button.c-slider-controls__button--prev-mod {
  transform: none;

}



.c-product-hero__gallery__in {
  position: relative;
  height: auto;
  margin: 0 auto;

  .swiper {
    height: auto;

    @include search_cursor();
  }
}

.c-product-hero__gallery__item {
  @extend %global_flex_block_row_wrap_flex-start_center;

  height: 100%;
  width: 100%;
  // box-sizing: border-box;
}

.c-product-hero__gallery__item__w__img {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
}

.c-product-hero__gallery__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  max-height: calc(100vh - var(--header-height));
  width: 100%;
  object-fit: cover;
}

.c-product-hero__gallery__preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 34.4% 0 0 28%;
}



.c-product-hero__gallery__dots {
  margin: $space-l auto 0;
  width: fit-content;

  .swiper-pagination-bullet {
    margin-right: .6rem;

    &:last-child {
      margin: 0;
    }

    &.swiper-pagination-bullet-active .swiper-pagination-bullet-btn {
      background-color: getColor('primary');
      opacity: 1;

      width: 5.6rem;
      height: .4rem;
      transition: width 0.3s ease-in-out;
    }
  }

  .swiper-pagination-bullet-btn {
    border-radius: .4rem;
    background-color: getColor('primary');
    opacity: 0.4;

    width: 1.6rem;
    height: .4rem;
    transition: width 0.3s ease-in-out;
  }
}

// ----------------------------------------------------  close gallery

.c-product-hero__row--offset-mod {
  &:not(:last-child) {
    margin: $space-m 0;
  }
}

.c-product-hero__row {
  .c-product-hero__form & {
    padding-bottom: $space-m;
  }

  &.c-product-hero__row--flex-mob-mod {
    display: flex;
    flex-wrap: wrap;
    padding-top: $space-m;

    &>* {
      width: 100%;
    }
  }
}

#divForSizeSelector {
  padding-bottom: $space-m;
}

.c-product-hero-title-head {
  @extend %global_flex_block_row_nowrap_flex-start;
  align-items: flex-end;
}

.c-product-hero-title-head__text {
  @extend %typo-h4;
  font-weight: 400;
  color: getColor('primary');
}

.c-product-hero-title-head__price {
  @extend %typo-h5;
  color: getColor('primary');
  @extend %global_flex_block_row_wrap_flex-start_center;
  margin: 0 0 0 auto;
  line-height: 3.6rem; // !! custom !!
  flex-wrap: nowrap;
}

.c-product-hero__subtitle {
  width: 100%;
  @extend %typo-text-1;
  font-weight: 400;
  color: getColor('primary');
  padding: $space-xs 0 $space-m;
  margin-bottom: $space-m;
}

.c-product-hero__link {
  font-weight: 700;
  color: getColor('primary');
  @extend %typo-text-2;
  text-decoration: underline;
  transition: all .3s;

  &:hover {
    cursor: pointer;
  }
}

.c-product-description {
  width: 100%;
  margin: 0 auto;
}

.product__info__support {
  text-align: left;

  a {
    @extend %typo-text-2;
    color: getColor('primary');
    font-weight: 400;
    text-decoration: none;

    span {
      text-decoration: underline;
      font-weight: 700;
    }
  }
}

.c-product-hero__char {
  @extend %global_flex_block_row_wrap_flex-start_center;
  @extend %typo-text-2;

  margin-bottom: $space-s;

  &.c-product-hero__char--v2-mod {
    justify-content: space-between;
  }
}

.c-product-hero__char__label {
  margin: 0 4px 0 0;
  font-weight: 400;
  color: getColor('primary');

  &::after {
    content: ':'
  }

  .c-product-hero__char--v2-mod & {
    font-weight: 700;
  }
}

.c-product-hero__char__value {
  display: none;

  &--input-mod {
    background-color: transparent;
    border: none;
    pointer-events: none;
  }

  &--active-mod {
    display: block;
    font-weight: 700;
    color: getColor('primary');
  }
}

.c-product-hero__nav {
  @extend %global_flex_block_row_wrap_flex-start;
  gap: $space-m;
}

.c-product-hero__nav__item {
  @extend %global_flex_block_row_wrap_center_center;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
}

.c-product-hero__nav__item__radio {
  position: absolute;
  opacity: 0;

  // display: none;
}

.c-product-hero__nav__item__w-image,
.c-product-hero__gallery__nav__btn,
.c-popup-product-zoom__thumbs__slider__button {
  position: relative;

  display: block;
  width: 100%;
  height: 100%;

  max-width: 6.7rem;
  max-height: 8rem;

  border: .1rem solid getColor('gray-medium-dark');
  border-radius: .4rem;

  transition: border-color .3s;
  overflow: hidden;

  .c-product-hero__nav__item__radio:checked+&,
  .c-product-hero__nav__item__radio:hover+&,
  .c-product-hero__nav__item__radio:focus+&,
  &.c-product-hero__gallery__nav__btn--active-mod {
    border-color: getColor('primary');
  }
}

.c-product-hero__nav__img,
.c-product-hero__gallery__nav__btn__img,
.product__zoom__nav__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//# Product info add

.c-product-hero__add {
  order: 5;

  .form-field__sublabel {
    color: getColor('gray-dark');
    @extend %typo-text-2;
  }

}

.c-product-hero__add__field {
  display: none;

  .c-product-description--empty-guest-mod & {
    display: block;
  }

  .form_field_label {
    text-transform: uppercase;
  }
}

.c-product-hero__add__message {
  order: 4;
  display: block;
  @extend %typo-text-2;
  color: getColor('gray-dark');

  p {
    margin: 0;
  }

  .c-product-description--added-mod &,
  .c-product-description--empty-logged-mod &,
  .c-product-description--empty-guest-mod &,
  .c-product-description--empty-logged-mod & {
    display: none;
  }

  .c-product-hero__button~& {
    margin-top: $space-m;
  }
}

.c-product-hero__add__message--added-mod,
.c-product-hero__add__message--empty-guest-mod,
.c-product-hero__add__message--empty-logged-mod,
.c-product-hero__button.c-product-hero__button--added-mod,
.c-product-hero__button.c-product-hero__button--empty-mod {
  display: none;
}

.c-product-description--added-mod .c-product-hero__add__message--added-mod,
.c-product-description--empty-guest-mod .c-product-hero__add__message--empty-guest-mod,
.c-product-description--empty-logged-mod .c-product-hero__add__message--empty-logged-mod {
  display: block;
}

.c-product-hero__add button.button {
  margin-top: $space-m;
}

.c-product-description--added-mod .c-product-hero__button {
  display: none;

  &--added-mod {
    display: inline-flex;
  }
}

.c-product-description--empty-logged-mod .c-product-hero__button,
.c-product-description--empty-guest-mod .c-product-hero__button {
  display: none;

  &--empty-mod {
    display: inline-flex;
  }
}

.c-product-hero__support {
  @extend %global_flex_block_row_wrap_center_center;
  width: 100%;
}

.c-product-hero__support__item {
  @extend %global_flex_block_row_nowrap_flex-end_center;

  position: relative;
  text-decoration: none;

  width: 50%;

  transition: opacity .3s;

  &:nth-child(2n + 1) {
    padding-left: 0;
  }

  &:nth-child(2n + 2) {
    justify-content: flex-start;
    padding-right: 0;
  }

  &:not(:last-child) {
    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      height: 4.2rem;
      width: 1px;

      background-color: getColor('gray-dark');
      content: '';
    }
  }
}

a.c-product-hero__support__item:focus,
a.c-product-hero__support__item:hover {
  opacity: .6;
}

.c-product-hero__support__icon {
  min-width: 2.8rem;
  width: 3rem;
  height: 3.4rem;
  color: getColor('light');

  .icon {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.c-product-hero__support__name {
  .c-product-hero__support__span {
    display: block;

    &.c-product-hero__support__span--v2-mod {
      font-weight: 700;
      padding-left: .4rem;
    }
  }
}


.c-product-hero-title-head__price__old {
  @extend %product_price_line;
  color: $black;
}

.c-product-hero-title-head__price__current {
  color: $black;

  .c-product-hero-title-head__price__old+& {
    color: getColor('error');
  }
}

// ----------------- >= TABLET

@include responsiveFrom('md') {

  .c-product-hero {
    @include grid-tablet();
    padding: 0px $space-l;
  }

  .c-product-hero__content {
    grid-column: 5 / 9;
    padding: $space-xl $space-l;
    margin: 0;
  }

  .c-product-hero__gallery {
    grid-column: 1 / 5;
    position: relative;
  }

  .c-product-hero__gallery__item {
    align-items: flex-start;
    align-content: flex-start;
    border-radius: .4rem;
  }

  .c-product-hero__gallery__controls {
    z-index: 50;
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;

  }

  .c-product-description .c-slider-controls__button {
    margin: 0 $space-l;
    position: static;
    transform: none;

    &.c-slider-controls__button--prev-mod {
      transform: none;
    }
  }

  .c-product-hero__content {}

  .breadcrumbs.breadcrumbs--offset-mod {
    margin-bottom: $space-m;
  }

  .c-product-hero__subtitle {}

  .c-product-hero__gallery__dots {

    .swiper-pagination-bullet {
      margin-right: 1rem;
    }
  }

  .c-product-hero-title-head {
    align-items: flex-start;
  }

  .c-product-hero__support {
    flex-wrap: nowrap;
  }

  .c-product-hero-title-head__price {
    line-height: 5.2rem; // !! custom !!
  }
}

// ----------------- TABLET

// ----------------- >= DESKTOP



@include responsiveFrom('lg') {

  .c-product-hero {
    @include grid-desktop;
    margin: 0 auto $space-3xl;
  }

  .c-product-hero__content {
    grid-column: 7 / 12;
    padding: $space-xl 0 $space-xl $space-xl;
  }

  [data-product-form-order-button="product-order-form"].c-product-hero__add {
    width: 60%;
  }

  .c-product-hero__gallery__dots {
    display: none;
  }

  .c-product-hero__gallery {
    grid-column: 1 / 7;
    height: calc(100vh - var(--header-height));
  }

  .c-product-hero__gallery__in {
    height: 100%;

    .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
      height: 100%;
    }
  }


  .c-product-hero__gallery__item__w__img {
    padding-top: 0;
    height: 100%;
  }

  .c-product-hero__gallery__controls {
    display: none;
  }

  .c-product-hero__gallery__in {
    width: 83%;
    margin: 0 0 0 auto;
  }

  .product__label {}

  .c-product-hero-title-head {

    .c-product-banner & {
      padding: 1rem 4.4rem .6rem 0;
    }
  }

  .c-product-hero-title-head__text {}

  .c-product-hero-title-head__price {
    padding-top: .4rem;
  }


  .c-product-hero-title-head__price__old {
    margin-right: 4px;
  }

  .c-product-hero__link {}

  .c-product-hero__row {
    &:not(:last-child) {
      padding: 0 0 1.9rem;
      margin: 0 0 1rem;
    }

    &.c-product-hero__row--offset-mod {
      &:not(:last-child) {
        margin: 0 0 1.2rem;
      }
    }
  }

  .c-product-hero__char {

    &.c-product-hero__char--v2-mod {
      margin: 0 0 2rem;
    }
  }

  .c-product-hero__nav__item {}

  .c-product-hero__form {
    //padding: 0 0 1.6rem;
    //	margin: 0 0 2rem;
  }

  .c-product-hero__support__icon {
    margin: 0 1.4rem 0 0;
  }

  .c-product-hero__button {

    .c-product-hero & {
      margin-bottom: 0;
    }
  }

  .c-product-hero__add__message {
    // margin: 1.4rem 0 0;
  }

  .c-product-hero__support__item {
    padding: 0 4.5rem;
  }

  .c-product-hero__support__span {
    letter-spacing: .005em;
  }
}

@include responsiveFrom('xxl_2') {
  .c-product-hero__gallery__img {
    object-fit: contain;
  }
}

// ----------------- >= DESKTOP

@include responsiveFromTo('lg', 'md') {

  .c-product-hero__gallery__item__w__img {
    padding-top: 118.4%;
  }

  .c-product-hero__gallery__in {
    width: 100%;
  }


  .c-product-hero__support__icon {
    margin: 0 .8rem 0 0;
  }

}

// ----------------- > Mobile


// ----------------- >= Mobile

@include responsiveFromTo('md', 'sm') {
  .c-product-hero__support__item {
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: calc(50%);

    padding: 0 2.4rem 0 1.9rem;
  }
}

@media (max-width: 405px) {
  .product__info {
    display: block;
  }
}

// ----------------- < DESKTOP

@include responsiveTo('lg') {

  .c-product-hero__support__span {}

  .c-product-hero__gallery__nav {
    display: none;
  }

  .c-product-hero__subtitle {}

  .c-product-hero__link {}

  .c-product-hero__form {
    //	padding-bottom: 1rem;
    //margin-bottom: 1.6rem;
  }

  .c-product-hero__gallery__preview {
    display: none;
  }


}

// ----------------- < TABLET

@include responsiveTo('md') {
  .c-product-description {}

  .c-product-hero {
    flex-wrap: wrap;
  }

  .c-product-hero__content {
    padding: $space-m $space-s;
    padding-top: 0;
    margin: 0 0 $space-xl;

    .breadcrumbs--offset-mod {
      margin-bottom: $space-m;
    }
  }

  .c-product-hero__gallery__img {
    max-width: unset;
    object-fit: cover;
  }

  .c-product-hero__gallery__item__w__img {
    padding-top: 124%;
  }

  .c-product-hero__gallery {
    position: relative;
    width: 100%;
    margin: 0 0 $space-xl;
  }

  .c-product-description .c-slider-controls__button.c-slider-controls__button--next-mod.c-slider-controls__button.c-slider-controls__button--next-mod {
    right: 0;
    margin-right: $space-xxs;
  }

  .c-product-description .c-slider-controls__button.c-slider-controls__button--prev-mod.c-slider-controls__button.c-slider-controls__button--prev-mod {
    left: 0;
    margin-left: $space-xxs;
  }

  .c-product-hero-title-head__price {}

  #anchorDescription.c-product-hero__link {
    margin-bottom: $space-m;
  }

  .c-product-hero-title-head--size-mod {
    padding: 1.6rem var(--gl-indent) 1.4rem;

    .c-product-hero-title-head__text {
      display: none;
    }

  }


  .c-product-hero-title-head__price__old {
    margin-right: $space-xxs; //to control
  }

  .c-product-hero__gallery__in {
    width: 100%;
  }

  .c-product-description {
    .c-slider-controls__button--prev-mod {
      transform: none;
    }

    .c-slider-controls__button {
      margin: 0 $space-s;
    }
  }

  .product__info__support {
    margin-bottom: 0;
  }
}

// ----------------- < MOBILE

@include responsiveTo('sm') {
  .c-product-hero__support__item {
    justify-content: center;
    width: 100%;
    padding: 0;

    &:not(:last-child) {
      margin: 0 0 2rem;
    }

    &::after {
      display: none;
    }
  }
}