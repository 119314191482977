/*Icon svg wrapped into .icon element */
.icon{
	display: inline-block;
	// max-width:toRem(24);
	// height:toRem(24);
	@include responsiveFrom('md'){
		// max-width:toRem(26);
		// height:toRem(26);
	}
	svg{
		display: block;
		width: 100%;
		height: 100%;
	}

	&.icon--size-mod {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.icon-play {

	}

}
