@mixin font_face($file_name, $font_name: $file_name, $path: $font_path, $weight: normal, $style: normal) {
	@font-face {
		font-family: quote($font_name);
		src: url($path + $file-name + ".ttf") format("ttf"), url($path + $file-name + ".ttf") format("ttf");
		font-weight: $weight;
		font-style: $style;
	}
}

@mixin bp($width, $direction: max) {
	@if $direction ==min {
		@media screen and (min-width: $width) {
			@content;
		}
	}

	@else {
		@media screen and (max-width: $width) {
			@content;
		}
	}
}

@mixin d3() {
	will-change: transform;
	transform: translateZ(0);
}

@mixin flex_block($flex-direction : row, $flex-wrap: wrap, $content : space-between, $align-items : flex-start, $align-content : flex-start) {

	display: flex;
	flex-direction: $flex-direction;
	flex-wrap: $flex-wrap;
	justify-content: $content;
	align-content: $align-content;
	align-items: $align-items;
}

@mixin mobile_base_style_action_button() {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	min-height: 6rem;
	height: 100%;
	padding: 1rem 2rem;

	font-size: 1.4rem;
	text-align: center;
	letter-spacing: 0.01rem;

	background-color: getColor('light');

	@include responsiveTo('sm') {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@mixin mobile_base_accent_style_button() {
	color: getColor('light');
	background-color: getColor('primary');

	&:hover,
	&:focus {
		background-color: darken(getColor('light'), 15%);
		border: 2px solid getColor('primary');
	}
}

@mixin base_outline() {
	outline: 2px solid getColor('red');
}

@mixin close_cursor() {
	cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"><g clip-path="url(%23clip0_2156_87581)"><path d="M22.662 20.0093L39.4896 2.91423C39.8068 2.59194 39.9792 2.16457 39.9792 1.70917C39.9792 1.25376 39.8068 0.826388 39.4896 0.504104C39.1861 0.195832 38.7517 0.0136719 38.3034 0.0136719C37.8551 0.0136719 37.4206 0.188826 37.1172 0.504104L20.2896 17.5992L3.4551 0.497098C2.84821 -0.119445 1.68958 -0.119445 1.08269 0.497098C0.765447 0.819382 0.593033 1.24676 0.593033 1.70216C0.593033 2.15756 0.765447 2.58494 1.08269 2.90722L17.9103 20.0023L1.08269 37.0974C0.427515 37.7629 0.427515 38.8489 1.08269 39.5145C1.73786 40.1801 2.80683 40.1801 3.4551 39.5145L20.2827 22.4194L37.1103 39.5145C37.4275 39.8368 37.8482 40.0119 38.2965 40.0119C38.7448 40.0119 39.1655 39.8368 39.4827 39.5145C39.7999 39.1922 39.9723 38.7648 39.9723 38.3094C39.9723 37.854 39.7999 37.4266 39.4827 37.1044L22.6551 20.0093H22.662Z" fill="black"/></g><defs><clipPath id="clip0_2156_87581"><rect width="40" height="40" fill="white"/></clipPath></defs></svg>'),auto;
}

@mixin search_cursor() {
	cursor: url("data:image/svg+xml,%3Csvg id='a4000838-d69a-4f9e-a44c-8313f436c96d' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24'%3E%3Cpath d='M10.19,19.72a10.06,10.06,0,0,1-5.52-1.64A9.81,9.81,0,0,1,1,13.71,9.58,9.58,0,0,1,.43,8.09a9.73,9.73,0,0,1,2.72-5A10.05,10.05,0,0,1,8.24.43,10.18,10.18,0,0,1,14,1a9.92,9.92,0,0,1,4.46,3.58,9.59,9.59,0,0,1-1.24,12.29A10,10,0,0,1,10.19,19.72ZM10.19,1A9.29,9.29,0,0,0,5.09,2.5a9.07,9.07,0,0,0-3.39,4,8.87,8.87,0,0,0,2,9.8A9.28,9.28,0,0,0,17.82,15,8.85,8.85,0,0,0,16.67,3.64,9.29,9.29,0,0,0,10.19,1Zm0,19a10.37,10.37,0,0,1-5.66-1.68A10,10,0,0,1,.77,13.81,9.76,9.76,0,0,1,.19,8,9.89,9.89,0,0,1,3,2.92,10.33,10.33,0,0,1,14.08.76a10.09,10.09,0,0,1,4.58,3.68A9.84,9.84,0,0,1,17.39,17,10.35,10.35,0,0,1,10.19,20Zm0-19.48a9.83,9.83,0,0,0-5.38,1.6A9.43,9.43,0,0,0,1.24,6.35a9.28,9.28,0,0,0-.56,5.48,9.42,9.42,0,0,0,2.65,4.86,9.71,9.71,0,0,0,5,2.6,9.84,9.84,0,0,0,5.6-.54,9.62,9.62,0,0,0,4.35-3.49A9.33,9.33,0,0,0,17,3.29,9.79,9.79,0,0,0,10.19.51Zm0,18.74A9.52,9.52,0,0,1,5,17.67a9.19,9.19,0,0,1-3.48-4.15A9,9,0,0,1,.93,8.18,9.14,9.14,0,0,1,3.51,3.45,9.39,9.39,0,0,1,8.34.92a9.58,9.58,0,0,1,5.45.52A9.41,9.41,0,0,1,18,4.85a9.08,9.08,0,0,1-1.18,11.66A9.54,9.54,0,0,1,10.19,19.23Zm0-18a9,9,0,0,0-5,1.47A8.72,8.72,0,0,0,1.94,6.63a8.61,8.61,0,0,0-.52,5.06,8.75,8.75,0,0,0,2.45,4.48,9,9,0,0,0,4.57,2.4,9.19,9.19,0,0,0,5.16-.5,8.93,8.93,0,0,0,4-3.22,8.61,8.61,0,0,0-1.12-11,9,9,0,0,0-6.3-2.56ZM24,23.63l-7.11-7,.63-.62,7.11,7a.48.48,0,0,1,.13.31.44.44,0,0,1-.13.31.46.46,0,0,1-.32.13A.45.45,0,0,1,24,23.63Zm.31.36a.65.65,0,0,1-.27,0,.51.51,0,0,1-.23-.15l-7.27-7.13,1-1,7.29,7.14a.7.7,0,0,1,.19.35.65.65,0,0,1,0,.39.61.61,0,0,1-.26.31.74.74,0,0,1-.39.11Zm-.13-.55.06,0h.15l.06,0a.17.17,0,0,0,0-.26L17.51,16.4l-.27.26,6.92,6.79Z'/%3E%3C/svg%3E%0A"), auto;
}

@mixin style_for_accordion_body {
	max-height: 0;
	opacity: 0;

	transition: all 0.25s ease-out;
	will-change: max-height;
	overflow: hidden;
	pointer-events: none;
}

@mixin style_for_accordion_body_active_state {
	opacity: 1;
	pointer-events: auto;
}

@mixin default_focus_outline($color: getColor('primary')) {
	outline: 2px solid $color;
}

@mixin slider_padding_for_correct_focus() {
	padding: 3px !important; // important for swiper
}

@mixin base_section_bg_image_style() {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}