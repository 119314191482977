//---------------------------------------------c-size-guide-popup
.c-size-guide-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-i-modal;

  width: 100%;
  height: 100%;

  opacity: 0;
  pointer-events: none;

  background-color: rgba(getColor('primary'), .4);

  overflow: hidden;
  transition: opacity .3s ease;

  &.popup--actived {
    opacity: 1;
    pointer-events: auto;
  }
}

.c-size-guide-popup__heading {
  position: absolute;
}

.c-size-guide-popup__btn-close {
  color: getColor('primary');
  transition: color .3s ease;

  height: 2rem;
  width: 2rem;
  margin: $space-l;

  position: absolute;
  top: 0;
  right: 0;

  &:hover,
  &:focus {
    color: getColor('primary');
  }
}

//---------------------------------------------c-size-guide-popup###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
  .c-size-guide-popup__heading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    height: 5.2rem;
    width: 100%;

    padding-left: 1rem;
    padding-right: 1rem;

    background-color: getColor('light');
    content: "";

    @include flex_block(row, wrap, flex-start, center, center);

    // &:before {
    // 	position: absolute;
    // 	left: 0;
    // 	bottom: 0;

    // 	width: 100%;
    // 	height: 1px;

    // 	background-color: getColor('gray');

    // 	content: '';
    // }
  }

  .c-size-guide-popup__heading__logo {
    width: 12.2rem;
    height: 4rem;
    margin: 1.2rem auto 0;
  }

  .c-size-guide-popup__heading__logo__img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  .c-size-guide-popup__btn-close {
  }
}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('md') {

}

//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {

  // .c-size-guide-popup__btn-close {
  // 	width: 1.4rem;
  // 	height: 1.4rem;
  // }
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
  .c-size-guide-popup {
    @include flex_block(row, wrap, flex-start, flex-end, flex-end);
  }

  .c-size-guide-popup__heading__logo {
    display: none;
  }

  .c-size-guide-popup__heading {
    position: static;
    height: 6rem;
  }
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {

}

//---------------------------------------------DESKTOP ONLY###