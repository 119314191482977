@use "sass:math";

%form_field_default {
	display: block;
	width: 100%;
	height: $input_height;
	padding: var(--input-offset);
	font-size: $input_font_size;
	font-family: $base-font-family;
	font-weight: 300;
	text-align: left;
	color: $input_text_color;
	border: 1px solid getColor('gray');
	border-radius: $input_radius;
	background: none;
	outline: none;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-moz-placeholder {
		color: $input_placeholder_color;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

}

%form_field_default_hover {}

%form_field_default_active {
	border-color: getColor('primary');

	&.form-field__input--active-color-mod {
		border-color: getColor('primary');
	}
}

%global_flex_block_e {
	@include flex_block();
}

%global_flex_block_row_wrap_flex-start {
	@include flex_block(row, wrap, flex-start);
}

%global_flex_block_row_wrap_flex-start_stretch {
	@include flex_block(row, wrap, flex-start, stretch);
}

%global_flex_block_row_wrap_flex-start_center {
	@include flex_block(row, wrap, flex-start, center, center);
}

%global_flex_block_row_wrap_flex-start_flex-end {
	@include flex_block(row, wrap, flex-start, flex-end, flex-end);
}

%global_flex_block_row_wrap_flex-start_baseline {
	@include flex_block(row, wrap, flex-start, baseline);
}

%global_flex_block_row_wrap_flex-end {
	@include flex_block(row, wrap, flex-end);
}

%global_flex_block_row_wrap_flex-end_center {
	@include flex_block(row, wrap, flex-end, center, center);
}

%global_flex_block_row_wrap_flex-end_stretch {
	@include flex_block(row, wrap, flex-end, stretch);
}

%global_flex_block_row_wrap_flex-end_baseline {
	@include flex_block(row, wrap, flex-end, baseline);
}

%global_flex_block_row_wrap_center {
	@include flex_block(row, wrap, center);
}

%global_flex_block_row_wrap_center_center {
	@include flex_block(row, wrap, center, center, center);
}

%global_flex_block_row_wrap_center_stretch {
	@include flex_block(row, wrap, center, stretch);
}

%global_flex_block_row_wrap_center_baseline {
	@include flex_block(row, wrap, center, baseline);
}

%global_flex_block_row_wrap_space_between {
	@include flex_block(row, wrap, space-between);
}

%global_flex_block_row_wrap_space_between_stretch {
	@include flex_block(row, wrap, space-between, stretch);
}

%global_flex_block_row_wrap_space_between_center {
	@include flex_block(row, wrap, space-between, center, center);
}

%global_flex_block_row_wrap_space_between_baseline {
	@include flex_block(row, wrap, space-between, baseline);
}

%global_flex_block_row_wrap_space_between_flex-start {
	@include flex_block(row, wrap, space-between, flex-start);
}

%global_flex_block_row_wrap_space_between_flex-end {
	@include flex_block(row, wrap, space-between, flex-end);
}

%global_flex_block_row_nowrap_flex-start {
	@include flex_block(row, nowrap, flex-start);
}

%global_flex_block_row_nowrap_flex-start_stretch {
	@include flex_block(row, nowrap, flex-start, stretch);
}

%global_flex_block_row_nowrap_flex-start_center {
	@include flex_block(row, nowrap, flex-start, center, center);
}

%global_flex_block_row_nowrap_flex-start_flex-end {
	@include flex_block(row, nowrap, flex-start, flex-end);
}

%global_flex_block_row_nowrap_flex-start_baseline {
	@include flex_block(row, nowrap, flex-start, baseline);
}

%global_flex_block_row_nowrap_flex-end {
	@include flex_block(row, nowrap, flex-end);
}

%global_flex_block_row_nowrap_flex-end_center {
	@include flex_block(row, nowrap, flex-start, center, center);
}

%global_flex_block_row_nowrap_flex-end_stretch {
	@include flex_block(row, nowrap, flex-end, stretch);
}

%global_flex_block_row_nowrap_flex-end_baseline {
	@include flex_block(row, nowrap, flex-end, baseline);
}

%global_flex_block_row_nowrap_center {
	@include flex_block(row, nowrap, center);
}

%global_flex_block_row_nowrap_center_center {
	@include flex_block(row, nowrap, center, center, center);
}

%global_flex_block_row_nowrap_center_stretch {
	@include flex_block(row, nowrap, center, stretch);
}

%global_flex_block_row_nowrap_center_baseline {
	@include flex_block(row, nowrap, center, baseline);
}

%global_flex_block_row_nowrap_space_between {
	@include flex_block(row, nowrap, space-between);
}

%global_flex_block_row_nowrap_space_between_stretch {
	@include flex_block(row, nowrap, space-between, stretch);
}

%global_flex_block_row_nowrap_space_between_center {
	@include flex_block(row, nowrap, space-between, center, center);
}

%global_flex_block_row_nowrap_space_between_baseline {
	@include flex_block(row, nowrap, space-between, baseline);
}

%global_flex_block_row_nowrap_space_between_flex-start {
	@include flex_block(row, nowrap, space-between, flex-start);
}

%global_flex_block_row_nowrap_space_between_flex-end {
	@include flex_block(row, nowrap, space-between, flex-end);
}

%global_flex_block_column_wrap_flex-start {
	@include flex_block(column, wrap, flex-start);
}

%global_flex_block_column_wrap_flex-start_stretch {
	@include flex_block(column, wrap, flex-start, stretch);
}

%global_flex_block_column_wrap_flex-start_center {
	@include flex_block(column, wrap, flex-start, center, center);
}

%global_flex_block_column_wrap_flex-start_flex-end {
	@include flex_block(column, wrap, flex-start, flex-end);
}

%global_flex_block_column_wrap_flex-start_baseline {
	@include flex_block(column, wrap, flex-start, baseline);
}

%global_flex_block_column_wrap_flex-end {
	@include flex_block(column, wrap, flex-end);
}

%global_flex_block_column_wrap_flex-end_center {
	@include flex_block(column, wrap, flex-end, center, center);
}

%global_flex_block_column_wrap_flex-end_stretch {
	@include flex_block(column, wrap, flex-end, stretch);
}

%global_flex_block_column_wrap_flex-end_baseline {
	@include flex_block(column, wrap, flex-end, baseline);
}

%global_flex_block_column_wrap_center {
	@include flex_block(column, wrap, center);
}

%global_flex_block_column_wrap_center_center {
	@include flex_block(column, wrap, center, center, center);
}

%global_flex_block_column_wrap_center_stretch {
	@include flex_block(column, wrap, center, stretch);
}

%global_flex_block_column_wrap_center_baseline {
	@include flex_block(column, wrap, center, baseline);
}

%global_flex_block_column_wrap_space_between {
	@include flex_block(column, wrap, space-between);
}

%global_flex_block_column_wrap_space_between_stretch {
	@include flex_block(column, wrap, space-between, stretch);
}

%global_flex_block_column_wrap_space_between_center {
	@include flex_block(column, wrap, space-between, center);
}

%global_flex_block_column_wrap_space_between_baseline {
	@include flex_block(column, wrap, space-between, baseline);
}

%global_flex_block_column_wrap_space_between_flex-start {
	@include flex_block(column, wrap, space-between, flex-start);
}

%global_flex_block_column_wrap_space_between_flex-end {
	@include flex_block(column, wrap, space-between, flex-end);
}

%global_flex_block_column_nowrap_flex-start {
	@include flex_block(column, nowrap, flex-start);
}

%global_flex_block_column_nowrap_flex-start_stretch {
	@include flex_block(column, nowrap, flex-start, stretch);
}

%global_flex_block_column_nowrap_flex-start_center {
	@include flex_block(column, nowrap, flex-start, center);
}

%global_flex_block_column_nowrap_flex-start_flex-end {
	@include flex_block(column, nowrap, flex-start, flex-end);
}

%global_flex_block_column_nowrap_flex-start_baseline {
	@include flex_block(column, nowrap, flex-start, baseline);
}

%global_flex_block_column_nowrap_flex-end {
	@include flex_block(column, nowrap, flex-end);
}

%global_flex_block_column_nowrap_flex-end_center {
	@include flex_block(column, nowrap, flex-end, center);
}

%global_flex_block_column_nowrap_flex-end_stretch {
	@include flex_block(column, nowrap, flex-end, stretch);
}

%global_flex_block_column_nowrap_flex-end_baseline {
	@include flex_block(column, nowrap, flex-end, baseline);
}

%global_flex_block_column_nowrap_center {
	@include flex_block(column, nowrap, center);
}

%global_flex_block_column_nowrap_center_center {
	@include flex_block(column, nowrap, center, center);
}

%global_flex_block_column_nowrap_center_stretch {
	@include flex_block(column, nowrap, center, stretch);
}

%global_flex_block_column_nowrap_center_baseline {
	@include flex_block(column, nowrap, center, baseline);
}

%global_flex_block_column_nowrap_space_between {
	@include flex_block(column, nowrap, space-between);
}

%global_flex_block_column_nowrap_space_between_stretch {
	@include flex_block(column, nowrap, space-between, stretch);
}

%global_flex_block_column_nowrap_space_between_center {
	@include flex_block(column, nowrap, space-between, center);
}

%global_flex_block_column_nowrap_space_between_baseline {
	@include flex_block(column, nowrap, space-between, baseline);
}

%global_flex_block_column_nowrap_space_between_flex-start {
	@include flex_block(column, nowrap, space-between, flex-start);
}

%global_flex_block_column_nowrap_space_between_flex-end {
	@include flex_block(column, nowrap, space-between, flex-end);
}

%clearfix {
	&:after {
		position: static;
		display: block;
		height: 0;
		clear: both;
		content: "";
		visibility: hidden;
	}
}

%circle_ico_style {
	$w: 24px;
	position: absolute;
	width: $w !important;
	height: $w;
	top: 50%;
	margin:  math.div(-$w, 2) 0 0;
	text-align: center;
	line-height: $w;
	border-radius: 50%;
}

%circle_ico_style_small {
	$w: 20px;
	position: absolute;
	width: $w !important;
	height: $w;
	top: 50%;
	margin:  math.div(-$w, 2) 0 0;
	text-align: center;
	line-height: $w;
	border-radius: 50%;
}

%translate3d {
	transform: translateZ(0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

%vert_centr {
	top: 50%;
	transform: translate(0, -50%);
}

%hor_centr {
	left: 50%;
	transform: translate(-50%, 0);
}

%hor_vert_centr {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

%stretch {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

%hover_show {
	opacity: 0;
	transition: opacity .3s ease;
}

%hover_show_parent {
	&:hover,
	&:focus {
		%hover_show {
			opacity: 1;
		}
	}
}

%scroll_rediz_1 {
	&::-webkit-scrollbar {
		width: 6px;
		height: 12px;
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		width: 6px;
		height: 12px;
		border-radius: 12px;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}
}

%text-overflow {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

%z-index_fix_vertical {
	$index: 20;
	$index2: 21;
	@while $index > 0 {

		&:nth-child(#{$index}) {
			z-index: 10 + ($index2 - $index);
		}

		$index: $index - 1;
	}
}

%transition_all {
	transition: all .3s ease;
	will-change: transform;
}

%transition_opacity {
	transition: opacity .3s ease;
	will-change: opacity;
}

%transition_background {
	transition: background .3s ease;
	will-change: background;
}

%transition_color {
	transition: color .3s ease;
	will-change: color;
}

%transition_color_background {
	transition: color .3s ease, background .3s ease;
	will-change: color, background;
}

%transition_transform {
	transition: transform .3s ease;
	will-change: transform;
}

%tranisition_fill {
	transition: fill .3s ease;
	will-change: fill;
}

%rounded_i_w {
	display: block;
	position: relative;
	border-radius: 50%;
	overflow: hidden;
}

%rounded_i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
}

%responsive_animation_wrap{
	position: absolute;
	width: 10%;
	height: 10vh;
	top: 50%;
	left: 50%;
	margin: -5vh 0 0 (-5%);
	transform: translateZ(0);
	will-change: transform;
}

%popup_transition {
	transition: transform .5s ease;
	will-change: transform;
}

%product_price_line {
	position: relative;

	&::after {
		position: absolute;
		left: 0;
		bottom: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 1px;

		background-color: currentColor;

		content: '';
	}
}

%full_height_section_minus_header {
	// DESKTOP
	@include responsiveFrom('lg') {
		min-height: calc(100vh - 14rem);
	}

	// TABLET
	@include responsiveFromTo('lg', 'md') {
		min-height: calc(100vh - 10rem);
	}

	// MOBILE
	@include responsiveTo('md') {
		min-height: calc(100vh - 7rem);
	}
}

%gallery_nav_btn {
	@extend %global_flex_block_row_wrap_center_center;

	width: 8rem;
	height: 8rem;
	padding: .8rem;

	overflow: hidden;

	border: .1rem solid getColor('gray-medium');
	background: getColor('gray-medium');
	border-radius: 50%;
}

//---------------------------------------------TITLE EXTENDS

%title_base {
	margin: 0;
	letter-spacing: 0.05em;
	font-weight: 700;
}

%title_font {
	font-family: $base-font-family;
}

%title_size_1 {
	// responsive
}

%title_size_2 {
	// responsive
}

%title_size_4 {
	// responsive
}

%title_offset_1 {
	// responsive
}

%title_border {
	border-bottom: 1px solid getColor('gray-dark');
}

%title_font {
	// font-style: italic;
	color: getColor('primary');
}

%link_base {
	line-height: 1;
	font-weight: 700;
	color: getColor('primary');
	text-decoration: none;
	letter-spacing: .07em;

	border-bottom: 1px solid;

	transition: border-color .2s ease;

	&:hover, &:focus {
		border-color: transparent;
	}
}

%checkout_link {
	position: relative;
	display: inline-flex;
	font-weight: 700;
	color: $black;
	transition: color .3s;
	text-decoration: underline;

	&:hover, &:focus {
		text-decoration: none;
		color: getColor('gray-dark');
	}
}

%order_title {
	text-align: left;
	letter-spacing: .05em;
	font-family: $base-font-family;

	span {
		margin-left: .2rem;
		color: getColor('red');
	}
}

//---------------------------------------------TITLE EXTENDS###

%section_bg_wrapper_style {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: -1;

	picture {
		width: 100%;
		height: 100%;
	}
}

%section_bg_image_style {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

//---------------------------------------------back link

%back_link {
	@extend %global_flex_block_row_nowrap_flex-start_flex-end;

	color: getColor('primary');
	text-decoration: none;

	transition: color .3s;

	&:hover, &:focus {
		color: rgba(getColor('primary'), .6);
	}
}

%back_link_title {
	text-transform: uppercase;
	letter-spacing: .07em;
	font-weight: 700;
	font-size: 1.4rem;
}

%back_link_icon {
	width: .9rem;
	height: 1.7rem;
	margin: 0 1rem 2px 0;
}

//---------------------------------------------back link###

@include responsiveFrom('lg') {
	%title_base {
		font-size: 3.4rem;
	}

	%title_size_1 {
		font-size: 6rem;
	}

	%title_size_2 {
		font-size: 2.6rem;
	}

	%title_size_3 {
		font-size: 4rem;
	}

	%title_size_4 {
		font-size: 4.5rem;
	}

	%title_border {
		padding-bottom: 2.4rem;
	}

	%title_offset_1 {
		margin-bottom: 5.8rem;
	}

	%title_offset_2 {
		margin-bottom: 8.8rem;
	}

	%order_title {
		font-size: 3.4rem;

		span {
			font-size: 4rem;
		}
	}
}

@include responsiveFromTo('lg', 'md') {
	%title_base {
		font-size: 3rem;
	}

	%title_size_1 {
		font-size: 3rem;
	}

	%title_size_2 {
		font-size: 2.6rem;
	}

	%title_size_3 {
		font-size: 3.4rem;
	}

	%title_size_4 {
		font-size: 3.4rem;
	}

	%title_offset_1 {
		margin-bottom: 3.8rem;
	}

	%title_offset_2 {
		margin-bottom: 6.8rem;
	}

	%order_title {
		font-size: 2.6rem;

		span {
			font-size: 3.4rem;
		}
	}
}

@include responsiveTo('md') {


	%title_base {
		font-size: 1.8rem;
	}

	%title_size_1 {
		font-size: 2.6rem;
	}

	%title_size_2 {
		font-size: 2.2rem;
	}

	%title_size_3 {
		font-size: 2.4rem;
	}

	%title_size_4 {
		font-size: 2.4rem;
	}

	%title_offset_1 {
		margin-bottom: 1.8rem;
	}

	%title_offset_2 {
		margin-bottom: 4.4rem;
	}

	%order_title {
		font-size: 2.2rem;

		span {
			font-size: 2.6rem;
		}
	}
}


//profile extends
%profile_head {
	width: 100%;
	max-width: 70rem;

	text-align: center;

	&:last-child {
		margin-bottom: 0;
	}
}

%profile_title {
	font-weight: 700;
	color: getColor('primary');

	&:last-child {
		margin-bottom: 0;
	}
}

%profile_descr {
	color: getColor('primary');
	P {
		margin: 0;
		padding: 0;
	}
}

%profile_form_width {
	width: 100%;
	margin: 0 auto;
}

// category card
%category_card {
	@extend %global_flex_block_row_nowrap_flex-start_flex-end;

	position: relative;
	text-decoration: none;
	border-radius: .8rem;
	overflow: hidden;
}

%category_label {
	position: relative;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	min-height: 5rem;

	font-family: $base-font-family;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .05em;
	border-radius: 0 3rem 3rem 0;
	overflow: hidden;

	&::after {
		z-index: -1;
		position: absolute;
		left: -.1rem;
		top: 0;

		width: calc(100% + .2rem);
		height: 100%;

		background-color: getColor('light');
		content: '';
	}
}



// ----------------------------------------------MOBILE
@include responsiveTo('md'){
	%profile_head {
		margin: 0 auto 4.5rem;
	}

	%profile_title {
		margin: 0 0 1.2rem;

		font-size: 2.2rem;
		letter-spacing: .11rem;
	}

	%profile_descr {
		font-size: 1.4rem;
		line-height: 1.43;
		letter-spacing: .07rem;
	}
}
// ----------------------------------------------MOBILE###
// ----------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	%profile_head {
		margin: 0 auto 8.6rem;
	}

	%profile_title {
		margin: 0 0 2rem;
	}

	%profile_descr {
		font-size: 1.6rem;
	}

	%profile_form_width {
		max-width: 48.6rem;
	}
}
// ----------------------------------------------TABLET ONLY###
// ----------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
	%profile_title {
		font-size: 2.6rem;
		letter-spacing: .13rem;
	}

	%profile_descr {
		font-size: 1.6rem;
		line-height: 1.38;
		letter-spacing: .05rem;
	}
}
// ----------------------------------------------TABLET + DESKTOP###
// ----------------------------------------------DESKTOP
@include responsiveFrom('lg') {
	%profile_head {
		margin: 0 auto 4.8rem;
	}

	%profile_title {
		margin: 0 0 1rem;
	}

	%profile_descr {
		font-size: 1.8rem;
	}

	%profile_form_width {
		max-width: 45.2rem;
	}

}
// ----------------------------------------------DESKTOP###
