#cancelOrderPopup {
  top: -$space-3xl;

   @include responsiveFrom('md'){
     top:0;
     height: 100vh;
   }

  .popup_close.popup_close--pos-2-mod.popup_close--size-mob-mod.popup_close--size-2-mod.cancel-order-popup-close{
    top: $space-4xl;
  }

  .popup__in.popup__in--v1-mod.c-cancel-order--popup-mod {
    max-height: unset;

    @include responsiveFrom('md'){
      padding: $space-l;
    }
  }
}