.c-header-menu {
	z-index: $z-i-menu;
	position: absolute;
	top: 100%;
	left: 0;

	width: 100%;

	pointer-events: none;

	overflow: hidden;

	&.active-test-state {

	}
}

.c-header-menu__list {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
}

// .c-header-menu__list__item {
// 	z-index: -1;

// 	position: absolute;
// 	top: 0;
// 	left: 0;

// 	width: 100%;

// 	pointer-events: none;

// 	overflow: hidden;
// }

.c-header-menu__in {
	background-color: getColor('light');
}

.c-header-menu__in--active-menu {
	z-index: 1;
}
.c-header-menu__sublist__bg{
	&::after{
		content: '';
		position:absolute;
		top:0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%, rgba(0, 0, 0, 0.24) 100%, rgba(0, 0, 0, 0.60) 100%);
		z-index: 1;
	}
}
.c-header-menu__sublist__bg__img {
	@extend %transition_transform;

	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
	//mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%, rgba(0, 0, 0, 0.16) 100%, rgba(0, 0, 0, 0.4) 100%, lightgray);

	//-webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%, rgba(0, 0, 0, 0.16) 100%, rgba(0, 0, 0, 0.40) 100%,  lightgray);

	//mask-position: -314.337px 0px;
	//mask-size: 329.755% 100%;
	.c-header-menu__sublist__link:hover & {
		transform: scale(1.2);
	}
}


.c-header-menu__sublist__link {
	font-weight: 400;
}

// .c-header-menu__sublist__text {
// 	@extend %global_flex_block_row_wrap_flex-end_center;

// 	z-index: 2;

// 	position: relative;

// 	width: 100%;
// 	min-height: 2.4rem;
// 	padding: 4px 1rem 4px 4px;

// 	background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
// }
//---------------------------------------------MOBILE ONLY
.c-close-button_custom{
	display: none;
}
@include responsiveTo('md') {
	
	.c-header-menu {
		height: calc(100% - #{$header_user_nav_mobile_menu_height});
	}

	.c-header-menu__in {
		padding: 0;
	}

	.c-header-menu__heading__btn-back {
		height: 6rem;
	}

	.c-header-menu__sublist__bg {
		margin-right: 4.2rem;
		display: none;
	}

	.c-header-menu__sublist__item {

		&:first-child::before,
		&::after {
			left: 0;
			width: 100%;
		}
	}

}
//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
.c-header-menu__sublist__item:nth-child(-n+7) .headerMenuItem {
	@media (min-width:1601px) and (max-width: 1737px) {
		width: calc(204px * 93vw / 1737px);
		height: calc(288px * 93vw / 1737px);
	}
}
@include responsiveTo('xxl') {
	.c-header-menu {
		top: 0;

		overflow: hidden;
	}

	.c-header-menu__list {
		height: 100%;
	}

	.c-header-menu__sublist {
		padding-left: 2rem;
		border-top: 1px solid getColor('gray');
	}

	.c-header-menu__in {
		height: 100%;

		opacity: 0 !important;
		pointer-events: none;

		overflow: hidden;
		overflow-y: auto;

		transition: opacity .3s ease;
	}

	// important for resize issue
	.c-header-menu__in--active-menu {
		display: block !important;
		opacity: 1 !important;
		pointer-events: auto;
		transform: translateY(0%) !important;
	}

	.c-header-menu__sublist__item {
		position: relative;

		padding-bottom: 2rem;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}

		&:first-child {
			padding-top: 2rem;

			&::before {
				top: 0;
			}
		}

	}

	.c-header-menu__sublist__link {
		padding-right: 1rem;

		text-decoration: none;

		@include flex_block(row, nowrap, flex-start, center, center);
	}

	.c-header-menu__sublist__bg {
		position: relative;

		max-width: 8.8rem;
		width: 100%;
		height: 10.8rem;

		overflow: hidden;
		border-radius: 2rem;
	}

	.c-header-menu__sublist__text__in {
		font-size: 1.6rem;
		color: getColor('primary');
		font-weight: 400;
	}

	.c-header-menu__close-btn {
		display: none;
	}

	.c-header-menu__heading__btn-back {
		position: relative;
		border-bottom: 1px solid getColor('gray-dark');
		text-align: left;
		padding-left: 4rem;
		width: 100%;
		height: 6.4rem;

		font-size: 2rem;
		font-family: $base-font-family;
		font-weight: 700;
		letter-spacing: .08rem;

		color: getColor('primary');
	}

	.c-header-menu__heading__btn-back__icon {
		position: absolute;
		top: 50%;
		left: 2rem;

		width: .5rem;
		height: 1rem;

		transform: translatey(-50%);

		color: black
	}

	.c-header-menu__heading__btn-back__title {
		display: inline-block;
		// line-height: 1;
		// border-bottom: 1px solid getColor('primary');
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px; /* 133.333% */
		letter-spacing: -0.36px;
	}
}
//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('xxl', 'md') {
	.c-header-menu {
		height: calc(100% - #{$header_user_nav_tablet_menu_height});
	}

	.c-header-menu__in {
		padding: 0;
	}

	.c-header-menu__sublist__bg {
		display: none;
		margin-right: 4.2rem;
	}

	.c-header-menu__sublist__item {

	}

}
//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
	.c-header-menu__sublist__text__in{
		color: getColor('primary');
	}
}
//---------------------------------------------TABLET + DESKTOP###
.c-header-menu__sublist__text__in{
	@extend %typo-text-1;
}
//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('xxl') {
	.c-header-menu__sublist__text__in{
		text-align: center;
		color: white;
		font-weight: 700;
		margin: 0 $space-xxs;
	}
	.c-close-button_custom{
		display: flex;
	}
	.c-header-menu {
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
	}

	.c-header-menu__sublist {
		z-index: 2;

		position: relative;

		margin: 0 -4.5rem -4.5rem 0;
		padding: 0;

		list-style: none;

		@include flex_block(row, wrap, center);
	}

	.c-header-menu__sublist__item {
		padding: 0 2% 0 0;
	}

	.c-header-menu__in {
		//border-top: 1px solid getColor('gray-dark');
		min-height: 32.8rem;
		padding: 2rem 2rem;

		opacity: 0;

		display: none;
	}

	.c-header-menu__in--active-menu {
		pointer-events: auto;
	}

	.c-header-menu__sublist__link {
		position: relative;

		width: toRem(204);
		height: toRem(288);
		padding: 2.7rem 0;

		text-decoration: none;
		border-radius: .25rem;
		overflow: hidden;

		@include flex_block(row, wrap, flex-start, flex-end, flex-end);

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}

		&:focus {
			@include base_outline();
		}
	}

	.c-header-menu__sublist__bg {
		z-index: 1;

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		overflow: hidden;
	}

	.c-header-menu__sublist__text {
		z-index: 2;

		position: relative;
		width: 100%;

	}

	.c-header-menu__sublist__text__in {
		display: flex;
		justify-content: center;
	}

	.c-header-menu__close-btn {
		z-index: 10;

		position: relative;


		display: block;
		width: 2rem;
		height: 2rem;
		cursor: pointer;

		transition: color .3s ease;

		&:hover,
		&:focus {
			color: getColor('secondary');
		}

		&:focus {
			@include base_outline();
		}
	}

	.c-header-menu__list__btn-back {
		display: none;
	}

	.c-header-menu__heading {
		display: none;
	}
}
//---------------------------------------------DESKTOP ONLY###
