@use "sass:math";

.icon {
	
	fill: currentColor;
}
/*
.icon-alert {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}*/
.icon-arrow {
	font-size:(math(9, 10))*1rem;
	width:(math(19,9))*1em;
}
.icon-arrow_2_left {
	font-size:(math(20, 10))*1rem;
	width:(math(10,20))*1em;
}
.icon-arrow_2_right {
	font-size:(math(20, 10))*1rem;
	width:(math(10,20))*1em;
}
.icon-arrow_bottom {
	font-size:(math(5, 10))*1rem;
	width:(math(10,5))*1em;
}
.icon-arrow_d {
	font-size:(math(32.05, 10))*1rem;
	width:(math(57.12,32.05))*1em;
}
.icon-arrow_l {
	font-size:(math(57.12, 10))*1rem;
	width:(math(32.07,57.12))*1em;
}
.icon-arrow_left {
	font-size:(math(41, 10))*1rem;
	width:(math(51,41))*1em;
}
.icon-arrow_r {
	font-size:(math(58, 10))*1rem;
	width:(math(34,58))*1em;
}
.icon-arrow_right {
	font-size:(math(41, 10))*1rem;
	width:(math(51,41))*1em;
}
.icon-arrow_t {
	font-size:(math(31.98, 10))*1rem;
	width:(math(57.12,31.98))*1em;
}
.icon-close {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}
/*
.icon-ask {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}
.icon-bag {
	font-size:(math(57, 10))*1rem;
	width:(math(45,57))*1em;
}
.icon-bell {
	font-size:(math(57, 10))*1rem;
	width:(math(45,57))*1em;
}
.icon-check {
	font-size:(math(18, 10))*1rem;
	width:(math(23,18))*1em;
}
.icon-check_circle {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}
.icon-close {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}
.icon-close_circle {
	font-size:(math(57, 10))*1rem;
	width:(math(57,57))*1em;
}
.icon-credit_card {
	font-size:(math(57, 10))*1rem;
	width:(math(78,57))*1em;
}
.icon-cvv {
	font-size:(math(57, 10))*1rem;
	width:(math(83,57))*1em;
}
.icon-download {
	font-size:(math(58, 10))*1rem;
	width:(math(47,58))*1em;
}
.icon-eye {
	font-size:(math(43, 10))*1rem;
	width:(math(77,43))*1em;
}
.icon-eye_w_line {
	font-size:(math(58, 10))*1rem;
	width:(math(76,58))*1em;
}
.icon-facebook {
	font-size:(math(37, 10))*1rem;
	width:(math(19,37))*1em;
}
.icon-filter {
	font-size:(math(52, 10))*1rem;
	width:(math(78,52))*1em;
}
.icon-garanty {
	font-size:(math(57, 10))*1rem;
	width:(math(44,57))*1em;
}
.icon-info {
	font-size:(math(19.96, 10))*1rem;
	width:(math(3.12,19.96))*1em;
}
.icon-instagram {
	font-size:(math(35, 10))*1rem;
	width:(math(35,35))*1em;
}
.icon-m_arrow {
	font-size:(math(16, 10))*1rem;
	width:(math(8,16))*1em;
}
.icon-mail {
	font-size:(math(49, 10))*1rem;
	width:(math(68,49))*1em;
}
.icon-muted {
	font-size:(math(58, 10))*1rem;
	width:(math(70,58))*1em;
}
.icon-package {
	font-size:(math(58, 10))*1rem;
	width:(math(78,58))*1em;
}
.icon-pause {
	font-size:(math(57, 10))*1rem;
	width:(math(39,57))*1em;
}
.icon-phone {
	font-size:(math(58, 10))*1rem;
	width:(math(57,58))*1em;
}
.icon-play {
	font-size:(math(58, 10))*1rem;
	width:(math(50,58))*1em;
}
.icon-plyr-airplay {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-captions-off {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-captions-on {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-download {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-enter-fullscreen {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-exit-fullscreen {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-fast-forward {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-logo-vimeo {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-logo-youtube {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-muted {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-pause {
	font-size:(math(57, 10))*1rem;
	width:(math(39,57))*1em;
}
.icon-plyr-pip {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-play {
	font-size:(math(58, 10))*1rem;
	width:(math(50,58))*1em;
}
.icon-plyr-restart {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-rewind {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-settings {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-plyr-volume {
	font-size:(math(18, 10))*1rem;
	width:(math(18,18))*1em;
}
.icon-point {
	font-size:(math(57, 10))*1rem;
	width:(math(41,57))*1em;
}
.icon-purchase {
	font-size:(math(58, 10))*1rem;
	width:(math(57,58))*1em;
}
.icon-quote_l {
	font-size:(math(58, 10))*1rem;
	width:(math(73,58))*1em;
}
.icon-quote_r {
	font-size:(math(58, 10))*1rem;
	width:(math(73,58))*1em;
}
.icon-right {
	font-size:(math(16, 10))*1rem;
	width:(math(8,16))*1em;
}
.icon-schedule {
	font-size:(math(57, 10))*1rem;
	width:(math(56,57))*1em;
}
.icon-search {
	font-size:(math(58, 10))*1rem;
	width:(math(59,58))*1em;
}
.icon-shopping_bag {
	font-size:(math(24, 10))*1rem;
	width:(math(18,24))*1em;
}
.icon-trash {
	font-size:(math(57, 10))*1rem;
	width:(math(50,57))*1em;
}
.icon-twitter {
	font-size:(math(35, 10))*1rem;
	width:(math(43,35))*1em;
}
.icon-user {
	font-size:(math(57, 10))*1rem;
	width:(math(55,57))*1em;
}
.icon-user_fill {
	font-size:(math(54.58, 10))*1rem;
	width:(math(51.97,54.58))*1em;
}
.icon-volume {
	font-size:(math(57, 10))*1rem;
	width:(math(75,57))*1em;
}
.icon-whatsapp {
	font-size:(math(58, 10))*1rem;
	width:(math(58,58))*1em;
}
.icon-youtube {
	font-size:(math(33.19, 10))*1rem;
	width:(math(43.96,33.19))*1em;
}
*/
