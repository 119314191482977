.c-hero {
	position: relative;

	&.c-hero--filter-mod {
		margin: 0 0 2rem;
		height: 25rem;
	}
}

.hero-bg {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;

	width: 100vw;
	height: 100%;
}

.hero-bg__img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover
}

.hero-content {
	@extend %global_flex_block_row_wrap_flex-start_center;
	justify-content: center;
	height: 100%;
}

.hero__in {
	position: relative;
	z-index: 2;

	color: $white;
}

.hero__title {
	margin: 0;
	text-align: left;
	font-family: $base-font-family;
	@extend %typo-h4;
	font-weight: 400;
	// letter-spacing: 0.05em;
}

.hero__descr {
	font-weight: 400;
	@extend %typo-text-1;
	
	p {
		margin: 0;
	}
}

// ----------------- >= DESKTOP
@include responsiveFrom('lg'){
	.c-hero {
		height: 20rem;
	}

	.hero-content {
    padding: 1rem 0 3rem;

		.c-hero--filter-mod & {
			padding: 2.4rem 0 3.4rem;
		}
	}

	.hero__descr {
		text-align: center;
		color: #000;
	}

	.hero__title {
		color: black;
   		 margin: 0 0 1rem;

		.c-hero--filter-mod & {
			margin: 0 0 2.2rem;
		}
	}
}
// ----------------- >= DESKTOP###
// ----------------- >= TABLET
@include responsiveFrom('md'){

	.c-hero {
		&:after {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			content: '';

			width: 100vw;
			height: 100%;

			background-color: #fff;
			opacity: 1;
		}

	}
}
// ----------------- >= TABLET###
// ----------------- TABLET
@include responsiveFromTo('lg', 'md') {
	.c-hero {
		height: 14.9rem;
	}

	.hero-content {
		padding: 1rem 0 2.4rem;

		.c-hero--filter-mod & {
			padding: 2.4rem 0 3.2rem;
		}
	}

	.hero__title {
		color: black;
		font-size: 3rem;
		font-weight: 400;
	}

	.hero__descr {
		font-size: 1.6rem;
		color: black;
	}
}
// ----------------- TABLET###
// ----------------- < TABLET
@include responsiveTo('md'){
	.c-hero {
		margin: 3.2rem 0 1.4rem;

		&.c-hero--filter-mod {
			display: none;
		}
	}

	.hero-bg {
		display: none;
	}

	.hero__title {
		font-weight: 400;
		font-size: 2.6rem;
		color: getColor('primary');
		line-height: 30px; /* 115.385% */
		letter-spacing: -0.52px;
	}

	.hero__descr {
		display: none;
		// font-size: 1.6rem;
		// color: $black;
	}
}
// ----------------- < TABLET###