// custom scss for components

.banner__full__image__video.banner__full__image__video {
  height: auto; //cause can be different than 9:16
  max-height: calc(100vh - var(--header-height));
  overflow: hidden;
  padding: 0;
  position: relative;

  .desktop.desktop {
    display: none;
    padding-bottom: 56.25%;
    max-width: 100%;
    height: auto;
    position: relative;

    @include responsiveFrom('md') {
      display: block;

      &:has(img) {
        padding-bottom: 0;
      }
    }
  }

  .mobile.mobile {
    display: block;
    position: relative;

    &:has(iframe) {
      padding-bottom: 177.78%;
    }

    @include responsiveFrom('md') {
      display: none;
    }

  }

  article {

    &.imagevideo-info {
      //text + btn
      z-index: 7;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      bottom: 0;
      position: absolute;
      padding: $space-l $space-s;

      @include grid-mobile;

      @include responsiveFrom('md') {
        @include grid-tablet;
        padding: $space-l;
      }

      @include responsiveFrom('lg') {
        padding: $space-xxl;
      }

      h2 {
        @extend %typo-h2;
        margin-bottom: $space-xl;
        padding: 0 $space-s;
        font-weight: 400;
        color: getColor('light');
        grid-column: 1 / 5;
        
        @include responsiveFrom('md') {
          grid-column: 1 / 9;
          padding: 0;
          text-align: left;
        }

        @include responsiveFrom('lg') {
          margin-bottom: $space-m;
          padding-bottom: $space-s;
        }
      }

      .button.button--tertiary {
        grid-column: 1 / 5;
        min-width: unset;

        @include responsiveFrom('md') {
           max-width: 30.5rem;
        }
      }

    }

    .section__banner-controller_div {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
    }

    iframe {
      border: 1px solid #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain;
      display: block;
    }


  }

  button {
    position: absolute;
    transition: all 0.3s;
    z-index: 50;
    bottom: 0;
    right: 0;
    visibility: visible;
    margin: $space-l;

    @include responsiveTo('md'){
      bottom: unset;
      top: 0;
    }

    @include responsiveFrom("lg") {
      margin: $space-xl;
      visibility: hidden;
    }

    &:hover {
      opacity: 1;
    }

    i.icon {
      font-size: 2rem;
      color: getColor('light');

      @include responsiveFrom("md") {
        font-size: 2.6rem;
      }

      @include responsiveFrom("lg") {
        font-size: 4rem;
      }
    }
  }

  &:hover {
    button {
      visibility: visible;
      opacity: 1;
    }
  }
}