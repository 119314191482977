.banner_image_with_text {

    .banner.banner--experience{
        @include responsiveFrom('lg'){
            @include grid-desktop;
            padding: $space-4xl $space-l;
        }
    }

    .banner__col__bg {

        @include responsiveTo('md') {
            margin: 0 $space-s $space-l;
            display: block;
            padding: 0;
            height: auto;
            width: auto;
            position: static;
        }
        @include responsiveFrom('lg') {
            grid-column: 2 / 5;
            height: 100%;
            padding-bottom: 0;
        }

        img {
            
            @include responsiveTo('md') {
                position: static;
                max-height: unset;
                max-width: unset;
            }
            @include responsiveFrom('lg') {
                max-height: unset;
                max-width: unset;
                position: static;
            }
        }
    }

    .banner__col.banner__col--content-mod{
        @include responsiveFrom('lg') {
           grid-column: 6  / 12;
           padding: $space-l;
        }
    }

}