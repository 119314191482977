#editorial-newsletter-component.editorial-newsletter-component {

    @include responsiveFrom ('lg') {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: $space-s;
    }

    .c-account-hero__bg {
        @include responsiveFrom ('lg') {
            grid-column: 1 / 7;
        }

        .c-account-hero__bg__picture {
            display: block;
            height: 100%;

            .c-account-hero__bg__img {
                display: block;
                height: 100%;
                object-fit: contain;

            }
        }
    }

    .storefront-gigya-wrapper {
        padding: $space-xl $space-s;

        @include responsiveFrom ('md') {
            padding: $space-xl $space-l;
        }

        @include responsiveFrom ('lg') {
            padding: 0;
            grid-column: 8 / 12;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}