//pop up search
[data-js-component="PopupSearchComponent"] {
  display: block;

  .c-header-menu__close-btn {
    display: block;

    i {
      font-size: 1.8rem;
    }
  }

  .c-close-button_custom {
    @include responsiveFrom('md') {
      display: block;
      width: auto;
      position: absolute;
      top: $space-l;
      right: $space-l;
    }
  }

  .popup__in.popup__in--search-mod {

    @include responsiveFrom('md') {
      @include grid-tablet;
    }
    @include responsiveFrom('lg') {
      @include grid-desktop;
    }

    .popup__content {
      padding: 0;

      @include responsiveFrom('md') {
        max-width: unset;
        margin: 0;
        grid-column: 2 / 8;
      }
      @include responsiveFrom('lg') {
        grid-column: 4 / 10;
      }

      #plpSearchForm {
        margin-bottom: $space-xxl;

        #freeTextSearchInput {
          @extend %typo-text-2;
          color: getColor('primary');
        }

        #plpSearchButton {
          position: absolute;
          bottom: 1.3rem;
          right: 1.8rem;

          &:hover, &:focus {
            color: getColor('primary');
          }

          i {
            font-size: 1.8rem;
          }
        }
      }

      .popup-search__title, .popup_search__link {
        @extend %typo-text-1;
        color: getColor('primary');
      }


      .popup-search__title {
        margin-bottom: $space-l;
        font-weight: 700;
        text-transform: uppercase; //custom
      }

      .popup_search__item {
        margin-bottom: $space-s;
      }

    }

  }

}
