:root {
	--full-screen: calc(var(--vh, 1vh) * 100);
		--global_message_height: 6rem;
	

	// TABLET
	@include responsiveFromTo('lg', 'md') {}

	// Tablet + mobile
	@include responsiveTo('lg') {}

	// MOBILE
	@include responsiveTo('md') {}
}

.c-global-message {
	@extend %global_flex_block_row_nowrap_space_between_center;

	z-index: 11;
	position: fixed;
	left: 0;

	display: none;
	width: -webkit-fill-available;

	margin: 0 $space-s;

	background-color: $white;

	transition: transform .3s ease;
	padding: 1.4rem; //custom

	border-radius: $space-xs;
	border: 1px solid;


	&.c-global-message--active-mod {
		display: flex;
	}

	&#popupGlobalErrorMessage,
	&#globalErrorMessage {
		border-color: getColor('error')
	}

	&#globalSuccessMessage {
		border-color: getColor('success')
	}
}

.c-global-message__content {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	padding-right: 2rem;
}

.c-global-message__close {
	cursor: pointer;

	color: getColor('success');

	#popupGlobalErrorMessage &,
	#globalErrorMessage &{
		color: getColor('error');
	}

	i {
		font-size: 2rem;

	}
}

.c-global-message__content__title {
	font-weight: 700;
	color: getColor('success');
	@extend %typo-text-1;

	&.c-global-message__content__title--error-mod {
		color: getColor('error');
	}

	#popupGlobalErrorSpanFiscalCode {
		display: none;
	}
}

.c-global-message__content__icon {
	color: getColor('success');
	margin-right: $space-l;

	&.c-global-message__content__icon--error-mod {
		color: getColor('error');
	}

	i {
		font-size: 3rem;
	}

	i.icon-added{
		display: block;
		width: 100%;

		&::before{
			display: block;
			width: 100%;
			margin-left: -$space-xs;
		}
	}
}



//---------------------------------------------TABLET + DESKTOP

@include responsiveFrom('md') {
	.c-global-message {
		margin: 0 $space-l;
		body:not(.disable_top_bar_state) & {
			top: 14rem;
		}
	}
}

@include responsiveFrom('lg') {

	.c-global-message {
		body:not(.disable_top_bar_state) & {
			top: 12rem;
		}

		body.page-scroll-state & {
			transform: translateY(-2rem)
		}
	}
}

//tablet only
@include responsiveFromTo('lg', 'md') {}

@include responsiveTo('md') {
	.c-global-message {
		body:not(.disable_top_bar_state) & {
			top: 11rem;
		}
	}
}