$base-font-family: 'Inter', sans-serif;

//Font weights
$font-regular: 400;
$font-medium: 600;
$font-bold: 700;

%typo-h1 {
  font-family: $base-font-family;
  font-size: 4.4rem;
  line-height: 4.4rem; /* 100% */
  letter-spacing: -0.88px;
  @include responsiveFrom('md') {
    font-size: 8.8rem;
    line-height: 8.8rem;; /* 100% */
    letter-spacing: -1.76px;
  }
  @include responsiveFrom('lg') {
  }
}

%typo-h2 {
  font-family: $base-font-family;
  font-size: 4rem;
  line-height: 4.4rem; /* 100% */
  letter-spacing: -0.8px;
  margin: 0;
  @include responsiveFrom('md') {
    font-size: 7.2rem;
    line-height: 8.8rem;; /* 100% */
    letter-spacing: -1.44px;
  }
  @include responsiveFrom('lg') {
  }
}

%typo-h3 {
  font-family: $base-font-family;
  font-size: 3rem;
  line-height: 4rem; /* 133.333% */
  letter-spacing: -0.6px;
  margin: 0;
  @include responsiveFrom('md') {
    font-size: 4rem;
    line-height: 5rem; /* 125% */
    letter-spacing: -0.8px;
  }
  @include responsiveFrom('lg') {
  }
}

%typo-h4 {
  font-family: $base-font-family;
  font-size: 2.8rem;
  line-height: 3.6rem; /* 115.385% */
  letter-spacing: -0.56px;
  margin: 0;
  @include responsiveFrom('md') {
    font-size: 3.6rem;
    line-height: 5.2rem; /* 133.333% */
    letter-spacing: -0.72px;
  }
  @include responsiveFrom('lg') {
  }
}

%typo-h5 {
  font-family: $base-font-family;
  font-size: 2.2rem;
  line-height: 2.4rem; /* 120% */
  letter-spacing: -0.44px;
  margin: 0;
  @include responsiveFrom('md') {
  }
  @include responsiveFrom('lg') {
    font-size: 2.2rem;
    letter-spacing: -0.44px;
  }
}

%typo-h6 {
  font-family: $base-font-family;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem; /* 120% */
  letter-spacing: -0.4px;
  @include responsiveFrom('md') {
  }
  @include responsiveFrom('lg') {
  }
}

%typo-sub {
  font-family: $base-font-family;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.8rem; /* 100% */
  letter-spacing: -0.81px;
  @include responsiveFrom('md') {
  }
  @include responsiveFrom('lg') {
  }
}

%typo-text-1 {
  font-family: $base-font-family;
  font-size: 1.6rem;
  line-height: 2rem; /* 150% */
  letter-spacing: -0.32px;
  @include responsiveFrom('md') {
  }
  @include responsiveFrom('lg') {
  }
}

%typo-text-2 {
  font-family: $base-font-family;
  font-size: 1.4rem;
  line-height: 2rem; /* 142.857% */
  letter-spacing: -0.28px;
  @include responsiveFrom('md') {
  }
  @include responsiveFrom('lg') {
  }
}

%typo-text-3 {
  font-family: $base-font-family;
  font-size: 1.2rem;
  line-height: 2rem; /* 166.667% */
  letter-spacing: -0.24px;
  @include responsiveFrom('md') {
    font-size: 1.4rem;
    letter-spacing: -0.26px;
  }
  @include responsiveFrom('lg') {
  }
}


// classes for auto formatting texts
.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

