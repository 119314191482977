//---------------------------------------------c-product-filter
.c-product-filter__wrapper {
	position: absolute;
	z-index: 10;
	top: 100%;
	left: 0;

	width: 100%;
	//overflow: hidden;

	.c-product-filter-nav.c-product-filter-nav--dropdown-active & {
		display: none;
	}
}

.c-product-filter {
	z-index: 90;

	opacity: 0;
	pointer-events: none;

	transition: opacity .3s ease;

	&.c-product-filter--active-mod {
		opacity: 1;
		pointer-events: auto;
	}

}

.c-menu__close-btn {
	.c-product-filter & {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 2rem;
		margin: 2rem;
	}
}

.c-product-filter__in {
	display: none;

	.c-product-filter.c-product-filter--active-mod & {
		display: block;
	}
}

.c-product-filter__result {
	margin-bottom: 1.6rem;
	@extend %typo-h6;
	font-weight: 400;
	color: getColor('primary');
}

.c-product-filter__block__title {
	@extend %typo-h6;
	color: getColor('primary');
	font-weight: 700;
}

.c-product-filter__checkboxes {
	columns: 2;
	margin: 0 -1.6rem -2rem 0;
}

.c-product-filter__checkboxes__item {
	display: inline-block;
	width: 100%;
	padding: 0 1.6rem 2rem 0;
}

.c-product-filter__bottom {
	@extend %global_flex_block_row_wrap_center;
}

//---------------------------------------------c-product-filter###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	.c-product-filter {
		position: fixed;
		top: 0;

		height: 100%;
		width: 100%;

		background-color: getColor('light');
		overflow: hidden;
	}

	.c-product-filter__in {
		height: 100%;
		width: 100%;

		padding: 7rem 0 9.6rem;

		overflow: hidden;
		overflow-y: auto;
	}

	.c-product-filter__bottom {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 100%;
			background-color: getColor('gray-dark');
			content: '';
		}

		&.c-custom-filters {
			justify-content: end;

			@media(min-width: 763px) {
				justify-content: center;
			}
		}
	}

	.c-product-filter__content {
		display: flex;
		flex-direction: column-reverse;
	}

	.c-product-filter__block {
		position: relative;
	}

	.c-product-filter__block__heading {
		position: relative;
		height: 5.8rem;
		padding: 0 3rem 0 $space-s;

		@include flex_block(row, wrap, flex-start, center, center);

		.c-product-filter__block__button-open {
			transform: rotate(270deg);
			transition: transform 0.2s ease-in-out;

			.c-product-filter__block--active-mod & {
				transform: rotate(90deg);
			}

			i,
			i::before {
				display: block;
				height: 100%;
				width: 100%;
				font-size: 1.2rem;
			}
		}
	}

	.c-product-filter__block__title {
		// ! custom !
		font-family: $base-font-family;
		font-size: 1.6rem;
		font-style: normal;
		line-height: 2rem;
		/* 125% */
		letter-spacing: -0.32px;
		// ! custom !
	}

	.c-product-filter__result {
		margin: 0;
		padding: $space-m $space-s ;
		border-bottom: 1px solid getColor('gray-dark');
	}

	.c-product-filter__checkboxes--radio-buttons-mod {
		max-height: 100%;
		flex-direction: row;
	}

	.c-product-filter__block__title--subtitle-mod {
		font-weight: 400;
	}

	.c-product-filter__checkboxes {
		padding: $space-m $space-s 0;
		margin: 0;
		columns: 2;
	}

	.c-product-filter__checkboxes__item {
		padding: 0;
		margin-bottom: $space-m;
		height: 2.8rem;
	}

	.c-product-filter__wrap__checkboxes {
		max-height: 50rem;
		@include style_for_accordion_body();

		.c-product-filter__block--active-mod & {
			@include style_for_accordion_body_active_state();
		}
	}

	.c-product-filter__block__button-open {
		position: absolute;
		top: 50%;
		right: .4rem;

		width: 2rem;
		height: 1.2rem;
		margin-top: -.6rem;

		.c-product-filter__block--active-mod & {
			transform: rotate(180deg);
		}
	}

	.c-product-filter__bottom-item {
		width: 50%;


	}

	.c-product-filter__bottom_item {
		&.c-custom-close {
			@media(max-width: 763px) {
				display: flex;
				width: 50%;
				justify-content: center;
			}
		}
	}

	.c-product-filter__bottom-button {
		@include mobile_base_style_action_button();

		font-weight: 700;
		font-size: 1.6rem;
		letter-spacing: 0.08rem;
		color: $black;

		&.c-product-filter__bottom-button--apply-mod {
			@include mobile_base_accent_style_button();
		}

		&.c-product-filter__bottom-button--remove-mod {
			flex-wrap: nowrap;
		}

	}

	.c-product-filter__bottom-button__icon {
		width: 1.2rem;
		height: 1.2rem;

		margin-left: 1.6rem;
	}
}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg') {}

//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	//.c-product-filter {
	//	top: -15rem;
	//}
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
	.c-product-filter {
		position: absolute;
		top: -1px;
		left: 50%;

		width: 100vw;
		// min-height: 40.6rem;
		//min-height: 36.9rem;
		margin: 0 0 0 -50vw;
		padding: $space-3xl 0 $space-l;
		border-top: 1px solid getColor('gray');

		&:before,
		&:after {
			content: '';
			top: 0;
			left: 0;
		}

		&:after {
			position: absolute;

			width: 100%;
			height: 100%;
			background-color: getColor('light');
			border-bottom: 1px solid getColor('gray-dark');
			border-top: 1px solid getColor('gray-dark');
			z-index: -1;
		}

		&:before {
			position: absolute;
			top: 100%;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 0, 0, 0.40);
			opacity: .4;
			z-index: -2;
		}
	}

	.c-product-filter__content {
		position: relative;

		padding: 0 $space-l $space-3xl;
		margin: 0 0 $space-l;

		@include flex_block(row, nowrap, center);

		&:after {
			content: '';
			position: absolute;
			bottom: 0;

			max-width: 100%;
			width: 100%;
			height: 1px;

			background-color: getColor('gray-dark');
		}

	}

	.c-product-filter__bottom {
		gap: $space-xl;
	}

	.c-product-filter__block {
		width: 31.1rem;
		padding: 0 2rem 0 0;
	}

	.c-product-filter__checkboxes--radio-buttons-mod {
		display: none;
	}

	.c-product-filter__block__heading {
		margin-bottom: 1rem;
	}

	.c-product-filter__block__title {
		margin-bottom: $space-l;
	}

	.c-product-filter__result {
		display: none;
	}

	.c-product-filter__checkboxes {
		margin: 0 -1.6rem -2rem 0;
	}

	.c-product-filter__checkboxes__item {
		padding: 0;
		margin-bottom: $space-m;

		label {
			margin: 0;

			.form-field__text.form-field__text--check {
				padding-left: $space-xl;
			}
		}
	}

	.c-product-filter__block__button-open {
		display: none;
	}

	.c-product-filter__bottom-item {
		margin: 0 3rem 0 0;
		min-width: 14.7rem;

		button.button.button--wide {
			min-width: 26.6rem;
		}

		&:last-child {
			margin: 0;
		}
	}

	#mainClosePopupDesktop {
		min-width: 26.6rem;
	}

	.c-product-filter__bottom-button {
		display: none;
	}
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {}

//---------------------------------------------DESKTOP ONLY###