#cartAlertRemoveMessage {
	//display: none;
	@extend %global_flex_block_row_nowrap_flex-start_center;

	position: relative;
	align-items: flex-start;
	background-color: getColor('gray');
	border-radius: .8rem;
	margin-bottom: $space-l;
	padding: $space-l;

	.cart__alert__icon {
		flex-shrink: 0;

		i {
			font-size: 2rem;
		}
	}

	.cart__alert__text {
		@extend %typo-text-2;
		font-weight: 400;
		color: getColor('primary');

		span {
			font-weight: 700;
			margin: 0 $space-xxs;
		}

		p:last-child {
			margin: 0;
		}

		a {

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	.cart__alert__close {
		position: absolute;
		top: 2.2rem;
		right: 1.8rem;

		width: 1.2rem;
		height: 1.2rem;

		cursor: pointer;

		transition: color .3s;

		&:hover,
		&:focus {
			color: getColor('red');
		}
	}
}

.c-cart {
	background-color: getColor('light');
	border-radius: 3rem;
}

.cart__head {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;
	margin: 0 0 $space-l;
}

.cart_head_title {
	@extend %typo-h5;
	color: getColor('primary');
	font-weight: 700;
	margin: 0;
}

.cart__head__count {
	margin: 0 0 0 .5rem;

	font-weight: 400;
	@extend %typo-text-1;
	color: getColor('primary');
}

.cart__subtitle {
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	/* 133.333% */
	letter-spacing: -0.36px;
	color: black;
}

.cart__text {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: -0.28px;
	color: black;

	p {
		margin: 0;
	}

	span {
		font-weight: 700;
	}

	&.cart__text--offset-2-mod {
		margin: 0 0 1.2rem;
	}

	&.cart__text--offset-3-mod {
		margin: 0 0 4rem;
	}
}

.cart-shipping-info-wrapper {
	margin-top: $space-m;
	flex-basis: 100%;
  }

.cart-shipping-info {
	@extend %typo-text-2;
	font-weight: 400;
	color: getColor('primary');
	text-align: start;
	display: block;

	.cart__total__field__label & {
	  padding: 0;
	}

	.bold{
	  font-weight: 700;
	}
  }
//---------------------------------------------Alert


//---------------------------------------------Alert###

//---------------------------------------------Promocode

.cart__promocode {
	position: relative;
	z-index: 1;
	padding: $space-l $space-s;

	&::after {
		z-index: -1;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);

		height: 100%;
		border-radius: .4rem;
		background-color: #F6F9F9;
		content: ''
	}
}

.cart__promocode__head {
	@extend %global_flex_block_row_wrap_flex-start_center;
	cursor: pointer;
	justify-content: space-between;
	height: auto;

}

.cart__promocode__title {
	@extend %typo-text-1;
	font-weight: 700;
	color: getColor('primary');
	width: 90%;
	text-transform: uppercase;
}

.cart__promocode__icon {
	margin: 0;
	transition: transform .2s ease;
	transform: rotateZ(270deg);
	height: 1.6rem;
	width: 1.6rem;

	.cart__promocode--show-mod & {
		transform: rotateZ(90deg);
	}

	i,
	i::before {
		display: block;
		height: 100%;
		width: 100%;
		font-size: 1.5rem;
	}

	i:before {
		transform: translateX(-8px); //custom
	}
}

.cart__promocode__body {
	display: none;

	padding-top: $space-l;

	.cart__promocode--show-mod & {
		display: block;
	}
}

.cart__promocode__field {
	max-width: 43.8rem;
	margin: 0 0 .6rem;
	width: 100%;

	& .form-field__input {
		border-radius: .4rem;
	}
}

.cart__promocode__field {
	.form-field__label {
		@extend %typo-text-2;
		font-weight: 400;
		color: getColor('primary');
		text-transform: uppercase;
	}
}

#promoCodeEnterButton {
	top: 3.5rem;
	right: .6rem;

	i,
	i::before {
		display: block;
		height: 100%;
		width: 100%;
		font-size: 1.2rem;
	}

	i::before {
		transform: rotate(180deg) translateX(-3px) translateY(6px);
	}
}

.cart__promocode__descr {
	@extend %typo-text-3;
	font-weight: 400;
	color: getColor('primary');

	p {
		margin: 0;
	}


	&.cart__promocode__descr--error-mod {
		color: getColor('red');
	}

	&.cart__promocode__descr--success-mod {
		color: getColor('success');
	}
}



//---------------------------------------------Promocode###

//---------------------------------------------Total
.cart__total__fields {
	padding: 2rem 0 1.6rem;
	margin: 0 0 2rem;

	border-top: 1px solid #575A5A;
	border-bottom: 1px solid #575A5A;
}

.cart__total__field {
	@extend %global_flex_block_row_wrap_flex-start_center;

	&+& {
		margin-top: $space-m;
	}

	&.cart__total__field--lg-mod {
		font-weight: 700;
	}
}

.cart__total__field__label,
.cart__total__field__value {
	padding: 0;
	color: getColor('primary');
	@extend %typo-text-1;
	font-weight: 400;

	.cart__total__field--lg-mod & {
		@extend %typo-h6;
		font-weight: 700;
	}

	&.cart__total__field__label--size-mod {}
}

.cart__total__field__value {
	margin: 0 0 0 auto;
}

//---------------------------------------------Total###

//---------------------------------------------EMPTY
.cart__empty__title {
	font-weight: 700;
	font-family: $base-font-family;
	text-transform: uppercase;
	color: getColor('light');

	&--v2-mod {
		color: getColor('primary');
		text-transform: none;
	}
}

.cart__empty__text {
	font-size: 2rem;

	p:last-child {
		margin: 0;
	}
}

.cart__empty__descr {
	font-size: 1.8rem;

	p:last-child {
		margin: 0;
	}
}





//---------------------------------------------EMPTY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg') {

	.cart__list {
		// margin: 0 0 -2.8rem;
	}

	.cart__text {
		span {
			display: block;
			margin: 0 0 2.4rem;
		}
	}
}

//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	.c-cart {
		width: 100%;
		padding: 0 0 $space-xxl;
		margin-bottom: $space-3xl;
	}

	.cart__in {
		margin: 0 0 2.8rem 0;

		.c-cart.empty & {
			margin-bottom: 10.8rem;
		}
	}

	.cart__list--offset-mod {
		padding: 0;
		margin-bottom: $space-xl;
	}

	.cart__total {
		margin: 0 0 4.2rem;
	}

	.cart__total__field.cart__total__field--lg-mod {
		padding: $space-l 0;
		margin: 0 0 $space-xl;
	}

	.cart__total__field__label {
		&.cart__total__field__label--size-mod {}
	}

	.cart__text {
		font-size: 1.4rem;

		&.cart__text--offset-mod {
			margin: 0 0 4.2rem;
		}
	}

	.cart__promocode {
		margin: 0 0 $space-xl;

		&::after {
			width: 100%;
		}
	}

	.cart__promocode__head {}

	.cart__promocode__body {}

	.cart__promocode__field {}

	.cart__alert {}

	.cart__alert__text {
		font-size: 1.4rem;
	}

	.cart__alert__icon {
		margin: 0 1.8rem 0 0;
	}

	.cart__alert__close {
		top: 1rem;
		right: 1rem;

		width: 1.5rem;
		height: 1.5rem;
	}

	.cart__empty__title {
		font-size: 2.6rem;
	}

	.cart__empty__text.offset__mod {
		margin: 0 0 5.2rem;
	}

	.cart__empty__descr {
		font-size: 1.4rem;
	}

	.cart_head_title {}

	.cart__subtitle {
		margin: 0 0 2rem;
		font-size: 1.8rem;
	}

	.cart__empty__title {
		margin: 0 0 5rem;

		&--offset-mod {
			margin-bottom: 3rem;
		}

		&--v2-mod {
			font-size: 2.2rem;
		}
	}

	.cart__empty__text {
		font-size: 1.4rem;
	}

	.cart__empty__text.offset__mod {
		margin: 0 0 5rem;
	}

	.cart__empty__head {
		margin: 0 0 5.2rem;
	}

	.cart__empty__descr {
		margin: 0 0 4.4rem;
	}

	.cart__total__fields {
		padding: $space-l 0;
		margin-bottom: 0;
	}

	.cart__total__field {
		font-size: 1.4rem;
	}
}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-cart {
		padding: 0 0 6.2rem;
		margin-bottom: 13.4rem;
	}

	.cart__total {
		margin: 0 0 5.4rem;
	}

	.cart__total__field.cart__total__field--lg-mod {
		margin: 0 0 $space-xxl;
	}

	.cart__in {
		//max-width: 75.6rem;
		width: 100%;
		margin: 0 auto 0;

		.c-cart.empty & {
			margin-bottom: 15.6rem;
		}
	}

	.cart__list--offset-mod {
		padding: 0 0 4.4rem;
	}

	.cart__head {
		align-items: center;
	}

	.cart_head_title {}

	.cart__promocode {
		padding: $space-l;
		margin: 0 0 $space-xxl;

		&::after {
			width: 100%;
		}
	}

	.cart__empty__descr {
		font-size: 1.8rem;
	}

	.cart__subtitle {
		margin: 0 0 2.2rem;
	}

	.cart__text {
		&.cart__text--offset-mod {
			margin: 0 0 5.2rem;
		}
	}

	.cart__empty__head {
		margin: 0 0 10.4rem;
	}

	.cart__empty__title {
		font-size: 4rem;
		margin: 0 0 4.8rem;

		&--v2-mod {
			font-size: 3.4rem;
		}

		&--offset-mod {
			margin-bottom: 2.2rem;
		}
	}

	.cart__empty__text {

		&.offset__mod {
			margin: 0 0 4.8rem;
		}
	}

	.cart__empty__descr {
		margin: 0 0 4.8rem;
	}

	.cart__total__fields {
		padding: $space-l 0;
		margin: 0 0 2rem;
	}

	.cart__total__field {}
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {

	#promoCodeEnterButton {
		top: 3.3rem;
		right: .5rem;
	}

	.cart__text {
		font-size: 1.4rem;
	}

	.cart__alert {}

	.cart__alert__text {
		font-size: 1.6rem;
	}

	.cart__alert__icon {
		margin: 0 2.2rem 0 0;
	}
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {
	.cart__total {
		margin: 0 0 5.4rem;
	}

	.cart__total__field.cart__total__field--lg-mod {
		margin: 0 0 $space-xxl;
	}

	.cart__total__field {
		font-size: 1.6rem;
	}

	.c-cart {
		width: 67.4%;
		padding: $space-l $space-xxl $space-3xl;
		margin-right: 3rem;
	}

	.cart__in {
		margin: 0 auto 12.2rem;
		max-width: 91.9rem;
	}

	.cart__list {
		&.cart__list--offset-2-mod {}
	}

	.cart__list--offset-mod {
		padding: 0 0 2rem;
	}

	.cart__subtitle {
		margin: 0 0 1.8rem;
	}

	.cart__promocode {
		padding: $space-l;
		margin: 0 0 $space-xxl;

		&::after {
			width: 100%;
		}
	}

	.cart__text {
		&.cart__text--offset-mod {
			margin: 0 0 5.2rem;
		}
	}

	.cart__empty__head {
		margin: 0 0 10.2rem;
	}

	.cart__empty__title {
		font-size: 5rem;
		margin: 0 0 6rem;

		&--v2-mod {
			font-size: 3.4rem;
		}

		&--offset-mod {
			margin-bottom: 2.4rem;
		}
	}

	.cart__empty__text.offset__mod {
		margin: 0 0 4.8rem;
	}

	.cart__empty__descr {
		margin: 0 0 4.8rem;
	}

	.cart__total__fields {
		margin: 0 0 1.4rem;
	}

	.cart__total__field {
		font-size: 1.6rem;
	}

	#loginForCheckoutPopup.popup.popup--center-mod.popup--actived {
		[data-popup-inner-container="popupInnerContainer"] {
			padding-bottom: 0;
			min-width: unset;
			width: 53rem;
		}
	}

	.c-cart-sidebar {
		width: 32.6%;
	}
}

//---------------------------------------------DESKTOP ONLY###