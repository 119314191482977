//---------------------------------------------fonts
$font_path: "../fonts/";

//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$red: #ED3A35;
$green: #138543;

//---------------------------------------------layout
$height_footer: 10rem;
$height_header: 14.2rem;
$page_width: 100%;
$page_width_2: 174.5rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_mob_indent: 1rem;
$gl_radius: 2rem;

$header_height: 9rem;
@include responsiveFrom(md) {
  $header_height: 8.8rem;
}

@include responsiveFrom(lg) {
  $header_height: 10rem;
}


$header_user_nav_tablet_menu_height: 6.6rem;
$header_user_nav_mobile_menu_height: 6.6rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms

$input_height: 4.5rem;
$input_font_size: 1.6rem;
$input_border_color: getColor('primary');
$input_placeholder_color: getColor('gray-dark');
$input_text_color: getColor('primary');
$input_radius: .4rem;

//---------------------------------------------spacing
$space-xxs: .4rem;
$space-xs: .8rem;
$space-s: 1rem;
$space-m: 1.6rem;
$space-l: 2rem;
$space-xl: 3.2rem;
$space-xxl: 4rem;
$space-3xl: 6rem;
$space-4xl: 8rem;
$space-5xl: 17.8rem;
