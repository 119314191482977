/**
 * Ref: https://www.smashingmagazine.com/2014/06/sassy-z-index-management-for-complex-layouts/
 * In order to maintain the stacking order of the elements,
 * we can set up a Sass list which represents the order in which we want our elements to appear,
 * from lowest to highest, like shown below.
 * (Note that if we want to add an element to the existing stacking order,
 * we just have to update the list with our new element)
 * In order to retrieve the z-index value to assign to each element,
 * you can use the index() sass function, for i.e. .modal {z-index: index($elements, modal)}
 */

$base-elements:  main, header, mainmenu, searchbar, sidebar, modal, zoom, slider-controls, pdp-bar;
//## Playing with system wide z-indexes
$z-i-main     : index($base-elements, main);
$z-i-pdp-bar     : index($base-elements, pdp-bar);
$z-i-header   : index($base-elements, header);
$z-i-searchbar    : index($base-elements, searchbar);
$z-i-sidebar : index($base-elements,sidebar);
$z-i-menu    : index($base-elements, mainmenu);
$z-i-modal    : index($base-elements, modal);
$z-i-zoom    : index($base-elements, zoom);
$z-i-slider-controls: index($base-elements, slider-controls);

//#Header elements z-indexes
$header-elements: search, nav-menu, minicart, menu-mobile;

$z-i-header--search: index($header-elements, search);
$z-i-header--menu: index($header-elements, nav-menu);
$z-i-header--menu-mob: index($header-elements, menu-mobile);
$z-i-header--minicart: index($header-elements, minicart);

//  $modal-elements: fields, form-controls, errors, autocomplete-dropdown;

/**
 * To make to solution scalable across the website, you can add multiple lists, related to specific contexts.
 * Remember that CSS spec informs us that
 * “stacking contexts are generated by any positioned element
 * (including relatively positioned elements) having a computed value of ‘z-index’ other than ‘auto’”,
 * so stick to this spec if you want to create a new stacking context.
 */
