.icon {
	&.icon--size-mod {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.icon--dropdown-mod {

	}

	&.icon__footer-social-mod {
		display: block;
	}

	&.icon-bag {
	}

	&.icon--side-offset-2-mod {
		margin: -4px 1.8rem 0 0;
	}

	&.icon--side-offset-3-mod {
		margin: -4px 1.2rem 0 0;
	}

	&.icon--side-offset-mod {
		margin-top: -4px;
	}

	&.icon--side-offset-v3-mod {
		margin-right: 1.2rem;
	}

	&--reverse-mod {
		transform: rotateZ(180deg);
	}

	&.icon-youtube {
		font-size: 3.8rem;
	}

	&.icon-arrow.up {
		transform: rotate(90deg);
	}
}

// TABLET + MOBILE
@include responsiveTo('md') {
	.icon {
		&.icon__footer-social-mod {
			max-width: 2.4rem;
			height: 1.9rem;
		}

		&.icon--side-offset-mod {
			margin-right: 1.8rem;
		}
	}
}



// TABLET

@include responsiveFromTo('lg', 'md') {
	.icon {
		&.icon__footer-social-mod {
			max-width: 3.5rem;
			height: 2.6rem;
		}

		&.icon--side-offset-mod {
			margin-right: 2rem;
		}
	}
}


// DESKTOP
@include responsiveFrom('lg') {
	.icon {
		&.icon__footer-social-mod {
			max-width: 5rem;
			height: 3.7rem;
		}

		&.icon--side-offset-mod {
			margin-right: 1.8rem;
		}
	}
}

