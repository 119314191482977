.popup {
	@extend %global_flex_block_row_nowrap_center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-color: rgba(getColor('primary'), .6);

	z-index: $z-i-modal !important;

	opacity: 0;
	pointer-events: none;

	overflow-x: hidden;
	overflow-y: auto;

	transition: opacity .3s ease;

	&.popup--actived {
		opacity: 1;
		pointer-events: initial;
	}

	&.popup--side-mod {
		body:not(.disable_top_bar_state) & {
			height: calc(100% - var(--offset));
			margin-top: var(--offset);
		}

		body.page-scroll-state.disable_top_bar_state & {
			height: calc(100% - ((var(--header-height) - var(--c-header-top-height)) - var(--c-header-top-height)));
			margin-top: calc((var(--header-height) - var(--c-header-top-height)) - 2rem);
		}

		body.disable_top_bar_state & {
			height: calc(100% - (var(--header-height) - var(--c-header-top-height)));
			margin-top: calc(var(--header-height) - var(--c-header-top-height));
		}
	}

	&.for-test-page {
		margin: 0 !important;
		position: relative;
		height: 100vh;
		border-bottom: 5px solid getColor('red');

		&:last-of-type {
			position: absolute;
		}
	}

	&.popup--bottom-content-mod {
		align-items: flex-end;
		align-content: flex-end;
	}

	&.popup--bg-mod {
		background-color: $white;
	}

	&.popup--center-mod {
		align-items: center;
		align-content: center;
		justify-content: center;
	}
}

.popup_close {
	position: absolute;

	top: 2.2rem;
	// right: 2rem;

	width: 1.6rem;
	height: 1.6rem;

	color: getColor('primary');

	z-index: 2;
	cursor: pointer;

	.c-product-filter & {
		right: 1.8rem;

		width: 1.8rem;
		height: 1.8rem;
	}

	&.popup_close--size-mod {
		width: 1.8rem;
		height: 1.8rem;
	}

	i {
		font-size: 2rem;
	}

	#miniCart & {
		top: $space-m;
		right: 2.6rem;
	}

}

.popup_close--v2-mod {
	width: 4.6rem;
	height: 4.6rem;
	padding: 1.4rem;

	// background-color: $white;
	// border-radius: 50%;
}

// .popup_close--pos-mod {
// 	top: auto;
// 	bottom: 21.8%;
// 	left: 14.4rem;
// }

.popup_close--pos-2-mod {
	top: 50%;
	// right: 1.4rem;
	// transform: translateY(-50%);
}

.popup__in {
	z-index: 1;
	position: relative;

	&.popup__in--side-mod {
		@extend %popup_transition;
		@extend %global_flex_block_column_nowrap_flex-start_stretch;

		height: 100%;
		margin: 0 0 0 auto;
		overflow-y: auto;

		background-color: getColor('light');

		transform: translateX(100%);

		.popup--actived & {
			transform: translateX(0);
		}
	}

	&.popup_in--cart-layer-mod {
		@extend %popup_transition;
		@extend %global_flex_block_column_nowrap_flex-start_stretch;

		height: 100%;
		margin: 0 0 0 auto;
		padding-left: 0;
		padding-right: 0;

		background-color: getColor('light');

		overflow: hidden;
		// transition: transform .5s ease;
		transform: translateX(100%);

		.popup--actived & {
			transform: translateX(0);
		}
	}

	&.popup__in--search-mod {
		position: static;
		max-width: 100%;
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	&.popup__in--size-popup-mod {
		width: 100%;
		min-height: 32.6rem;

		background-color: getColor('light');
	}

	&.popup__in--v1-mod {
		background-color: $white;
	}

	&.popup__in--v2-mod {
		max-width: 99.2rem;

		background-color: $white;
	}

	&.popup__in--v3-mod {
		background-color: $white;
	}
}

.popup__content {
	max-width: 124rem;
	margin: 0 auto;

	&.popup__content--size-form-mod {
		max-width: 46.2rem;
		margin: 0;
		padding: 0;
	}
}

.popup__title {
	margin: 0 0 1rem;
	@extend %typo-h4;
	font-weight: 400;

	&.popup__title--side-indent-mod {
		padding-right: 3rem;
	}

	#miniCart & {
		margin-bottom: $space-m;
		@extend %typo-h6;
		font-weight: 700;
	}

}

.popup__text {
	margin: 0 0 1.8rem;

	font-size: 1.6rem;

	&:last-child {
		margin: 0;
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin: 0;
		}
	}

	strong {
		font-weight: 700;
	}

	a {
		text-decoration: underline;
		font-weight: 400;

		transition: color .2s ease;
	}

	&.popup__text--offset-2-mod {
		margin: 0 0 4.3rem;
	}

	&.popup__text--offset-3-mod {
		margin: 0 0 1rem;
	}

	&.popup__text--offset-4-mod {
		margin: 0 0 2.5rem;
	}

	.popup_newsletter & {
		font-size: 1.4rem;
	}

	.popup_login & {
		font-size: 1.4rem;
	}
}

.popup__info {
	font-size: 1.2rem;
	text-align: right;

	// padding-right: 1.4rem;

	&.popup__info--offset_mod {
		margin: 0;

		.c-login & {
			margin: 0 0 -.8rem;
		}
	}

	&.popup__info--offset_2_mod {
		margin: 0 0 1rem;
	}
}

.popup_link {
	display: block;
	// font-size: 1.2rem;
	font-size: 1.2rem;
	text-align: right;
	text-decoration: underline;

	transition: color .3s;


	&.popup_link--pos-mod {
		margin-left: auto;
	}

	&.popup_link--pos-2-mod {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	&.popup_link--pos-3-mod {
		text-align: left;
	}

	&.popup_link--visible-mob-mod {
		display: none;
	}

	.popup--cart-login & {
		letter-spacing: .03em;
		margin-bottom: 2.2rem;
	}

}

.popup_error {
	margin: 0 0 1rem;
	font-weight: 400;
	color: getColor('secondary');

	p {
		margin: 0;
	}
}

.popup-login {
	margin: 0 0 3.8rem;

	&.popup-login--offset-0-mod {
		margin: 0;
	}
}

.popup-login__title {
	@extend %global_flex_block_row_nowrap_center_center;

	margin: 0 0 2rem;

	font-size: 2.4rem;
	font-weight: 700;
	font-family: $base-font-family;
	// font-style: italic;
	color: getColor('light');
	text-align: center;
	letter-spacing: .05em;

	&:before,
	&:after {
		content: '';
		height: 1px;
		background-color: #C4C4C4;
		width: 5rem;
		flex-grow: 1;
	}

	span {
		flex-shrink: 0;
	}

	&:before {
		margin: 0 2.4rem 0 0;
	}

	&:after {
		margin: 0 0 0 2.4rem;
	}

	&.popup-login__title--v2-mod {
		margin: 0 0 2.4rem;
		font-size: 1.6rem;
		letter-spacing: .05em;

		&:before,
		&:after {
			flex: auto;
		}

		&:before {
			margin: 0 1rem 0 0;
		}

		&:after {
			margin: 0 0 0 1rem;
		}
	}

	&.popup-login__title--offset-mod {
		margin-bottom: 3.6rem;
	}

	.popup--cart-login & {
		font-size: 1.6rem;
		color: getColor('primary');
		font-family: $base-font-family;
		font-style: normal;
		text-transform: uppercase;
		font-weight: 400;

		&:before,
		&:after {
			width: unset;
		}

		&:before {
			margin: 0 1.8rem 0 0;
		}

		&:after {
			margin: 0 0 0 1.8rem;
		}
	}
}

.popup-login__list {
	@extend %global_flex_block_row_wrap_center;
	margin: 0 -1.8rem -1.4rem 0;

	.popup--cart-login & {
		margin: 0 -1.8rem -1.8rem 0;
	}
}

.popup-login__item {
	padding: 0 1.8rem 1.4rem 0;

	.popup--cart-login & {
		padding: 0 1.8rem 1.8rem 0;
	}
}

.popup-login__link {
	display: block;
	width: 4rem;
	height: 4rem;

	.c-login &,
	&.popup-login__link--size-mod {
		width: 4.8rem;
		height: 4.8rem;
	}
}

.popup-login__logo {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}



// bottom

.popup-bottom {
	width: 100%;
	margin: auto 0 0;

	.popup__in.popup_in--cart-layer-mod & {
		flex: none;
	}
}

.popup-bottom__price {
	@extend %global_flex_block_row_nowrap_flex-start_center;

	margin: 0 0 2rem;
	border-bottom: 1px solid getColor('gray-dark');

	#miniCart & {
		margin: 0 -2rem $space-l -2rem;
	}
}

.popup-bottom__price__title {
	margin: 0 2rem 0 0;
	font-weight: 700;
}

.popup-bottom__price__value {
	margin: 0 0 0 auto;
	font-weight: 700;
}

// country

.popup-country__head {
	text-align: center;

	p {
		margin: 0;
	}
}

.popup-country__in {
	@extend %global_flex_block_column_wrap_flex-start;
}

.popup-country__block__title {
	@extend %global_flex_block_row_wrap_flex-start_center;

	font-size: 1.8rem;
	font-weight: 700;
	letter-spacing: .05em;
}

.popup-country__block__title__trigger {
	width: 1.6rem;
	height: .8rem;
	margin: 0 0 0 auto;

	transform-origin: center;
	transition: transform .3s;

	.popup-country__block.popup-country__block--active-mod & {
		transform: rotateZ(180deg);
	}
}

.popup-country__list {
	@extend %global_flex_block_row_wrap_flex-start;
	margin: 0 -4rem -1.2rem 0;

	&.popup-country__list--offset-mod {
		margin: 0 -8rem -1.2rem 0;
	}
}

.popup-country__link {
	text-decoration: none;
	transition: color .3s;

	&:hover,
	&:focus {
		color: getColor('secondary');
	}
}

.popup-country__link__title {
	font-size: 1.4rem;
	font-weight: 700;
	//margin-bottom: .2rem;
}

// search

.popup-search__form {
	position: relative;
}


.popup-search__title {
	font-weight: 700;
}

.popup_search__item {

	&:last-child {
		margin: 0;
	}
}

.popup_search__link {
	font-size: 1.4rem;
	font-weight: 400;
	text-decoration: none;
	transition: color .3s;

	&:hover,
	&:focus {
		color: getColor('secondary')
	}
}

.popup__form {
	width: 100%;
}


.popup__form__head {
	width: 100%;
	margin-bottom: 1.4rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.popup__form__title {
	@extend %title_base;
}

.popup__form__allert-text {
	display: none;

	width: 100%;
	font-weight: 700;

	color: #FE0000;

	&.popup__form__allert-text--active-mod {
		display: block;
	}
}

.popup-cart {
	.popup__in.popup_in--cart-layer-mod & {
		overflow-x: hidden;
		overflow-y: auto;

		height: calc(100% - 2.2rem);
		max-height: calc(100% - 2.2rem);
		margin-bottom: 2.2rem;
	}
}

.popup-cart__in {
	width: 100%;
}

// ----------------- >= DESKTOP
@include responsiveFrom('lg') {

	.c-cancel-order--popup-mod {
		max-height: 80vh;
		// height: 63.6rem;
		padding-top: 5.3rem;
	}

	.popup {

		body:not(.page-scroll-state) & {
			--offset: var(--header-height);
		}

		body.page-scroll-state:not(.disable_top_bar_state) & {
			--offset: calc(var(--header-height) - 2rem);
		}
	}

	.popup__in.popup__in--side-mod {
		width: 37.8rem;
	}

	.popup__in.popup_in--cart-layer-mod {
		width: 38rem;
	}

	.popup__form__title {
		font-size: 2.4rem;
	}

	.popup__form__allert-text {
		font-size: 1.8rem;
	}

	.popup_error {
		font-size: 1.4rem;
	}

	.popup__title {

		&.popup__title--size-mod {
		}

		&.popup__title--size-2-mod {
		}

		&.popup__title--size-v2-mod {
		}

		&.popup__title--size-3-mod {

		}

		&.popup__title--size-4-mod {
		}

		&.popup__title--offset-mod {
			margin: 0 0 2.2rem;
		}

		&.popup__title--offset-2-mod {
			margin: 0 0 2.6rem;
		}

		&.popup__title--offset-3-mod {
			margin: 0 0 2rem;
		}

		&.popup__title--offset-4-mod {
			margin: 0 0 1.8rem;
		}

		&.popup__title--offset-5-mod {
			margin: 0 0 3.2rem;
		}

		&.popup__title--offset-6-mod {
			margin: 0 0 2rem;
		}

		&.popup__title--offset-7-mod {
			margin: 0 0 1.2rem;
		}

		&.popup__title--offset-8-mod {
			margin: 0 0 1rem;
		}

		&.popup__title--offset-9-mod {
			margin: 0 0 5.4rem;
		}
	}

	.popup-bottom__price__title {
		font-size: 1.8rem;
	}

	.popup-bottom__price__value {
		font-size: 1.8rem;
	}

	.popup-bottom__price {
		padding: 0 $space-l 1rem;
	}

	.popup__text--offset-mod {
		margin: 0 0 2rem;
	}

	.popup__text--lg-mod {
		font-size: 1.6rem;
	}

	.popup_search__link {
		font-size: 1.8rem;
	}



}

// ----------------- >= DESKTOP###

// ----------------- >= TABLET
@include responsiveFrom('md') {

	.popup__in.popup__in--side-mod {
		padding: $space-l $space-m;
	}

	.popup__in.popup_in--cart-layer-mod {
		padding: 2.2rem $gl_indent 3.8rem;
	}


	.popup__in.popup__in--offset-mod {
		padding-bottom: 2rem;
	}

	.popup__in.popup__in--v2-mod {
		padding-top: 3.4rem;
		// margin-top: 14vh;
		margin: auto;
	}

	.popup__in.popup__in--search-mod {
		padding: 19.2rem $gl_indent;
	}

	.popup__in.popup__in--size-popup-mod {
		padding: 3rem 7.4rem 4.4rem;
	}

	.popup-search__title {
		margin: 0 0 3.4rem;
		font-size: 2.2rem;
	}

	.popup-search__form {
		margin: 0 0 5.2rem;
	}

	.popup-country__head {
		padding: 0 4.2rem 5rem;
	}

	.popup-country {
		background-color: getColor('green');
		padding: 4rem 4rem 5.8rem 4.2rem;
	}

	.popup-country__in {
		max-height: 47rem;
		margin: 0 -11.4rem -3.6rem 0;
	}

	.popup-country__block {
		max-width: 50%;
		width: 100%;
		padding: 0 11.4rem 2.6rem 0;

		&:first-child {
			max-width: 50%;
		}
	}

	.popup-country__block__title {
		font-family: $base-font-family;

		padding: 0 0 .6rem;
		margin: 0 0 1.6rem;
		border-bottom: 1px solid getColor('gray-dark');
	}

	.popup-country__list__item {
		width: 33.33%;

		padding: 0 3rem 1.2rem 0;
	}

	.popup-country__block__title__trigger {
		display: none;
	}

	.popup-cart {
		.popup__in.popup_in--cart-layer-mod & {
			margin-left: (-$gl_indent);
			margin-right: (-$gl_indent);

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
			}
		}
	}

	.popup_search__item {
		margin: 0 0 1.7rem;
	}

	.popup-country__link__title {
		margin-bottom: .2rem;
	}

	.popup-country__link__value {
		// font-size: 1.2rem;
		font-size: 1.4rem;
		font-weight: 300;
	}

}

// ----------------- >= TABLET###

// ----------------- TABLET
@include responsiveFromTo('lg', 'md') {

	.c-cancel-order--popup-mod {
		max-height: 70vh;
		// height: 53.6rem;
		padding-top: 4.4rem;
	}

	.popup {
		--offset: 120px;
	}

	.popup__title--size-mob-mod {
		font-size: 2.2rem;
	}

	.popup__title {

		&.popup__title--size-mod {
		}

		&.popup__title--offset-2-mod {
			margin: 0 0 2rem;
		}

		&.popup__title--offset-3-mod {
			margin: 0 0 2.4rem;
		}

		&.popup__title--offset-6-mod {
			margin: 0 0 1.8rem;
		}

		&.popup__title--offset-7-mod {
			margin: 0 0 1.8rem;
		}
	}

	.popup__in.popup__in--side-mod {
		width: 38.2rem;
	}

	.popup__in.popup_in--cart-layer-mod {
		width: 38.4rem;
	}


	.popup_link {
		.c-login & {
			font-size: 1.4rem;
			margin-bottom: 3.4rem;
		}
	}

	.popup-login__title {
		.c-login & {
			font-size: 2.4rem;
			margin: 0 0 2rem;

			&:before,
			&:after {
				max-width: 6.9rem;
				width: 100%;
			}

			&:before {
				margin: 0 2rem 0 0;
			}

			&:after {
				margin: 0 0 0 2rem;
			}
		}
	}

	.popup-login__list {
		.c-login & {
			padding: 0 -2.4rem -2.4rem 0;
		}
	}

	.popup-login__item {

		.c-login & {
			padding: 0 1.6rem 2.4rem 0;
		}
	}

	.popup__text {
		&.popup__text--offset-3-mod {
			margin: 0 0 2rem;
		}

		&.popup__text--offset-4-mod {
			margin: 0 0 2rem;
		}
	}
}

// ----------------- TABLET###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
	.popup_close {
		right: 2rem;
		top: 2.4rem;

		&.popup_close--size-2-mod {
			width: 1.6rem;
			height: 1.6rem;
		}

		&.popup_close--size-3-mod {
			width: 2rem;
			height: 2rem;
		}

		&.popup_close--pos-1-mod {
			right: 2.8rem;
			top: 3.6rem;
		}

		&.popup_close--pos-2-mod {
			right: 2.8rem;
			top: 2.6rem;
		}

		&.popup_close--pos-3-mod {
			right: 2.6rem;
		}

		&.popup_close--pos-4-mod {
			top: 3.2rem;
			right: 3.4rem;
		}

		&.popup_close--pos-5-mod {
			top: 2.4rem;
			right: 2.6rem;
		}

		&.popup_close--pos-mod {
			top: 2.7rem;
		}

		&.popup_close--pos-6-mod {
			top: $space-m;
			right: 2.6rem;
		}

		[data-js-component="PopupLoginComponent"] &{
			right: 2.5rem;
			top: $space-l;
		}

	}

	.popup__in--v1-mod {
		padding: 5.6rem 4.6rem 7.2rem;
		min-width: 72.8rem;
		max-height: 100vh;

		overflow-y: auto;
	}

	.popup__in--v3-mod {
		padding: $space-l;
		max-width: 36.2rem;
	}

	.popup__content {
		padding: 0 10.4rem 0 0;
	}

	.popup {
		&.popup--center-mod {
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	.popup-bottom__price {
		padding: 0 0 1.2rem;

		#miniCart & {
			padding: 0 $space-l $space-l;
		}
	}
}

//---------------------------------------------TABLET + DESKTOP###

// ----------------- < DESKTOP
@include responsiveTo('lg') {

	.popup_error {
		font-size: 1.4rem;
	}

	.popup__form__allert-text {
		font-size: 1.6rem;
	}

	.popup__title {

		&.popup__title--offset-mod {
			margin: 0 0 2.6rem;
		}
		&.popup__title--offset-5-mod {
			margin: 0 0 2.8rem;
		}

		&.popup__title--offset-9-mod {
			margin: 0 0 5.2rem;
		}
	}

	.popup-bottom__price__title {
		font-size: 2rem;
	}

	.popup-bottom__price__value {
		font-size: 2rem;
	}


	.popup__text--offset-mod {
		margin: 0 0 2.2rem;
	}

	.popup__text--lg-mod {
		font-size: 1.4rem;
	}

	.popup__text--lg-2-mod {
		font-size: 1.6rem;
	}
}

// ----------------- < DESKTOP###

// ----------------- DESKTOP
@include responsiveFrom('lg') {
	.popup__in.popup__in--v2-mod {
		margin-top: 14vh;
	}

	.popup_link {
		margin: 0 0 2rem;

		.c-login & {
			margin-bottom: 2.2rem;
		}
	}

	.popup-login__title {
		.c-login & {
			margin: 0 0 2rem;

			&:before,
			&:after {
				max-width: 9.4rem;
				width: 100%;
			}

			&:before {
				margin: 0 2rem 0 0;
			}

			&:after {
				margin: 0 0 0 2rem;
			}
		}
	}
}

// ----------------- DESKTOP###

// ----------------- < TABLET
@include responsiveTo('md') {

	.c-cancel-order--popup-mod {
		height: 100%;
		padding: 10rem 0 7.6rem;
	}

	.popup {
		--offset: 90px;

		&.popup--hide-mob-mod {
			display: none;
		}
	}

	.popup_close {
		right: $gl_mob_indent;

		&.popup_close--size-mob-mod {
			width: 1.8rem;
			height: 1.8rem;
		}

		&.popup_close--size-2-mod {
			width: 1.6rem;
			height: 1.6rem;
		}

		&.popup_close--pos-mod {
			top: 3.4rem;
		}

		&.popup_close--pos-2-mod {
			top: 2.3rem;
			right: 1.8rem;
		}


	}

	.popup_close--hidden-mob-mod {
		display: none;
	}

	.popup_link {

		.c-login & {
			margin-bottom: 1rem;
			font-size: 1.4rem;
		}
	}


	.popup__info.popup__info--offset_mod {

		.c-login & {
			margin: 0 0 -1rem;
		}
	}

	.popup__info {
		&.popup__info--offset_2_mod {
			margin: 0 0 2.4rem;
		}
	}

	.popup__in.popup__in--offset-mod {
		padding-bottom: 2rem;
	}

	.popup__in--v1-mod {
		max-height: 100vh;
		width: 100%;
		padding: 8.6rem $gl_mob_indent 4rem;

		overflow-y: auto;
	}

	.popup__in--v2-mod {
		min-height: 100%;
		width: 100%;
		padding: 9.5rem $gl_mob_indent 4rem;
	}

	.popup__in--v3-mod {
		width: 100%;
		height: 100%;
		padding: 8.8rem $gl_mob_indent 4.8rem;
	}

	.popup__in--search-mod {
		padding: 11.6rem $gl_mob_indent 4rem;
	}

	.popup__in--size-popup-mod {
		padding: 5rem $gl_mob_indent 4rem;
	}


	.popup-search__title {
		margin: 0 0 3.8rem;
		letter-spacing: .056em;
		font-size: 2rem;
	}

	.popup-search__form {
		margin: 0 0 5.4rem;
	}

	.popup-country {
		padding: 0;
	}

	.popup-country__head {
		margin: 0 auto 1.8rem;
		max-width: 89%;
	}

	.popup-country__block {
		width: 100%;

		border-top: 1px solid getColor('gray-dark');

		&:first-child {
			border: 0;
			padding-top: 0;
		}
	}

	.popup-country__block__title {
		padding: 1.4rem 1rem 1.6rem 0;
		font-size: 1.6rem;

		span {
			padding: 0 2rem 0 0;
		}

		.popup-country__block:last-child & {
			border: 0;
		}
	}

	.popup-country__list-wrap {
		@include style_for_accordion_body();

		margin-left: calc(var(--gl-indent) * -1);
		width: calc(100% + var(--gl-indent) * 2);

		.popup-country__block.popup-country__block--active-mod & {
			@include style_for_accordion_body_active_state();
		}
	}

	.popup-country__list {
		position: relative;

		padding: 1.2rem var(--gl-indent) 3.2rem;
		margin: 0;

		background-color: getColor('light');
	}

	.popup-country__list__item {
		width: 100%;
		margin: 0 0 1.2rem;

		&:last-child {
			margin: 0;
		}
	}

	.popup-country__link__title {
		letter-spacing: .07em;
	}

	.popup__info {
		margin: 0 1.2rem 0 .2rem;
	}

	.popup__in.popup__in--side-mod {
		width: 100%;
		padding: 1.8rem $gl_mob_indent 5.8rem;
	}

	.popup__in.popup_in--cart-layer-mod {
		width: 100%;
		padding: 2.2rem $gl_mob_indent 2.8rem;
	}

	.popup__title {

		&.popup__title--size-mod {
		}

		&.popup__title--size-2-mod {
		}

		&.popup__title--size-mob-mod {
		}

		&.popup__title--offset-6-mod {
			margin: 0 0 2rem;
		}

		&.popup__title--offset-3-mod {
			margin: 0 0 1.8rem;
		}

		&.popup__title--offset-8-mod {
			margin: 0 0 .8rem;
		}
	}

	.popup__text {
		margin-bottom: 2.4rem;

		&.popup__text--offset-3-mod {
			margin-bottom: 1.6rem;
		}
	}

	.popup_link {
		&.popup_link--hidden-mob-mod {
			display: none;
		}

		&.popup_link--visible-mob-mod {
			display: block;
		}

		&.popup_link--pos-2-mod {
			margin: 0 0 3.2rem;
		}
	}

	.popup-cart {
		.popup__in.popup_in--cart-layer-mod & {
			margin-left: (-$gl_mob_indent);
			margin-right: (-$gl_mob_indent);
			padding-left: $gl_mob_indent;
			padding-right: $gl_mob_indent;

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
			}
		}
	}

	.popup-login__title {
		.c-login & {
			margin: 0 0 2rem;
			font-size: 1.8rem;

			&:before,
			&:after {
				max-width: 6.7rem;
				width: 100%;
			}

			&:before {
				margin: 0 1.2rem 0 0;
			}

			&:after {
				margin: 0 0 0 1.2rem;
			}
		}
	}

	.popup-login__list {
		.c-login & {
			margin: 0 -1.8rem -2.8rem 0;
		}
	}

	.popup-login__item {

		.c-login & {
			padding: 0 1.8rem 2.8rem 0;
		}
	}

	.popup_search__item {
		margin: 0 0 1.5rem;
	}

	.popup-country__link__value {
		// font-size: 1.2rem;
		font-size: 1.2rem;
	}
}