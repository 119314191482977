.c-cart__item {
  .c-checkout-sidebar-total & {
    background-color: getColor('light');
  }
}


.popup-cart__in {}

.c-cart__item__img__wrap {
  .c-checkout-sidebar-total & {
    height: 13rem;
    width: 11rem;
  }
}

.c-cart__item__img__in {
  //minicart
  display: block;
  width: 100%;
  object-fit: contain;
}

.c-cart__item__content {

  .c-checkout-sidebar-total & {
    padding: 1.2rem 0 1rem;
  }

  .popup-cart & {
    padding: 0;
  }
}

.c-cart__item__content__in {}

// ---------------------------------------------------------------------------
//cartpage
.c-cart__item {
  @extend %global_flex_block_row_nowrap_flex-start_stretch;
  border-bottom: 1px solid getColor('gray-dark');
  margin: 0;
  padding-top: $space-l;


  &.c-cart__item--v2-mod {
    &:last-child {
      border: none;
    }
  }

  .c-cart__item__img__wrap {
    @extend %global_flex_block_row_nowrap_center_center;

    flex-shrink: 0;
    min-height: 13rem;
    width: 9rem;

    margin: 0;

    .c-cart__item__img {
      width: 100%;
      height: 100%;
    }
  }

  .c-cart__item__content {
    @extend %global_flex_block_column_nowrap_space_between;
    flex-grow: 1;
    padding: 0 $space-m $space-l;

    .c-cart__item__content__in {
      @extend %global_flex_block_row_nowrap_flex-start_stretch;
      width: 100%;

      .c-cart__item__col {
        width: 100%;
        margin-bottom: $space-xl;

        .c-cart__item__head-content-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: $space-xs;

          .c-cart__item__title {
            margin: 0;

            @extend %typo-text-2;
            font-weight: 700;
            color: getColor('primary');

          }

          .c-cart__item__price {
            @extend %global_flex_block_row_nowrap_flex-end_center;
            @extend %typo-text-1;
            font-weight: 400;
            color: getColor('primary');

            .c-cart__item__price__current {
              @extend %typo-text-1;
              font-weight: 400;
              color: getColor('primary');
            }

            .c-cart__item__price__old {
              @extend %product_price_line;
              margin: 0 $space-xxs 0 0;
              @extend %typo-text-1;
              font-weight: 400;
              color: getColor('primary');

              &+.c-cart__item__price__current {
                color: getColor('error');
              }
            }

          }
        }

        .c-cart__item__info {
          margin: 0 0 1rem;

          .c-cart__item__info__item {
            @extend %typo-text-2;
            font-weight: 400;
            color: getColor('primary');
          }
        }

        .c-cart__item__select {
          position: relative;
          display: inline-flex;

          .c-cart__item__select__in {
            min-width: 10.3rem;
            padding-bottom: .2rem; //custom
            font-weight: 400;
            display: inline-flex;
            vertical-align: top;
            color: getColor('primary');
            overflow: hidden;
            background-color: getColor('light');
            border: none;
            @extend %typo-text-2;
            border-bottom: 1px solid getColor('primary');
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

          .c-cart__item__select__arrow {
            z-index: 2;
            position: absolute;
            width: 2rem;
            height: 2rem;
            padding: .6rem;
            pointer-events: none;
            bottom: .1rem;
            right: 0;
            color: getColor('primary');
          }
        }

      }
    }

    .c-cart__item__link__w {
      @extend %global_flex_block_row_wrap_flex-end_center;
      width: 100%;

      .c-cart__item__link {
        position: relative;
        display: block;

        @extend %typo-text-2;
        font-weight: 700;
        text-decoration: none;

        border-bottom: 1px solid getColor('primary');
        transition: border-color .2s;

        &:hover,
        &:focus {
          text-decoration: none;
          border-color: transparent;
        }


        .c-cart__item--v2-mod & {
          font-size: 1.4rem;
        }

        &.c-cart__item__link--border-0-mod {
          border: 0;

          &:focus {
            outline: none;
          }
        }
      }

      .c-cart__item__link__icon {
        @extend %global_flex_block_row_wrap_center_center;

        position: relative;

        color: $white;

        transition: .3s;

        .c-cart__item__link:hover &,
        .c-cart__item__link:focus & {
          color: getColor('red');
        }

        .icon {
          z-index: 2;
        }

        &::after {
          z-index: 1;

          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          border-radius: 50%;
          background-color: getColor('primary');

          transition: .3s;

          content: '';

          .c-cart__item__link:hover &,
          .c-cart__item__link:focus & {
            color: getColor('error');
            background-color: $white;
            border-color: getColor('error');
          }
        }
      }

    }
  }
}

//------------------------------- minicart ----------------------------------
.popup-cart__in {

  //working from tablet
  .c-cart__item {
    padding: $space-l;

    .c-cart__item__img__wrap {
      height: 10rem;
      width: 9.2rem;
    }

    .c-cart__item__content {
      padding: 0;
      margin-left: $space-l;

      .c-cart__item__content__in {
        margin-bottom: $space-xs;

        .c-cart__item__col {

          .c-cart__item__title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: $space-xs;

            .custom-title-product {
              @extend %typo-text-2;
              font-weight: bold;
              color: getColor('primary');
              margin: 0;

              +div {
                padding-bottom: 0;
              }
            }
          }

          .c-cart__item__price {
            @extend %global_flex_block_row_nowrap_flex-end_center;
            justify-content: flex-end;
            @extend %typo-text-2;
            font-weight: 400;
            color: getColor('primary');

            .c-cart__item__price__current {
              @extend %typo-text-2;
              font-weight: 400;
              color: getColor('primary');
            }

            .c-cart__item__price__old {
              @extend %product_price_line;
              margin: 0 $space-xxs 0 0;
              @extend %typo-text-2;
              font-weight: 400;
              color: getColor('primary');

              &+.c-cart__item__price__current {
                color: getColor('error');
              }
            }

          }

          .c-cart__item__info__item {
            margin-bottom: $space-xxs;
          }

          .c-cart__item__select {
            .c-cart__item__select__in {}
          }
        }
      }
    }
  }
}

// ------------------------------checkout------------------------------------
.c-checkout-sidebar {
  .c-cart__item {
    border-top: none;
    border-bottom: 1px solid getColor('green');

    &:first-child {
      border-top: 1px solid getColor('green');
    }

    .c-cart__item__img__wrap {
      background-color: getColor('gray-bg');
      width: 10rem;
      height: auto;
    }

    .c-cart__item__content {

      .c-cart__item__content__in {
        justify-content: space-between;

        .c-cart__item__col {
          margin-bottom: 0;

          &.c-cart__item__col--v2-mod {
            width: auto;

            .c-cart__item__price {
              @extend %global_flex_block_row_nowrap_flex-end_center;
              @extend %typo-text-2;
              font-weight: 400;
              color: getColor('primary');

              .c-cart__item__price__current {
                margin: 0 0 0 $space-xxs;
              }

              .c-cart__item__price__old {
                @extend %product_price_line;
               

                &+.c-cart__item__price__current {
                  color: getColor('error');
                }
              }

            }
          }

          .c-cart__item__title {
            margin-bottom: $space-xs;
            @extend %typo-text-2;
            font-weight: 700;
            color: getColor('primary');
          }

          .c-cart__item__info {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


//---------------------------------------------MOBILE ONLY
@include responsiveTo('sm') {

  .c-cart__item.c-cart__item--v2-mod {
    .c-cart__item__col {
      padding-right: 0;
    }

    .c-cart__item__link__w--show-mob-mod {
      display: none;
    }

    .c-cart__item__content__in {
      flex-wrap: wrap;
    }

    .c-cart__item__col {
      width: 100%;
      margin: 0 0 1.4rem;
    }

    .c-cart__item__col--v2-mod {
      display: block;
      margin: 0;
      padding: 0;
    }

    .c-cart__item__link__w {
      align-items: flex-start;
      align-content: flex-start;
    }

    .c-cart__item__link {
      margin: 0 0 1rem;

      &:last-child {
        margin: 0;
      }
    }

    .c-cart__item__img__wrap {}

    .c-cart__item__price {
      justify-content: flex-start;
    }
  }

  .c-cart__item.c-cart__item--v2-mod.c-cart__item--style-mod {
    .c-cart__item__link__w {
      flex-direction: row;
      justify-content: flex-start;
    }

    .c-cart__item__link {
      margin: 0 4.6rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

@include responsiveFromTo('md', 'sm') {
  .c-cart__item.c-cart__item--v2-mod {

    .c-cart__item__link--hide-mob-mod {
      display: none;
    }

    .c-cart__item__link__w--show-mob-mod {
      display: flex;
    }

    .c-cart__item__link__w {
      align-items: flex-end;
      align-content: flex-end;
    }

    .c-cart__item__link {
      margin: 0;
    }

    .c-cart__item__img__wrap {}
  }
}

@include responsiveTo('md') {

  //cartpage
  .c-cart__item {
    .c-cart__item__content {
      .c-cart__item__content__in {
        .c-cart__item__col {
          .c-cart__item__head-content-wrapper {
            .c-cart__item__price {

              .c-cart__item__price__old,
              .c-cart__item__price__current {
                font-size: 1.4rem; //custom!!

              }
            }
          }
        }
      }

      .c-cart__item__link__w {
        &:has(.save-for-later-link) {
          justify-content: space-between;
        }
      }
    }
  }


}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg') {

  .c-cart__item {
    &:last-child {
      margin-bottom: 0;
    }
  }


  .c-cart__item.c-cart__item--v2-mod {
    //cart page
    box-shadow: unset;
    border-bottom: 1px solid getColor('gray-dark');

    &:last-child {
      border: none;
    }
  }

  .c-cart__item__img__wrap {}
}

//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------MOBILE + DESKTOP

@include responsiveFrom('sm') {}

//---------------------------------------------MOBILE + DESKTOP###

//---------------------------------------------TABLET + DESKTOP

@include responsiveFrom('md') {


  //cartpage
  .c-cart__item {
    padding: $space-m 0;

    .c-cart__item__img__wrap {
      height: 14.4rem;
      width: 12rem;
    }

    .c-cart__item__content {
      padding: $space-s $space-l;

      .c-cart__item__content__in {
        margin: 0;

        .c-cart__item__col {
          margin: 0;
        }
      }

      .c-cart__item__link__w {
        .save-for-later-link {
          margin-right: $space-xl;
        }
      }
    }
  }

  //checkoout
  .c-checkout-sidebar {
    .c-cart__item {
      padding: 0;

      .c-cart__item__img__wrap {
        width: 11.1rem;
      }

      .c-cart__item__content {
        padding: $space-l;

        .c-cart__item__content__in {

          .c-cart__item__col {

            &.c-cart__item__col--v2-mod {
              width: auto;
            }
          }
        }
      }
    }
  }
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {

  .c-cart__item {
    .c-cart__item__img__wrap {
      height: 16.9rem;
      width: 14.2rem;
    }

    .c-cart__item__content {
      padding: $space-l 0 $space-l $space-xl;
    }
  }

  //checkout
  .c-checkout-sidebar {
    .c-cart__item {

      .c-cart__item__img__wrap {
        width: 24.71%;

        .c-cart__item__img {
          height: auto;
        }
      }

      .c-cart__item__content {

        .c-cart__item__content__in {

          .c-cart__item__col {

            &.c-cart__item__col--v2-mod {
              flex-basis: 50%;

              .c-cart__item__price {
                flex-wrap: wrap;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
}


//---------------------------------------------DESKTOP ONLY###