//---------------------------------------------c-product-label
.c-product-label {
	display: inline-flex;
	justify-content: center;

	color: getColor('light');
	@extend %typo-text-2;
	text-align: center;
	font-weight: 400;

	background-color: getColor('primary');
	border-radius: 5rem;

	padding: $space-xxs $space-s;

	&.c-product-label--position-mod {
		z-index: $z-i-main;
		position: absolute;
	}

}
//---------------------------------------------c-product-label###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	.c-product-label {

		
		@media not all and (min-resolution:.001dpcm) {
			@supports (-webkit-appearance:none) {
			}
		}
		
		&.c-product-label--position-mod {
			padding: 0 $space-s;
			top: .7rem;
			left: 1.4rem;
		}

		&.c-product-label--descr-product-mod {
		}
	}
}
//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg') {

}
//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-product-label {


		@media not all and (min-resolution:.001dpcm) {
			@supports (-webkit-appearance:none) {
			}
		}

		&.c-product-label--position-mod {
		}

		&.c-product-label--descr-product-mod {
		}

		.cart__interested & {

			@media not all and (min-resolution:.001dpcm) {
				@supports (-webkit-appearance:none) {
				}
			}

			&.c-product-label--position-mod {
				top: .6rem;
				left: 1.2rem;
			}
		}
	}
}
//---------------------------------------------TABLET ONLY###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {
	.c-product-label {
		text-align: center;

		@media not all and (min-resolution:.001dpcm) {
			@supports (-webkit-appearance:none) {
			}
		}

		.body--macos_state & {
			align-items: baseline;
		}

		.c-cart-interested-products__slider__w & {

			@media not all and (min-resolution:.001dpcm) {
				@supports (-webkit-appearance:none) {
					line-height: 2.1rem;
				}
			}
		}

		&.c-product-label--position-mod {
			top: 1.6rem;
			left: 1rem;

			.c-cart-interested-products__slider__w & {
				top: .7rem;
				left: 1.4rem;
			}
		}

		&.c-product-label--descr-product-mod {

		}
	}
}
//---------------------------------------------DESKTOP ONLY###
