.c-product__size__select__list {
	@extend %global_flex_block_row_wrap_flex-start;
	gap: $space-s;
}

.product__size__select__item {
	// display: inline-flex;
}

.product__size__select__input {
	position: absolute;
	opacity: 0;
	// visibility: hidden;

}

.product__size__select__label {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .8rem;

	@extend %typo-text-3;
	font-weight: 400;
	color: getColor('primary');

	border: 1px solid getColor('primary');
	border-radius: 5rem;
	cursor: pointer;

	transition: color .3s, background-color .3s, border-color .3s;

	width: 4.2rem;
	height: 4.2rem;

	.product__size__select__input:checked+& {
		background: getColor('primary');
		font-weight: 700;
		color: getColor('light');
	}

	.c-product__size__select--error-state & {
		border-color: getColor('error');
	}

}

.c-product__size__select__error {
	display: none;
	width: 100%;
	padding-right: 2rem;
	margin-bottom: .8rem;

	@extend %typo-text-2;
	color: getColor('error');

	.c-product__size__select--error-state & {
		display: block;
	}
}

@include responsiveFromTo('lg', 'md') {
	.c-product__size__select__list {

		&.product__size__select--offset-mob-mod {}
	}

	.product__size__select__item {}
}


// ----------------- >= TABLET
@include responsiveFrom('md') {
	.product__size__select__label {
		width: 4.8rem;
		height: 4.8rem;
	}
	.c-product__size__select__list {}


	.product__size__select__item {}
}

// ----------------- >= TABLET###

// ----------------- < TABLET
@include responsiveTo('md') {
	.c-product__size__select__list {}

	.product__size__select__item {}

	.product__size__select--offset-mob-mod {
		padding: 0 0 3.2rem;
	}
}

// ----------------- < TABLET###