.breadcrumbs {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.breadcrumbs__item {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.breadcrumbs__link {
	display: block;
	@extend %typo-text-2;
	font-weight: 400;
	color: getColor('gray-dark');
	text-decoration: none;
	text-transform: capitalize;

	.breadcrumbs__item:last-child & {
		margin: 0;
		font-weight: 700;
		color: $black;
	}
}

.icon {
	display: block;
	font-size: 1rem;

	&.right {
		transform: rotate(180deg);
	}
}

// ----------------- >= DESKTOP
@include responsiveFrom('lg') {
	.breadcrumbs--offset-mod {
		margin: 0 0 1.2rem;
	}

	.breadcrumbs__item {
		&:not(:last-child) {
			.c-product-hero & {
				margin: 0 1rem 0 0;
			}
		}
	}

	.breadcrumbs__link {
		margin: 0;

		.c-product-hero & {
			margin: 0 1rem 0 0;
		}
	}
}

// ----------------- >= TABLET
@include responsiveFrom('md') {
	.breadcrumbs--show-mob-mod {
		display: none;
	}

	.breadcrumbs__item {
		&:not(:last-child) {
			margin: 0;
		}
	}
}

// ----------------- >= TABLET###

// ----------------- TABLET
@include responsiveFromTo('lg', 'md') {
	.breadcrumbs--offset-mod {
		margin: 0 0 1rem;
	}

	.breadcrumbs__link {
		.c-product-hero & {}
	}
}

// ----------------- TABLET###

// ----------------- < DESKTOP
@include responsiveTo('md') {
	.breadcrumbs--offset-mod {
		margin: 0 0 1.1rem;
	}

	.breadcrumbs--offset-2-mod {
		margin: 0 0 1rem;
	}

	.breadcrumbs--hide-mob-mod {
		display: none;
	}

	.breadcrumbs--show-mob-mod {
		display: flex;
	}

	.breadcrumbs__item {
		&:not(:last-child) {
			margin: 0 .6rem 0 0;
		}
	}

	.breadcrumbs__link {
		margin: 0 .2rem 0 0; //out of spacing
	}
}