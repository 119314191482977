@use "sass:map";

.c-footer {
	position: relative;
	flex: 0 0 auto;
	padding: 0 var(--gl-indent);
	background-color: getColor('gray');
	color: getColor('primary');
}

.c-footer__logo {
	display: block;
	width: 10rem;
	height: 11.9rem;
}

.c-footer__logo__img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.footer-menu__item {
	display: block;
	@extend %typo-text-2;
	color: getColor('primary');
	font-weight: 400;
}

.footer-social {
	@extend %global_flex_block_row_nowrap_center_center;

	position: relative;

	// min-height: 5.5rem;

	// padding: .8rem 0;
	color: getColor('light');

	z-index: 1;
	justify-content: start;

}

.footer__copyright {
	position: relative;

	padding: 1.2rem 0;
	@extend %typo-text-3;
	text-align: center;

	&::after {
		z-index: -1;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);

		width: 100vw;
		height: 100%;

		background-color: getColor('light');
		content: '';
	}
}

.footer-country {
	@include responsiveFromTo('md', 'lg') {
		display: flex;
		justify-content: center;
	}
}

.footer-subscribe__title {
	display: flex;
	position: relative;
	margin: 0 0 1rem;
	@extend %typo-text-2;
	text-transform: uppercase;
	font-weight: 700;
	color: getColor('primary');

	.footer-subscribe__form & {
		text-transform: none;

		&:after {
			content: '';
			height: 1px;
			width: calc(100% + 2rem);
			position: absolute;
			bottom: -$space-xs;
			left: 0;
			background-color: getColor('primary');
		}

	}
}

.footer-subscribe__form {
	position: relative;
	display: flex;
	width: 100%;

	&.footer-subscribe__form--btn-mod {
		cursor: pointer;
	}

	.icon--size-mod {
		width: 2.4rem;
		height: 2.4rem;
		margin-right: $space-m;


	}

	.form-field {
		width: 100%;
		padding: 0;
	}


}

.footer-subscribe__submit {
	position: absolute;
	top: 1.3rem;
	right: .4rem;
	width: 2rem;
	height: 2rem;
	padding: .6rem;

	color: getColor('primary');
	transition: color .3s;
	cursor: pointer;
	transform: rotateZ(180deg);

	.footer-country & {
		position: relative;
		top: 0.2rem;
		right: -0.8rem;
	}

	i {
		font-weight: 700;
	}

	&:hover,
	&:focus {
		color: getColor('secondary');
	}

	.footer-subscribe__form--btn-mod:focus & {
		color: getColor('red');
	}
}

// social list

.social__list {
	@extend %global_flex_block_row_nowrap_center_center;
}

.social__link {
	@extend %global_flex_block_row_nowrap_center_center;

	transition: color .3s;

	&:hover,
	&:focus {
		color: getColor('secondary');
	}

	svg.icon.icon__footer-social-mod {
		max-width: 4.4rem;
		height: 35px;
	}
}

.footer-menu__link,
.footer-menu__title {
	@extend %typo-text-2;
	text-align: left;
	text-decoration: none;
	font-weight: 700;
	color: getColor('primary');
	padding: $space-m 3rem $space-m 0;
}


// TABLET + MOBILE
@include responsiveTo('lg') {


	.footer-menu {
		position: relative;
		margin: 0 0 2.9rem;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: -2rem;
			right: -2rem;
			bottom: 0;
			background-color: getColour('green');
			z-index: -1;
		}
	}

	.footer-menu__col {
		position: relative;
		width: 100%;



		&.footer-menu__col--active-mod {
			&::after {
				opacity: 0;
			}
		}
	}

	.footer-menu__title {
		position: relative;

		width: 100%;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 2rem;
			height: 1rem;
			margin: -.5rem 0 0;

			background: black url('../images/icons/arrow.svg') no-repeat center center;
			background-size: contain;

			display: none;
		}
	}

	.footer-menu__title__icon {
		position: absolute;
		top: 50%;
		right: 0;
		width: 1.4rem;
		height: .7rem;
		margin: -.5rem 0 0;

		color: getColor('primary');

		transition: transform .3s ease;

		.footer-menu__col.footer-menu__col--active-mod & {
			transform: rotate(180deg);
		}
	}

	.footer-menu__list-w {
		margin: 0;

		@include style_for_accordion_body();

		background-color: getColor('gray');

		.footer-menu__col.footer-menu__col--active-mod & {
			@include style_for_accordion_body_active_state;
		}
	}

	.footer-menu__list {

		width: 100%;
		margin: 0;

		list-style: none;
		visibility: hidden;

		.footer-menu__col.footer-menu__col--active-mod & {
			visibility: visible;
		}
	}

	.footer-menu__link {
		font-weight: 400;
		color: getColor('black');
		text-decoration: none;

		&:hover,
		&:focus {
			color: getColor('black');
			text-decoration: underline;
		}
	}


	.footer-social {
		&.footer-social--desktop-mod {
			display: none;
		}
	}
}

// TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-footer {
		border-width: .3rem;
		padding-top: 3.6rem;

		&:before,
		&:after {
			height: .5rem;
		}

		&:after {
			top: .5rem;
		}
	}

	.c-footer__logo {
		margin: 0 auto $space-m;
	}

	.footer-subscribe__wrap {
		@include grid-tablet;
		align-content: center;
	}

	.footer-subscribe__block {
		grid-column: 1 / 5;

		&:nth-child(2) {
			grid-column: 6 / 9;
			display: flex;
			align-items: flex-end;
		}
	}

	.footer-menu__title {}

	.footer-menu__list {
		padding: 1.1rem $gl_indent;
	}

	.footer-menu__item {
		margin: 0 0 1.2rem;
	}

	.footer-menu__link {
		font-size: 1.4rem;
	}

	.footer-subscribe__title {}

	.social__item {
		margin: 0 -.6rem 0 0; //custom

		&:last-child {
			margin: 0;
		}
	}

	.footer__top {
		margin: 0 0 $space-3xl;
	}

	.footer__copyright {
		padding: $space-m 0;
	}

	.footer-social {
		margin: 0 0 3.7rem;
	}
}

// MOBILE
@include responsiveTo('md') {
	.c-footer {
		border-width: .1rem;
		padding-top: $space-l;

		&:before,
		&:after {
			height: .5rem;
		}

		&:after {
			top: .5rem;
		}
	}

	.c-footer__logo {
		margin: 0 auto $space-m;
	}

	.footer__copyright {
		padding: $space-m 0;
	}

	.footer-subscribe__block {
		margin: 0 0 $space-xxl;

		&:last-child {
			margin: 0;
		}
	}

	.footer-social {
		margin: 0 0 2.7rem;
	}

	.social__item {
		margin: 0 -0.6rem 0 0;

		&:last-child {
			margin: 0;
		}
	}

	.footer-menu {
		margin: 0 0 2.6rem;
	}

	.footer__top {
		margin: 0 0 $space-xxl;
	}

	.footer-menu__list {
		padding: 0;
	}


	.footer-menu__item {
		padding: $space-m 0;
	}

	.footer-menu__link {}

	.footer-subscribe__title {}
}

// DESKTOP
@include responsiveFrom('lg') {
	.c-footer {
		padding: $space-l 12rem 0;
	}

	.c-footer__logo {
		margin: 0 auto $space-l;
	}

	.footer__top {
		@include flex_block(row, nowrap, flex-start);
		max-width: 167.1rem;
		width: 100%;
		margin: 0 auto 9.2rem;
		padding-top: $space-m;
	}

	.footer-menu {
		@include flex_block(row, nowrap, space-between, flex-start);
		width: 73%;
	}

	.footer-menu__col {
		padding: $space-s;
		width: 20%;
	}

	.footer-menu__title {
		font-weight: 700;
		padding: 0;
		margin-bottom: $space-s;
	}

	.footer-menu__title__icon {
		display: none;
	}

	.footer-menu__link {
		font-weight: 400;
		padding: 0;

		&:hover,
		&:focus {
			color: getColor('primary');
			text-decoration: underline;
		}
	}

	.footer-subscribe__title {}

	.footer-subscribe__block {
		margin: 0 0 5rem auto;
		width: 78%;

		&:last-child {
			margin: 0 0 0 auto;
		}
	}

	.footer-social {
		padding: $space-m 0;
		margin: 0;
	}

	.footer-subscribe__wrap {
		margin: 0 0 0 auto;
		width: 24.1%;
	}

	.social__item {

		margin-right: -0.6rem;

		.social__link {
			svg {
				max-width: 4rem;
			}
		}

		&:last-child {
			margin: 0;
		}
	}
}