.banner__fullimage__with__text__overlay {
  .banner__col__bg {
    display: block;
    position: static;
    width: 100%;
    height: 100%;
    height: auto;

    @include responsiveFrom('md'){
      height: 100%;
      width: 100%;
      flex-basis: 50%;
    }

    img {
      height: auto;
      width: 100%;
      display: block;
    }
   
  }
  & .banner--reverse-mod.full-overlay{
    display: flex;
    flex-direction: row-reverse;
    .banner__col{
      @media(min-width: 1600px){
        padding: 11.9rem 9% 0 9%!important;
      }
      @media (max-width: 1500px){
        padding: 3.1rem 9% 0 9%;
      }
    }
  }


  

  .section__in {
    .banner {
      min-height: unset;
      display: flex;

      @include responsiveFromTo('lg','md'){ //desktop
        align-items: flex-start;
      }

      div.banner__col.banner__col--content-mod {

        @include responsiveFrom('md') {
          min-height: unset;
          display: flex;
          align-items: flex-end;
          height: 100%;
          width: 100%;
          right: 0;
          //min-width: calc(360px + 10%);
          flex-basis: 50%;
          padding: $space-l $space-xl; //tablet
        }

        @include responsiveFrom('lg') { //desktop
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          column-gap: $space-s;
        }
        @media (max-width: 1500px) { //custom
        }
        @include responsiveTo('lg') {
        }
        @include responsiveTo('md') { //mobile
          padding: 0;
        }

      }

      .banner__col__content {
        text-align: left;
        padding: $space-l $space-s;
        min-height: unset;
        @include responsiveTo('md') {
          margin-bottom: $space-4xl;
        }
        @include responsiveFrom('md') {
          padding: 0;
        }
        @include responsiveFrom('lg') {
          grid-column: 2 / 6;
        }
      }

      .banner__col__content__title {
        @extend %typo-h4;
        font-weight: 400;
        padding: $space-xxs 0;
        margin-bottom: $space-xxs;

        @include responsiveFrom('md') {
        }
        @include responsiveFrom('lg') {
        }

      }

      .banner__col__content__subtitle {
        padding: $space-xxs 0;
        margin-bottom: $space-xxs;
        @extend %typo-h6;
        font-weight: 400;
        color: getColor('primary');

        @include responsiveFrom('lg') { //desktop
        }
      }

      .banner__col__content__descr {
        font-weight: 400;
        @extend %typo-text-1;
        color: getColor('primary');
        padding: $space-l 0 $space-xl;
        margin: $space-xxs;

        @include responsiveFrom('lg') { //desktop
  
        }
        @include responsiveTo('lg') { //tablet

        }
      }

      .button.button--primary {
        @include responsiveFrom('md') {
          min-width: unset;
          width: 68%;
        }
      }

      &.banner--reverse-mod {
        div.banner__col.banner__col--content-mod {
          @include responsiveFrom('lg') { //desktop
          }
          @media (max-width: 1500px) { //custom
          }
          @include responsiveTo('lg') {
          }
          @include responsiveFrom('md') {
            left: 0;
            text-align: left;
          
          }
          @include responsiveTo('md') {
          }
        }

        .banner__col__content {
          @include responsiveFrom('md') {
            align-items: flex-start;
          }
        }
      }
    }
  }
}
