[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e900";
}
.icon-added:before {
  content: "\e901";
}
.icon-arrow-2:before {
  content: "\e902";
}
.icon-arrow:before {
  content: "\e903";
}
.icon-arrow3:before {
  content: "\e904";
}
.icon-audio-off:before {
  content: "\e905";
}
.icon-audio-on:before {
  content: "\e906";
}
.icon-bell:before {
  content: "\e907";
}
.icon-calendar:before {
  content: "\e908";
}
.icon-close_circle:before {
  content: "\e909";
}
.icon-CLOSE:before {
  content: "\e90a";
}
.icon-credit-card:before {
  content: "\e90b";
}
.icon-cvv:before {
  content: "\e90c";
}
.icon-doubt:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-dustbin:before {
  content: "\e90f";
}
.icon-eye-close:before {
  content: "\e910";
}
.icon-eye:before {
  content: "\e911";
}
.icon-facebook:before {
  content: "\e912";
}
.icon-filter:before {
  content: "\e913";
}
.icon-info:before {
  content: "\e914";
}
.icon-instagram:before {
  content: "\e915";
}
.icon-Left_quotes_sign:before {
  content: "\e916";
}
.icon-mail:before {
  content: "\e917";
}
.icon-package:before {
  content: "\e918";
}
.icon-pause:before {
  content: "\e919";
}
.icon-phone:before {
  content: "\e91a";
}
.icon-play:before {
  content: "\e91b";
}
.icon-position:before {
  content: "\e91c";
}
.icon-prchase:before {
  content: "\e91d";
}
.icon-search:before {
  content: "\e91e";
}
.icon-shopping-bag:before {
  content: "\e91f";
}
.icon-tiktok:before {
  content: "\e920";
}
.icon-user-full:before {
  content: "\e921";
}
.icon-user:before {
  content: "\e922";
}
.icon-waranty:before {
  content: "\e923";
}
.icon-whatsapp:before {
  content: "\e924";
}
.icon-youtube:before {
  content: "\e925";
}
