//---------------------------------------------c-product-filter-nav
.c-product-filter-nav__bg {
	position: absolute;
	top: 0;
	left: -2rem;
	right: -2rem;
	height: 100%;
	background-color: getColor('light');

}

.c-product-filter-nav {
	padding: 1px 0;

	body.open-menu-mod & {
		opacity: 0;
		pointer-events: none;
	}
}

.c-product-filter-nav__col {
	// z-index: 1;
}


.c-product-filter-nav__list {
	@extend %global_flex_block_row_wrap_flex-start_center;

}

.c-product-filter-nav__item {
	position: relative;
	text-align: center;

	@include responsiveFrom('md') {
		&::before {
			z-index: 2;
			position: absolute;
			left: -.5px;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 50%;
			height: .5rem;
			width: 5px;

			background-color: getColor('primary');

			content: '';
		}
	}
}

.c-product-filter-nav__btn {
	position: relative;
	@extend %typo-text-2;
	font-weight: 700;
	color: getColor('primary');
}

.c-product-filter-nav__item__btn-reset {
	letter-spacing: .03em;
}

.c-product-filter-nav__btn__icon,
.c-product-filter-nav__btn__filter__icon {
	width: auto;
	height: auto;
	color: getColor('primary');
	transition: transform .3s ease;
	color: getColor('primary');

	i.icon-filter {
		font-size: 1.6rem;
	}

	.icon-arrow.down {
		font-size: 1.2rem;
		width: 1.2rem;
		font-weight: 700;

		&::before {
			display: block;
			transform: rotate(270deg) translateX(-0.5rem);
		}
	}


}

.c-product-filter-nav__btn__filter__icon {
	margin-right: $space-m;
}

.c-product-filter-nav__btn__title {
	@extend %typo-text-2;
	color: getColor('primary');
	margin: 0 4px 0 0;

	.c-product-filter-nav__btn--filter-order-mod & {
		margin: 0 .5rem 0 0;
	}
}

.c-product-filter-nav__btn__dropdown {
	position: absolute;
	pointer-events: none;

	overflow: hidden;

	transition: opacity .3s ease;

	.c-product-filter-nav--dropdown-active & {
		pointer-events: initial;
	}

	.c-product-filter-nav__btn__dropdown__in{
		.form-field__label.form-field__label--radio-field{
			.form-field__text.form-field__text--radio{
				@extend %typo-text-2;
				color: getColor('primary');
				font-weight: 400;
				text-transform: unset;
			}
		}
	}
}

.c-product-filter-nav__btn__dropdown__in {
	@extend %popup_transition;
	padding: 2.1rem 2rem;
	text-align: left;
	background-color: getColor('light');
}

.c-product-filter-nav__btn__dropdown__in__content {
	display: none;

	.c-product-filter-nav.c-product-filter-nav--dropdown-active & {
		display: block;
	}
}

.c-product-filter-nav__btn__dropdown_item {
	margin: 0 0 .9rem;

	&:last-child {
		margin: 0;
	}
}


.c-product-filter-nav__in {
	@include flex_block(row, wrap, flex-start, center, center);

	position: relative;

	&.c-product-filter-nav__in--fixed-state {
		z-index: 2;
	}

	&::after {
		position: absolute;
		z-index: -2;
		top: 100%;

		width: 100vw;
		height: 100vh;

		background-color: getColor('primary');
		opacity: 0;
		pointer-events: none;

		transition: opacity .3s ease;
		content: '';
	}

	.c-product-filter-nav--dropdown-active & {
		&::after {
			opacity: .4;
			background-color: #000;
		}
	}
}


.c-product-filter-nav__col__result_count {
	@extend %typo-text-2;
	color: getColor('primary');
	font-weight: 700;
}

//---------------------------------------------c-product-filter-nav###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('xxl') {
	.c-product-filter-nav__in {

		&.c-product-filter-nav__in--fixed-state {

			body.page-scroll-state & {
				//top: calc(var(--header-height));
			}

			body.disable_top_bar_state & {
				top: calc(var(--header-height) - var(--c-header-top-height));
			}

			body.disable_top_bar_state.page-scroll-state & {
				top: calc(var(--header-height) - var(--c-header-top-height));
			}
		}
	}

}

@include responsiveTo('md') {
	.c-product-filter-nav {
		min-height: 5.4rem;

		margin-left: -1rem;
		width: calc(100vw + 1rem);

		&.c-product-filter-nav--offset-mod {
			margin-bottom: 2.7rem
		}
	}

	.c-product-filter-nav__in {
		min-height: 5.2rem;

		&::after {
			z-index: 9;
			top: 100%;
			left: 0;
		}

		&.c-product-filter-nav__in--fixed-state {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 10;
		}
	}

	.c-product-filter-nav__list {
		position: relative;

		align-items: stretch;

		& li {
			border-left: none;
		}

		:last-child {
			border-right: none;
		}

	}

	.c-product-filter-nav__btn__title {
		font-weight: 700;

		.c-product-filter-nav__btn--filter-order-mod & {
			margin-right: $space-m;
		}

		&.c-product-filter-nav__btn__title--desktop-mod {
			display: none;
		}
	}

	.c-product-filter-nav__col {
		width: 100%;
		bottom: 0;
		position: fixed;
		z-index: 10;
	}

	.c-product-filter-nav__col__result_count {
		display: none;
	}

	.c-product-filter-nav__col--breadcrumbs-mod {
		display: none;
	}

	.c-product-filter-nav__item__btn-reset {
		display: none;
	}

	.c-product-filter-nav__item {
		width: 50%;
		border: 1px solid getColor('primary');
		background-color: getColor('light');

		&:first-child::before {
			content: none;
		}

		.c-product-filter-nav__btn__dropdown__in {
			transform: none;

			.c-product-filter-nav--dropdown-active & {
				transform: none;
			}
		}

	}

	.c-product-filter-nav__btn {
		@include flex_block(row, nowrap, center, center, center);
		min-height: 5rem;
		background-color: getColor('light');
		min-height: unset;
		padding: $space-l $space-s;
		width: 100%;
	}

	.c-product-filter-nav__btn--filter-control-mod {}

	.c-product-filter-nav__btn--filter-order-mod {
		padding-left: 2.8rem;
		padding-right: 2.8rem;

		text-align: left;
	}

	.c-product-filter-nav__btn__order {
		display: none;
	}

	.c-product-filter-nav__btn__icon {
		position: static;
		color: getColor('primary');
		flex-shrink: 0;

		.c-product-filter-nav__btn--filter-control-mod & {
			display: none;
		}
	}

	.c-product-filter-nav__btn__dropdown {
		z-index: -1;
		width: 100%;
		top: 0;
		left: 0;
		transform: translateY(0%);
		transition: transform 0.5s ease-in;
	}
	
	.c-product-filter-nav--dropdown-active .c-product-filter-nav__btn__dropdown{
		transform: translateY(-100.5%);
	}

	.c-product-filter-nav__btn__dropdown_item {
		margin: 0 0 2rem;
	}

}

//---------------------------------------------MOBILE ONLY###

//tablet only
@include responsiveFromTo('lg', 'md') {
	.c-product-filter-nav {
		min-height: 5.6rem;

		&.c-product-filter-nav--offset-mod {
			margin-bottom: 2.7rem
		}
	}

	.c-product-filter-nav__item__btn-reset {
		font-size: 1.4rem;
	}

	.c-product-filter-nav__btn {
		min-height: 5.4rem;
	}

	.c-product-filter-nav__in {
		min-height: 5.2rem;
	}
}

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {

	.c-product-filter-nav__col__result_count {
		margin-right: $space-l;
	}

	.c-product-filter-nav__btn__icon {
		top: 50%;
		width: 1rem;
		height: .6rem;
		margin-top: -.3rem;
	}

	.c-product-filter-nav__bg {
		z-index: -1;
	}

	.c-product-filter-nav__btn__title--mobile-mod {
		display: none;
	}

	.c-product-filter-nav__btn.c-product-filter-nav__btn--filter-order-mod {
		display: flex;
		align-items: center;
	}


	.c-product-filter-nav {

		position: relative;
		z-index: 10;
	}

	.c-product-filter-nav__in {
		&::after {
			top: calc(100% - .2rem);
			left: -$gl_indent;
		}

		&.c-product-filter-nav__in--fixed-state {
			z-index: 2;

			position: fixed;
			top: var(--header-height);
			left: $gl_indent;
			right: $gl_indent;
		}
	}

	.c-product-filter-nav__col--options-mod {
		margin-left: auto;

		@include flex_block(row, wrap, flex-start, center, center);
	}

	.c-product-filter-nav__item__btn-reset {
		cursor: pointer;

		&.c-product-filter-nav__item__btn-reset--offset-mod {
			margin-left: 1.4rem;
		}

		&:hover,
		&:focus {
			color: getColor('red');
		}
	}

	.c-product-filter-nav__item {
		position: relative;
		padding-left: $space-l;
		display: flex;

		&:nth-child(1) {
			padding-right: $space-l;
		}

	}

	.c-product-filter-nav__btn {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		transition: color .3s ease;
		font-weight: 700;

		&.c-product-filter-nav__btn--filter-order-mod {
			font-weight: 400;

			.c-product-filter-nav__btn__order {
				font-weight: 700;
			}
		}

		&#custom-filter::after {
			content: '';
			left: 0;
			background-color: getColor('primary');
			width: 100%;
			height: 1px;
			position: absolute;
			display: block;
			margin-top: 2.2rem;
		}

		.c-product-filter-nav__btn__title {
			margin-right: $space-s;
		}
	}

	.c-product-filter-order-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;

		&::after {
			content: '';
			background-color: getColor('primary');
			width: calc(100%);
			height: 1px;
			display: block;
			position: absolute;
			margin-top: 2.2rem;
		}

		.c-product-filter-nav__btn__order {
			margin-right: $space-s;
		}
	}

	.c-product-filter-nav__btn__dropdown {
		top: 100%;
		width: calc(100% + .4rem);
		right: 0;

		.c-product-filter-nav__btn__dropdown__in {
			transform: translateY(-100%);

			.c-product-filter-nav--dropdown-active & {
				transform: translateY(0%);
			}
		}

	}

	.c-product-filter-nav__btn__icon {
		right: 0;
		color: getColor('primary');

		i.icon.icon-arrow.down {
			font-size: 1rem;
			width: 1rem;

			&::before {
				transform: rotate(270deg) translateX(-3px);
			}
		}
	}

	.c-product-filter-nav__btn__filter__icon {
		display: none;
	}


}

//---------------------------------------------TABLET + DESKTOP

@include responsiveFrom('lg') {
	.c-product-filter-nav {
		min-height: 6.4rem;

		&.c-product-filter-nav--offset-mod {
			margin-bottom: .8rem;
			margin-top: 1rem;
		}
	}

	.c-product-filter-nav__btn.c-product-filter-nav__btn--filter-order-mod {}

	.c-product-filter-nav__item__btn-reset {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.28px;
	}

	.c-product-filter-nav__item {
		&:has(.c-product-filter-nav__item__btn-reset){
			display: flex;
		}
	}

	.c-product-filter-nav__btn__title {
		&#filterLabelText {}
	}


	.c-product-filter-nav__btn__order {
		&::after {
			content: '';
		}
	}

	.c-product-filter-nav__btn {
		min-height: 5.8rem;
	}

	.c-product-filter-nav__in {
		min-height: 5.6rem;
	}
}

@include responsiveFrom('xxl') {
	.c-product-filter-nav__in {

		&.c-product-filter-nav__in--fixed-state {

			body.page-scroll-state & {
				top: calc(var(--header-height) - 2rem);
			}

			body.disable_top_bar_state & {
				top: calc(var(--header-height) - var(--c-header-top-height));
			}

			body.disable_top_bar_state.page-scroll-state & {
				top: calc(var(--header-height) - var(--c-header-top-height) - 2rem);
			}
		}
	}
}