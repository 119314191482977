// ----------------------------------------------- fade ----------------------------------------------------------------
@mixin slideSettings($name, $duration, $delay:0s) {
    -webkit-animation: $name $duration cubic-bezier(0.215, 0.610, 0.355, 1.000) $delay both;
    animation: $name $duration cubic-bezier(0.215, 0.610, 0.355, 1.000) $delay both;
}

@keyframes slide-up-fade-in {
    0% {
        transform: translateY(3.5vh);
        opacity: 0;
    }


    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animation-fade-up {
    @include slideSettings(slide-up-fade-in, 2s);

    p {
        @include slideSettings(slide-up-fade-in, 1.8s, 1s);
    }

    .button {
        @include slideSettings(slide-up-fade-in, 1.8s, 0.8s);

    }

}

@keyframes slide-right-fade-in {
    0% {
        transform: translate3d(6vw, 1vh, 0) scale(1.02);
        opacity: 0;
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: translate3x(0, 0, 0);
        opacity: 1;
    }
}

.animation-fade-right {
    @include slideSettings(slide-right-fade-in, 2s);

    p {
        @include slideSettings(slide-right-fade-in, 2s, 0.8s);
    }

    //---------------------------------------------MOBILE ONLY
    @include responsiveTo('md') {

        @include slideSettings(slide-up-fade-in, 2s);

        p {
            @include slideSettings(slide-up-fade-in, 2s, 0.8s);
        }
    }

    //---------------------------------------------MOBILE ONLY###
}



@keyframes slide-left-fade-in {
    0% {
        transform: translate3d(-6vw, 1vh, 0) scale(1.02);
        opacity: 0;
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: translate3x(0, 0, 0);
        opacity: 1;
    }
}

.animation-fade-left {
    @include slideSettings(slide-left-fade-in, 2s);

    p {
        @include slideSettings(slide-left-fade-in, 1.8s, 0.8s);
    }

    //---------------------------------------------MOBILE ONLY
    @include responsiveTo('md') {

        @include slideSettings(slide-up-fade-in, 2s);

        p {
            @include slideSettings(slide-up-fade-in, 1.8s, 0.8s);
        }
    }

    //---------------------------------------------MOBILE ONLY###
}



