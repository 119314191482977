@use "sass:math";

@import "./c-header-menu";

:root {
  --full-screen: calc(var(--vh, 1vh) * 100);

  .page-vespa-myAccount {
    .c-header {
      border-bottom: 1px solid #707372;
    }
  }

  @include responsiveFrom("lg") {
    --header-height: 10rem;
    --header-v2-height: 11rem;
    --c-header-top-height: 2.6rem;
    --product-info-top-offset: 3.6rem;
    // --header-indent: 5.2rem
  }

  // TABLET
  @include responsiveFromTo("lg", "md") {
    --header-height: 12rem;
    --header-v2-height: 11rem;
    --product-info-top-offset: 2.9rem;
    // --header-indent: 2rem
  }

  // Tablet + mobile
  @include responsiveTo("lg") {
    --c-header-top-height: 2rem;
  }

  // MOBILE
  @include responsiveTo("md") {
    --header-height: 9rem;
    --header-v2-height: 6.2rem;
    --product-info-top-offset: 2.6rem;
    // --header-indent: 1rem
  }
}

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  font-size: 1rem;

  will-change: transform;

  z-index: $z-i-header;

  &.c-header--v2-mod {
    height: var(--header-v2-height);
  }

  body:not(.disable_top_bar_state):not(.header-v2-mod) {
    height: var(--header-height);
  }

  body.disable_top_bar_state & {
    height: calc(var(--header-height) - var(--c-header-top-height));
  }

  .c-header__nav-in__link__icon {
    display: none;
  }
}

.c-header__accessebility-link {
  z-index: 100;
  position: absolute;
  top: 0.5rem;
  left: $gl_indent;

  padding: 0 0.4rem;

  color: getColor("black");
  background-color: getColor("light");
  font-size: 1.4rem;

  opacity: 0;
  pointer-events: none;

  &:focus {
    opacity: 1;
  }
}

.c-header__top {
  @extend %transition_transform;

  z-index: 3;

  position: relative;
  width: 100%;

  background-color: getColor("primary");

  body.disable_top_bar_state & {
    display: none;
  }
}

.c-header__top-in {
  @extend %global_flex_block_row_wrap_center_center;

  height: 100%;
}

.c-header__top-text {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: white;
}

.c-header__bottom {
  z-index: 2;

  position: relative;

  width: 100%;

  .c-header.c-header--v2-mod & {}

  &:last-child {
    margin-bottom: 0;
  }
}

.c-header__bottom-in {
  @extend %global_flex_block_row_nowrap_space_between_center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;

  padding: $space-m;

  background-color: getColor("light");

  .c-header.c-header--v2-mod & {
    height: var(--header-v2-height);
    background-color: getColor("light");
  }
}

.c-header__bottom-in__call-link__icon-w {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.8rem;
}

.c-header__bottom-in__logo {
  @extend %transition_transform;

  position: relative;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  width: 12.8rem;
  height: 4.4rem;

  display: block;
  flex-shrink: 0;

  @include responsiveTo('md') {
    width: 8.8rem;
    height: 3rem;
  }

  .c-header.c-header--v2-mod & {
    position: absolute;
    left: 50%;
    // transform: translate(-50%)
  }
}

.c-header__bottom-in__logo-img {
  @extend %transition_opacity;
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;

  &--hidden-mod {
    opacity: 0;
  }
}

.c-header__bottom-in__controller {
  z-index: 2;
  margin-left: auto;
  font-size: 2rem;
}

.c-header__bottom-in__controller-list {
  @extend %global_flex_block_row_nowrap_flex-start_center;

  padding: 0;

  list-style: none;
}

.c-header__bottom-in__controller-list__link {
  @extend %transition_color;

  display: block;

  color: getColor("primary");
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &[data-counter] {
    position: relative;

    &::after {
      @extend %global_flex_block_row_wrap_center_center;

      z-index: 2;
      position: absolute;
      left: -0.6rem;
      bottom: -1rem;

      display: inline-flex;

      color: getColor("light");
      font-weight: 700;

      background-color: getColor("primary");
      border-radius: 50%;
      content: attr(data-counter);
    }
  }

  &.c-header__bottom-in__controller-list__link--pos-mod {
    position: relative;
  }
}

.c-header__bottom-in__controller-list__link-tooltip {
  z-index: 3;
  position: absolute;
  right: calc(100% + 0.9rem);
  top: 50%;
  transform: translateY(-50%);

  display: block;
  padding: 0.7rem 0.7rem 0.5rem;

  opacity: 0;

  font-size: 1.2rem;
  font-family: $base-font-family;
  white-space: nowrap;
  color: getColor("primary");

  border-radius: .4rem;
  border: 1px solid #ABC7C9;
  background-color: #F6F9F9;

  transition: opacity 0.3s ease;

  &::after {
    z-index: -1;
    position: absolute;
    left: 98%;
    top: 42%;
    transform: rotate(45deg);

    height: .5rem;
    width: .5rem;

    border: solid #ABC7C9;
    border-width: .1rem 0.1rem;
    border-left: transparent;
    border-bottom: transparent;
    background-color: #F6F9F9;

    content: "";
  }

  .c-header__bottom-in__controller-list__link:hover &,
  .c-header__bottom-in__controller-list__link:focus & {
    opacity: 1;
  }
}

.c-header__bottom-in__btn-back {
  @extend %global_flex_block_row_wrap_flex-start_center;

  color: getColor("primary");
  text-decoration: none;

  transition: transform 0.3s ease, color 0.3s;
  will-change: transform;

  &:hover,
  &:focus {
    color: getColor("green");
  }
}


.c-header__nav-in__link {
  text-decoration: none;
  @extend %transition_color;

  position: relative;

  display: block;
  width: 100%;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  // &:focus {
  // 	outline: 2px solid #000;
  // }

  &[aria-expanded="true"] {
    &::before {
      opacity: 1;
    }
  }
}

.c-header__overlay {
  @extend %transition_opacity;

  z-index: -1;

  position: fixed;
  top: 2rem;
  left: 0;

  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s ease;

  body.open-menu-mod & {
    z-index: 1;

    opacity: 1;
    pointer-events: auto;
  }
}

.c-header__menu-trigger {
  cursor: pointer;
}

.c-header__menu-trigger__text {
  display: none;
}

.c-header-burger {
  display: none;

  @media (max-width: 1600px) {
    img {
      height: 2rem;
    }

    display: block
  }
}

%user_menu_butt_line {
  @extend %user_menu_butt_line_height;

  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -0.1rem;
  background: getColor("light");
  border-radius: 0.4rem;
}

.c-header__menu-trigger__decor {
  z-index: 210;
  position: relative;

  height: 2rem;

  &:before {
    content: "";
    transform: translate(0, -0.8rem);
    transition: all 0.3s ease;

    @extend %user_menu_butt_line;
  }

  &:after {
    content: "";
    transform: translate(0, 0.8rem);
    transition: all 0.3s ease;

    @extend %user_menu_butt_line;
  }
}

.c-header__menu-trigger__decor__in {
  @extend %user_menu_butt_line;

  transition: all 0.3s ease;

  body.open-menu-mod & {
    opacity: 0;
    transform: translate(100%, 0);
  }
}

#miniCartButtonMobile,
#miniCartPopupButton {
  svg {
    stroke: #ffffff;
    stroke-width: 0.5px;
  }
}

.c-header__user-nav__button {
  color: getColor('primary');
}

//---------------------------------------------MOBILE ONLY

@include responsiveTo("md") {
  .c-header {
    body.c-header--index-state-mod & {
      z-index: 0;
    }
  }

  .c-header__bottom-in {
    height: 7rem;
  }

  .c-header__bottom-in__controller {
    height: 100%;
  }

  .c-header__bottom-in__controller-list {
    height: 100%;
    align-items: center;
  }

  .c-header__bottom-in__controller-list__item {

    margin-right: $space-xl;
    height: 2rem;
    width: auto;

    &.c-header__bottom-in__controller-list__item--hidden-mob-mod {
      display: none;
    }

    &:has(#need-help-button) {
      display: none;
    }
  }

  .с-header__bottom-in__logo {
    width: 8.3rem;
    height: 2.9rem;

    .c-header.c-header--v2-mod & {
      width: 10.4rem;
      height: 3.6rem;
      margin-left: -5.2rem;
    }
  }

  .c-header__bottom-in__controller-list__link {


    &.c-header__bottom-in__controller-list__link--hide-mob-mod {
      display: none;
    }

    &[data-counter] {
      &::after {
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.2rem;

        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            padding: 1px 0 0 1px;
          }
        }
      }
    }

    &#miniCartButtonMobile {
      width: 1.8rem;
      height: auto;

      i.icon-shopping-bag {
        display: block;
        height: 100%;
        width: 100%;
        margin-left: -8px;

        &::before {
          display: block;
          height: 100%;
          width: 100%;
        }

      }
    }

  }

  .c-header__nav {
    width: 100%;

    body:not(.disable_top_bar_state) & {
      top: var(--header-height);
      height: calc(var(--full-screen) - var(--header-height));
    }

    body.disable_top_bar_state & {
      top: calc(var(--header-height) - var(--c-header-top-height));

      height: calc(var(--full-screen) - (calc(var(--header-height) - var(--c-header-top-height))));
    }
  }

  .c-header__user-nav__button {
    height: $header_user_nav_mobile_menu_height;
    padding: 2rem;

  }

  .c-header__nav-in {
    height: calc(100% - #{$header_user_nav_mobile_menu_height});
  }

  .c-header__nav-in__link {
    padding: 2rem 5.8rem 2rem 2rem;

    &::after {
      left: 1rem;
      width: calc(100% - 2rem);
    }
  }

  .c-header__nav-in__link__icon {
    right: 1.2rem;
  }

  .c-header__menu-trigger__text {
    margin-right: 1.3rem;

    // display: none;
    body.open-menu-mod & {
      opacity: 0;
    }
  }

  // .c-header__bottom-in__controller {
  // 	margin-right: 1rem;
  // }

  .c-header__bottom-in__btn-back__icon {}

  .c-header__bottom-in__btn-back__title {
    display: none;
  }

  .c-header__top-text {
    font-size: 1.2rem;
  }

  .c-header__menu-trigger__decor {
    width: 3.3rem;

    body.open-menu-mod & {
      &:before {
        transform: (rotate(45deg) translate(0, 0) scale(0.8));
      }

      &:after {
        transform: (rotate(-45deg) translate(0, 0) scale(0.8));
      }
    }
  }

  %user_menu_butt_line_height {
    height: 0.3rem;
  }

  .c-header__customer-info__title {
    letter-spacing: 0.05em;
  }

  li.c-header__bottom-in__controller-list__item:has(#loginHeader) {
    display: none;
  }
}

//---------------------------------------------MOBILE ONLY###

@include responsiveTo("lg") {
  .c-header__top {
    height: 2rem;
  }

  .c-header__bottom-in__call-link {
    display: none;
  }

  .c-header__bottom-in__controller-list__item {
    &.c-header__bottom-in__controller-list__item--hidden-tab-mod {
      display: none;
    }
  }
}

@include responsiveTo("xxl") {
  .c-header__nav {
    z-index: -1;
    position: fixed;
    left: 0;

    border-top: 1px solid getColor('gray-dark');
    background-color: getColor("light");

    pointer-events: none;
    transform: translateX(-100%);
    transition: transform 0.3s ease;

    body.open-menu-mod & {
      z-index: 10;

      // opacity: 1;
      transform: translateX(0%);
      pointer-events: auto;
    }
  }

  .c-header__nav-in {
    height: calc(100% - 6.6rem);
  }

  .c-header__nav-in__link {
    @include flex_block(row, wrap, flex-start, center, center);

    height: 6.4rem;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;
    transition: background-color 0.3s ease;
    color: getColor("primary");

    &:hover,
    &:active {
      background-color: darken(getColor("light"), 5%);
    }
  }

  .c-header__nav-in__link__icon {
    position: absolute;
    top: 50%;

    width: 0.5rem;
    height: 1rem;
    transform: translateY(-50%);

    transition: color 0.3s ease;

    color: getColor("primary");

    .c-header__nav-in__link:hover &,
    .c-header__nav-in__link:focus & {
      color: getColor("green");
    }
  }

  .c-header__user-nav {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
  }

  .c-header__user-nav__list {
    flex: 1 1 auto;

    @include flex_block(row, flex-start, start, center);
    flex-direction: column;
  }

  .c-header__user-nav__list__item {
    width: 50%;

    &:first-child {
      border-right: 1px solid getColor("light");
    }
  }

  .c-header__user-nav__button {
    @include flex_block(column, nowrap, center, start, center);

    width: 100%;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;

    &:hover,
    &:active {
      background-color: lighten(getColor("light"), 10%);
    }
  }

  .c-header__user-nav__button__icon {
    margin-bottom: 1rem;

    &.c-header__user-nav__button__icon--mail-mod {
      width: 2.8rem;
      height: 2.1rem;
    }

    &.c-header__user-nav__button__icon--user-mod {
      width: 2.3rem;
      height: 2.5rem;
    }
  }

  .c-header__nav-in {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    overflow-y: auto;
  }

  .c-header__customer-info {
    flex: 1 1 auto;
    padding-left: 2rem;

    @include responsiveTo("md") {
      padding-left: 2rem;
    }

    height: 6.4rem;
    background-color: #f6f9f9;

    @include flex_block(column, wrap, center, center, start);
  }

  .c-header__customer-info__in {
    margin: 0 2.4rem 0.8rem 0;

    text-decoration: none;

    @include flex_block(row, wrap, center, center, center);
  }

  .c-header__customer-info__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;

    & :last-child {
      color: getColor('gray-dark');
    }
  }

  .c-header__customer-info__subtitle {
    display: block;

    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.5px;

    color: getColor("gray-dark");
    text-align: center;

    &.c-header__customer-info__subtitle--phone-mod {
      font-size: 1.4rem;
      text-align: left;
      letter-spacing: -0.02em;
    }
  }

  .c-header__customer-info__icon {
    width: 3.4rem;
    height: 3.6rem;

    margin: -0.6rem 1.2rem 0 0;
  }

  .c-header__menu-trigger {
    @include flex_block(row, wrap, flex-start, center, center);
  }

  .c-header__bottom-in {
    transform: none !important; // remove inline styles
  }
}

//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo("lg", "md") {
  .c-header__bottom-in {
    height: 10rem;
  }

  .с-header__bottom-in__logo {
    width: 13.9rem;
    height: 4.6rem;
  }

  .c-header__bottom-in__controller-list__item {
    padding: 0;
    margin-right: 3.5rem; //custom
  }
}

@include responsiveFromTo("xxl", "md") {
  .c-header__nav {
    width: 51.2rem;

    body:not(.disable_top_bar_state) & {
      top: calc(var(--header-height));
      height: calc(var(--full-screen) - (calc(var(--header-height))));
    }

    body.disable_top_bar_state & {
      top: calc(var(--header-height) - var(--c-header-top-height));

      height: calc(var(--full-screen) - (calc(var(--header-height) - var(--c-header-top-height))));
    }
  }

  .c-header__nav-in__link {
    padding: 1rem 2rem 1rem 2rem;

    /*&::after {
      left: 5rem;
      width: calc(100% - 10rem);
    }*/
  }

  .c-header__nav-in__link__icon {
    right: 5rem;
  }

  .c-header__user-nav__button {
    height: $header_user_nav_tablet_menu_height;
    padding: 0.8rem 0.8rem 0.4rem 2rem;
  }

  // .c-header__nav-in {
  // 	height: calc(100% - #{$header_user_nav_tablet_menu_height});
  // }

  // .c-header__bottom-in__btn-back__icon {
  // 	width: 1.4rem;
  // 	height: 2.6rem;
  // 	margin-right: 2rem;
  // }

  .c-header__bottom-in__btn-back__title {}

  .с-header__bottom-in__logo {
    .c-header.c-header--v2-mod & {
      width: 21.4rem;
      height: 5.8rem;
      margin-left: -10.7rem;
    }
  }

  .c-header__bottom-in__logo {
    width: 12.7rem;
    height: 4.2rem;
  }
}

.c-custom-my-account {
  @include responsiveTo("xl") {
    display: block;
  }

  @include responsiveFrom("xl") {
    display: none;
  }
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom("md") {
  .c-header__top-text {
    font-size: 12px;
  }

  .c-header__bottom-in {
    padding: $space-l $space-xxl;
  }

  .c-header__bottom-in__controller-list__link {
    &[data-counter] {
      &::after {
        width: 1.9rem;
        height: 1.9rem;
        font-size: 1.4rem;

        body.body--macos_state & {
          padding-top: 2px;
          line-height: 1.4;
        }

        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            line-height: 1.4;
          }
        }
      }
    }
  }

  .c-header__bottom-in__controller-list__link {
    width: 100%;
    height: 2.4rem;

    &.c-header__bottom-in__controller-list__link--show-mob-mod {
      display: none;
    }
  }

  .c-header__menu-trigger {
    margin-left: 0;
  }

  .c-header__menu-trigger__text {
    margin-right: 1.8rem;
  }

  .c-header__menu-trigger__decor {
    width: 4.1rem;

    body.open-menu-mod & {
      &:before {
        transform: (rotate(45deg) translate(0, 0));
      }

      &:after {
        transform: (rotate(-45deg) translate(0, 0));
      }
    }
  }

  %user_menu_butt_line_height {
    height: 0.4rem;
  }

  .c-header__bottom-in__btn-back__icon {
    margin-right: $space-xs;
  }

  .с-header__bottom-in__logo {
    .c-header.c-header--v2-mod & {
      width: 20.6rem;
      height: 7.2rem;
      margin-left: -10.3rem;
    }
  }

  .c-header__bottom-in__logo {
    width: 12.7rem;
    height: 4.2rem;
  }
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom("lg") {
  .c-header__top {
    height: 2.8rem;
  }

  .c-header__bottom-in {
    height: 7.2rem;
  }

  .c-header__bottom-in__logo {
    width: 12.7rem;
    height: 4.2rem;
  }

  // .с-header__bottom-in__logo {
  // 	transition: transform .3s ease;
  // 	will-change: transform;

  // 	.c-header.c-header--v2-mod & {
  // 		width: 22.8rem;
  // 		height: 6rem;

  // 		margin: math(-6rem, 2) 0 0 math(-22.8rem, 2);
  // 	}

  // }

  .c-header__bottom-in__call-link {
    @include flex_block(row, wrap, flex-start, center, center);

    font-weight: 400;
    font-size: 1.4rem;
    text-decoration: none;

    color: getColor("primary");

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: getColor("green");
    }
  }

  .c-header__bottom-in__controller-list__link {

    &:hover,
    &:active,
    &:focus {
      color: getColor("gren");
    }
  }

  .c-header__bottom-in__btn-back__title {}

  .c-header__bottom-in__controller-list {
    margin: 0 -3.7rem -3.7rem 0;
  }

  .c-header__bottom-in__controller-list__item {
    padding: 0 3.7rem 3.7rem 0;
    margin-right: 0;
  }

  .c-header__menu-trigger {
    margin-left: $space-xxl;
  }
}

// Extensions for typography
.headerMenuItem {
  @extend %typo-text-2;
}

//---------------------------------------------DESKTOP ONLY###

@include responsiveFrom("xxl") {
  .c-header {
    transition: transform 0.3s ease;
    will-change: transform;

    body.page-scroll-state & {
      transform: translateY(-2rem);
    }
  }

  .c-header__overlay {
    transition: opacity 0.3s ease, transform 0.3s ease;
    will-change: transform;

    body.page-scroll-state & {
      transform: translateY(2rem);
    }
  }

  .c-header__bottom-in__call-link {
    transition: transform 0.3s ease, color 0.3s ease;
    will-change: transform, color;

    body.page-scroll-state & {
      transform: translateY(2rem);
    }
  }

  .c-header__top {
    transition: transform 0.3s ease;
    will-change: transform;

    body.page-scroll-state & {
      transform: translateY(2rem);
    }
  }

  .c-header__bottom-in__logo {
    //width ul with icons - logo width
    margin-right: calc(21.8rem - 12.7rem);

    transform-origin: center;
    transition: transform 0.3s ease;
    will-change: transform;
    
    body.page-scroll-state & {
      transition: transform 0.3s ease;
      transform: scale(0.7) translateY(1.8rem);
    }
  }

  .c-header__bottom-in__btn-back {
    body.page-scroll-state & {
      transform: translateY(1rem);
    }
  }

  .c-header__menu-trigger,
  .c-header__bottom-in__controller-list {
    transition: transform 0.3s ease;
    will-change: transform;

    body.page-scroll-state & {
      transform: translateY(1rem);
    }
  }

  .c-header__nav {
    @include flex_block(row, wrap, flex-start, center, center);

    z-index: 1;

    // position: relative;

    width: 100%;
    height: 3.6rem;
    //padding: 1.2rem $gl_indent 2px;
  }

  .c-header__nav-in__link {
    transition: transform 0.3s ease, color 0.3s ease;

    body.page-scroll-state & {
      transform: translateY(1rem);
    }

    &:before {
      position: absolute;
      bottom: -1rem;
      left: -0.2rem;

      width: calc(100% + 0.4rem);
      height: 1px;
      background-color: getColor("light");

      opacity: 0;

      transition: opacity 0.3s, transform 0.3s;
      will-change: opacity;

      content: "";

      body.page-scroll-state & {
        transform: translateY(-0.5rem);
      }
    }

    &:hover,
    &:focus {
      color: getColor("primary");

      &::before {
        opacity: 1;
        background-color: black;
      }
    }

    &--active-link {
      &:before {
        opacity: 1;
      }
    }
  }

  .c-header__nav-in__link__icon {
    display: none;
  }

  .c-header__nav-in__list {
    margin: 0 0 -2rem 0;
    padding: 0;

    list-style: none;

    @include flex_block(row, wrap, center, center, center);
  }

  .c-header__nav-in__item {
    padding: 0 5rem 2rem 5rem;
  }

  .c-header__user-nav {
    display: none;
  }

  .c-header__customer-info {
    display: none;
  }

  .c-header__menu-trigger {
    display: none;
  }
}

//---------------------------------------------###