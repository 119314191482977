.c-header-mini {

	&.c-header-mini--style-mod {

		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;

		height: 6rem;
		width: 100%;
		padding: 0 var(--gl-indent);

		background-color: $white;
	}

	&.c-header-mini--border-mod {
		border-bottom: 1px solid getColor('gray');
	}
}

.header-mini__logo__w {
	display: block;
	width: 12.2rem;
	height: 4rem;
	margin: 0 auto;
}

.header-mini__logo__in {
	display: block;
	width: 100%;
	height: 100%;
}

.header-mini__logo {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.header-mini__arrows {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.header-mini__arrow {
	width: 2.2rem;
	height: 2rem;

	&:not(:last-child) {
		margin-right: 1.4rem;
	}
}

// ----------------- >= TABLET

@include responsiveFrom('md'){

	.c-header-mini {
		display: none;
	}

}


// ----------------- >= TABLET###


//------------------ < TABLET

@include responsiveTo('md'){

	.c-header-mini--style-mod {
		@include flex_block(row, wrap, flex-start, center, center)
	}

	.header-mini__arrows {
		.c-popup-product-description-zoom & {
			display: none;
		}
	}

}

//------------------ < TABLET###