@import "./c-product-label";

//---------------------------------------------c-product
.c-product {
	z-index: 2;
	position: relative;

	display: block;
	height: 100%;

	overflow: hidden;

	text-decoration: none;
	background-color: #fff;
	// FOR SWIPER SLIDER
	box-sizing: border-box;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:focus {
		@include default_focus_outline();
	}
}

.c-product__image-wrapper {
	position: relative;

	height: 0;
	margin-bottom: $space-l;
	padding-top: calc(4585.34 / 3840 * 100%);
	overflow: hidden;
}

.c-product__image {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.c-product__info {
	position: relative;
	z-index: 2;
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;

	.c-product.c-product--banner-mod & {
		text-align: center;
		padding: 0;
	}
}

.c-product__name {
	display: block;
	font-weight: 700;
	@extend %typo-text-2;
	color: getColor('primary');
	display: flex;
	justify-content: center;
	margin-bottom: $space-xs;
	text-align: center;
	flex-basis: 100%;
}

.c-product__descr {
	color: getColor('primary');
	display: block;
	@extend %typo-text-3;
	font-weight: 400;
	text-align: center;
	margin-bottom: $space-xs;
	flex-basis: 100%;
}

.c-product__price {
	// display: block;
	@extend %typo-text-2;
	font-weight: 400;

	&.c-product__price--new-price-mod {
		.c-product__price.c-product__price--old-price-mod+& {
			color: getColor('red');
		}
	}

	&.c-product__price--old-price-mod {
		@extend %product_price_line;
	}
}

//---------------------------------------------c-product###

@include responsiveTo('sm_2') {
	.c-product {

		.c-product-tabs & {
			padding: 0 1.9rem;
		}
	}
}

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
	.c-product {

		&.c-product--banner-mod {
			padding: 0 3rem;
		}

		.c-must-have-products & {
			padding: 0 3rem 2.8rem;
		}

		.cart__interested & {
			padding: .7rem 1.6rem 1.5rem;
		}
	}

	.c-product__image-wrapper {

		.c-product--small-mod & {}

		.c-product.c-product--banner-mod & {
			width: 13.1rem;
			height: 15.6rem;
			margin: 0 auto;
			padding: 0;
		}
	}

	.c-product__info {

		.c-cart-interested-products__slider__w & {
			width: 92%;
		}

		.c-must-have-products & {
			padding: 0;
		}

		.c-interested-products & {
			padding: 0 .4rem;
		}
	}

	.c-product__image-wrapper {
		margin: 0 auto $space-l;
		padding-top: 114.5%;
		width: 96%;

		.c-must-have-products & {
			margin: 0 auto 1rem;
			padding-top: 123.2%;
			width: 100%;
		}


		.cart__interested & {
			margin: 0 auto .8rem;
			padding-top: 119.5%;
			width: 100%;
		}

		.c-interested-products & {
			padding-top: 117.4%;
			margin-bottom: 0;
		}
	}

	.c-product__name {

		.c-must-have-products & {
			margin: 0 0 1.6rem;
		}

		.cart__interested & {
			margin: 0 0 1rem;
		}

		.c-product.c-product--banner-mod & {}
	}

	.c-product__descr {

		.c-must-have-products & {
			margin: 0 0 1.8rem;
		}

		.c-product.c-product--banner-mod & {
			margin-bottom: 1.2rem;
		}
	}

	.c-product__price {
		font-weight: 400;

		.c-product.c-product--banner-mod & {}

		.c-must-have-products & {
			letter-spacing: .02em;
		}
	}
}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-product {
		padding: 0 2.1rem 2.4rem;

		.c-must-have-products & {
			padding: 0 0 2.6rem;
		}

		.cart__interested & {
			padding: .7rem 1.7rem 2rem;
		}

		&.c-product--banner-mod {
			padding: 0 2rem;
		}

		.c-interested-products & {
			padding: 0;
		}
	}

	.c-product__image-wrapper {

		margin: 0 auto;
		padding-top: 119.8%;

		.c-product--small-mod & {}

		.c-must-have-products & {
			width: 69.4%;
			padding-top: 86.8%;
			margin: 0 auto .7rem;
		}

		.c-product.c-product--banner-mod & {
			width: 16.3rem;
			height: 19.4rem;
			margin: 0 auto -.5rem;
			padding: 0;
		}

		.cart__interested & {
			padding-top: 117.8%;
			margin-bottom: 1rem;
		}

		.c-interested-products & {
			width: 100%;
			padding-top: 119.8%;
			margin-bottom: .2rem;
		}
	}

	.c-product__info {
		max-width: 18.2rem;

		.cart__interested & {
			padding: 0 2.4rem;
			width: 91%;
		}

		.c-interested-products & {
			padding: 0;
			max-width: 18.2rem;
		}

		.c-must-have-products & {
			width: 75%;
		}
	}

	.c-product__name {
		.c-product.c-product--banner-mod & {}

		.cart__interested & {
			margin-bottom: .6rem;
		}

		.c-must-have-products & {
			margin-bottom: 1.6rem;
		}
	}

	.c-product__descr {

		.c-must-have-products & {
			margin-bottom: 1.8rem;
		}

		.c-product.c-product--banner-mod & {
			margin-bottom: 1.4rem;
		}

	}

	.c-product__price {

		.c-must-have-products & {
			letter-spacing: .02em;
		}

		.c-cart-interested-products__item & {}
	}
}

//---------------------------------------------TABLET ONLY###

//--------------------------------------------- >767
@include responsiveFrom('md') {
	.c-product__descr {
		.banner & {
			margin: 0 0 .2rem;
		}
	}
}

//--------------------------------------------- >767###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {
	.c-product {

		padding-bottom: 2.4rem;

		.c-must-have-products & {
			padding: 0 2.6rem 3.6rem;
		}

		&.c-product--banner-mod {
			padding: 0 3rem;
		}

		.c-interested-products & {
			padding: 1rem 0 4rem;
		}

		.cart__interested & {
			padding: .6rem 1.2rem 2rem;
		}
	}

	.c-product__info {
		padding: 0 2.4rem;
		max-width: 37.2rem;

		.cart__interested & {
			padding: 0 1.6rem;
		}
	}

	.c-product__name {

		.c-product--small-mod & {}

		.cart__interested & {
			margin: 0 0 1rem;
		}

		.c-must-have-products & {
			margin-bottom: 2.2rem;
		}

		.c-product.c-product--banner-mod & {}
	}

	.c-product__descr {

		.c-cart-interested-products__slider & {
			margin: 0 0 1.8rem;
		}

		.c-must-have-products & {
			margin: 0 0 2.2rem;
		}

		.c-product.c-product--banner-mod & {
			margin: 0 0 1.2rem;
		}
	}

	.c-product__price {
		font-weight: 400;

		.c-product--small-mod & {}

		.c-must-have-products & {}

		.cart__interested & {}

		.c-product.c-product--banner-mod & {}
	}

	.c-product__image-wrapper {
		margin-bottom: .8rem;
		padding-top: 118.3%;

		.c-must-have-products & {
			padding-top: 122.5%;
			width: 98.4%;
			margin: 0 auto .2rem;
		}

		.c-product--small-mod & {}

		.c-product.c-product--banner-mod & {
			width: 37.5rem;
			height: 46.6rem;
			margin: 0 auto 1rem;
			padding: 0;
		}

		.cart__interested & {
			padding-top: 118.5%;
			margin-bottom: .7rem;
		}

		.c-interested-products & {
			max-width: 41.9rem;
			margin: 0 auto -2.4rem;
			padding-top: 115.5%;
		}
	}

	.c-product__image {
		transition: transform .3s ease;

		.c-product:hover &,
		.c-product:focus & {
			transform: scale(1.05);
		}
	}
}

//---------------------------------------------DESKTOP ONLY###