@use "sass:math";
//-------------------------------------------------------inputs and selects
%form_field_default {
  display: block;
  width: 100%;
  height: $input_height;
  padding: 0 $space-m;
  text-align: left;
  color: $input_text_color;
  border: 1px solid getColor('primary');
  border-radius: $input_radius;
  background: none;
  outline: none;

  @extend %typo-text-2;

  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }


  .c-checkout-register-form__main__item__in--error-mod &,
  .form-field--error-mod & {
    border-color: getColor('error');
  }

  .form-field--disabled-mod & {
    background: getColor('gray');
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: getColor('gray-dark');
  }

  .footer-subscribe__form & {
    line-height: 1;

    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        line-height: 4.3rem;
      }
    }
  }


  &::-webkit-input-placeholder {
    color: $input_placeholder_color;
  }

  &::-moz-placeholder {
    color: $input_placeholder_color;
  }

  &:-ms-input-placeholder {
    color: $input_placeholder_color;
  }


}

%form_field_default_hover {
}


%form_field_default_active {
  border-color: getColor('primary');

  &.form-field__input--active-color-mod {
    border-color: getColor('gray-dark');
  }
}

.form-cell,
.form-field {
  position: relative;

  &--limit-2-mod {
    max-width: 46.2rem;
  }

  &.form-field--limit-3-mod {
    max-width: 57rem;
  }


  &--hidden-field {
    display: none;
  }

  &--offset-mod {
    margin: 0 0 1.4rem;

    .popup__form & {
      margin-bottom: .8rem;

      &:last-child {
        margin: 0;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &--offset-2-mod,
  &.form-field--offset-5-mod {
    margin: 0 0 2rem;

    &:last-child {
      margin: 0;
    }
  }

  &--offset-3-mod {
    margin: 0 0 .8rem;

    &:last-child {
      margin: 0;
    }
  }

  &--offset-4-mod {
    margin: 0 0 4.2rem;

    &:last-child {
      margin: 0;
    }
  }

  &--offset-6-mod {
    margin: 0 0 2.8rem;

    &:last-child {
      margin: 0;
    }
  }

  &.form-field--offset-7-mod {
    margin: 0 0 5.7rem;
  }

  &.form-field--offset-8-mod {
    margin: 0 0 1rem;
  }

  &.form-field--offset-9-mod {
    margin: 0 0 1.4rem;
  }

  &.form-field--offset-10-mod {
    margin: 0 0 2.6rem;
  }

  &.form-field--offset-11-mod {
    margin: 0 0 1.8rem;
  }

  .icon.icon-arrow.down {
    transform: rotate(-90deg);
    font-size: 1.2rem;
  }
}


.form-cell__title,
.form-field__label {
  display: block;
  margin-bottom: $space-xxs;

  @extend %typo-text-2;
  font-weight: 400;
  color: getColor('primary');


  .refound-guest-top__form & {
    letter-spacing: normal;
  }

  .c-checkout-payment__form & {
    letter-spacing: .028rem;
  }

  &--hline-hide-mod,
  &--hidden-mod {
    display: none;
  }

  .form-field.form-field--no-label-mod & {
    font-size: 0;
    margin: 0;
  }

  &.form-field__label--offset-mod {
    padding-left: 0;
  }
}

.form_field_wrap {
  position: relative;

  &--form-v1-mod {
  }
}

.form-field {

  &--default-mod,
  &__input {
    @extend %form_field_default !optional;

    &:focus {
      @extend %form_field_default_active !optional;
    }

    &:hover {
      @extend %form_field_default_hover !optional;
    }


    &.success {
      border-color: getColor('success');
    }

    &--v3-mod {
      padding: 0 4rem 0 0;

      font-weight: 300;

      border: none;
      border-bottom: 1px solid getColor('primary');
      border-radius: 0;

      &::placeholder {
        color: getColor('gray-dark');
      }
    }

    .form-field--limit-mod & {
      width: 100%;
      max-width: 46.2rem;
    }

    .cart__promocode & {
      height: 4.5rem;
    }
  }


  &--textarea-mod-1 {
  }

}

.field__input__custom {
  white-space: nowrap;
}

.form-field__input {
  .c-checkout-form__field & {
    letter-spacing: .025em;
  }
}

textarea.form-field__input {
  padding: $space-m;
}


.form-field__icon {
  z-index: 2;
  position: absolute;
  right: 1.2rem;
  top: 1rem;

  width: 2.6rem;
  height: 2.4rem;

  color: getColor('primary');

  cursor: pointer;

  .form-field__label ~ & {
    top: 2.7rem;

    .form-field--icon-style-mod & {
      top: 3.2rem;
    }
  }

  &.form-field__icon--size_mod {
    width: 3.6rem;
  }

  &:hover, &:focus {
    color: getColor('secondary')
  }

  .form-field--icon-style-mod & {
    right: 2.8rem;
    top: 1.1rem;
    width: 1.7rem;
    height: 1.7rem;
  }
}

.form-field__icon__wrap {
  width: 100%;
  height: 100%;

  pointer-events: none;

  &.form-field__icon__wrap--password-hidden-mod {
    .form-field__icon.form-field__icon--password-visible-mod & {
      display: none;
    }
  }

  &.form-field__icon__wrap--password-visible-mod {
    .form-field__icon:not(.form-field__icon--password-visible-mod) & {
      display: none;
    }
  }
}

.form-field__icon__hover {
  position: absolute;
  top: 50%;

  @extend %typo-text-2;
  font-weight: 700;
  color: getColor('primary');

  background-color: getColor('gray');
  border: 1px solid getColor('green');
  border-radius: .4rem;

  transform: translateY(-50%);

  opacity: 0;

  transition: opacity .3s;
  text-align: left;

  .form-field__icon:focus &,
  .form-field__icon:hover & {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;

    top: 50%;
    right: 100%;

    margin-top: -.8rem;

    border-style: solid;
    border-width: .8rem 1rem .8rem 0;
    border-color: transparent getColor('green') transparent transparent;
  }

  :after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.6rem;
    border-style: solid;
    border-width: 0.6rem 0.9rem 0.6rem 0;
    border-color: transparent getColor('gray') transparent transparent;

  }
}

.c-checkout-form__location-info {
  height: $input_height;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

//---------------------------------------------inputs and selects###

//---------------------------------------------checkboxes and radio-buttons


.form-field__label {
  margin-bottom: $space-xxs;

  &--radio-field,
  &--checkbox-field {
    display: inline-block;
    cursor: pointer;
  }

  &--flex-mod {
    @extend %global_flex_block_row_wrap_flex-start_center;
  }

  &.form-field__label--radio-field {
    padding: 0;
  }

  &.form-field__label--checkbox-field {
    padding: 0;
  }

  .form-field.form-field__input--textarea-mod & {
    padding: 0 0 $space-xxs;
    font-size: 1.4rem;
  }

  .cart__promocode & {
    padding-left: 0;
    margin-bottom: .5rem;
    font-weight: 700;
  }
}

.form-field__sublabel {
  @extend %typo-text-2;
  font-weight: 400;
  text-align: right;

  .popup--cart-login & {
    letter-spacing: .03em;
  }

  .form-field.form-field--sublabel-offset-mod & {
    margin: 0 0 -1.4rem;
  }

  .form-field.form-field--sublabel-offset-2-mod & {
    margin: 0 0 1.4rem;
  }

  .form-field.form-field--sublabel-offset-3-mod & {
    margin: 0 0 1rem;
  }

  .form-field.form-field--sublabel-offset-5-mod & {
    margin: 0 0 1.2rem;
  }

  .c-forgotten__form & {
    margin-bottom: 1rem;
  }

  &.form-field__sublabel--v1_mod {
    font-size: 1.4rem;
    text-align: left;
  }

  &.form-field__sublabel--v2_mod {
    margin: 0 0 2rem -1.2rem;

    font-size: 1.4rem;
    text-align: left;
  }
}

.form-field__error {
  margin-top: $space-xxs;
  @extend %typo-text-2;
  font-weight: 400;
  color: getColor('error');
}

.form-field__radiocheck {
  position: absolute;
  opacity: 0;
}

.form-field__text {
  text-transform: none;
}

.form-field__text--check {
  position: relative;
  @extend %typo-text-2;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  align-content: center;

  text-transform: none;
  color: getColor('primary');


  .form-field__radiocheck:disabled + & {
    text-decoration: line-through;
    color: getColor('gray-dark');
  }

  &:before, &:after {
    position: absolute;
    top: 50%;
    left: 0;

    cursor: pointer;
  }

  &:before {
    top: .9rem;

    content: '';
    border: 1px solid getColor('gray-dark');

    .form-field__radiocheck:disabled + & {
      background-color: getColor('gray-dark');
    }

    .form-field.form-field--error-mod & {
      border-color: getColor('error');
    }
  }

  &:after {
    @extend %transition_opacity;
    text-align: center;
    opacity: 0;
    content: '';

    border: solid getColor('primary');
    position: absolute;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-width: 0 0 .1rem .1rem;
    height: .5rem;
    width: 1rem;
    top: 5.5px;
    left: 4px;


    .form-field__radiocheck:checked + & {
      opacity: 1;
    }

    .form-field__radiocheck:disabled + & {
      background-color: getColor('gray-dark');
      border-color: getColor('gray-dark');
    }
  }


}

.form-field__text--radio {
  position: relative;
  display: inline-block;

  font-size: 1.4rem;
  line-height: 1.8rem;
  color: $black;

  .c-product-filter-nav__btn__dropdown & {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 100%;
    border: 1px solid #C4C4C4;
    cursor: pointer;

    .form-field.form-field--error-mod & {
      border-color: getColor('error');
    }
  }

  &:after {
    @extend %transition_opacity;

    content: '';
    position: absolute;
    top: 50%;
    left: .4rem;


    border-radius: 100%;
    background: getColor('primary');
    opacity: 0;

    .form-field__radiocheck:hover + & {
      opacity: .2;
    }

    .form-field__radiocheck:checked + & {
      opacity: 1;

    }
  }

  .form-field__label--v2-mod & {
    font-family: $base-font-family;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;

    &::before {
      top: 50%;
      transform: translateY(-50%);
      margin: 0;

      border-color: getColor('gray-dark');
    }

    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: .4rem;
      margin: 0;
    }
  }
}

.form-fields {
  &.form-fields--no-offset-mod {
    margin: 0;
  }
}

.form-field__dropdown {

  .popup & {
    padding-top: .6rem;
  }

  .c-checkout-form__row__tab & {
    margin-top: $space-xxs;
    margin-bottom: $space-l;
  }
}

.form-field__dropdown__link {

}

.form-field__dropdown__content {
  display: none;
  padding-top: 1.6rem;

  &.form-field__dropdown__content--active-mod {
    display: block;
  }
}

.form-field__input {
  &.form-field__input--select-wrapper-mod {
    position: relative;
    padding: 0;
  }

  .form-field.form-field__input--textarea-mod & {
    border-radius: .5rem;
    resize: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.form-field__select_wrap {
  position: relative;
  width: 100%;
}

.form-field__select {
  @extend %form_field_default;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: getColor('primary');

  &.success {
    border-color: getColor('success');
  }

  &:disabled {
    border-color: getColor('gray-dark');
    color: getColor('gray-dark');

    option {
      color: getColor('gray-dark');
    }
  }

  &.form-field__select--v2-mod {
    border: 1px solid getColor('gray-dark');
    border-radius: 2.6rem;
  }

  .c-account-subhead-block__nav & {
    font-weight: 700;
    line-height: 4rem;
    padding: 0 1.8rem;
  }

  .c-account-service-nav__select & {
    font-weight: 700;
    letter-spacing: .07em;
    padding: 0 1.8rem;
  }

  .form-field__input__select__arrow {
    position: absolute;
    right: 3%;
    z-index: unset;
    top: 2.2rem;
    bottom: unset;
  }

}


.form-field__input__select__arrow {
  z-index: 2;
  position: absolute;
  right: 1.8rem;
  top: 2.7rem;

  width: 3.6rem;
  height: 3.6rem;
  padding: 1.4rem 1.1rem;

  pointer-events: none;

  color: getColor('primary');
}

// ----------------- >= DESKTOP
@include responsiveFrom('lg') {
  #checkTermsAndPrivacyLabel {
    display: block;

  }
  .form-fields {
    margin: 0 0 5rem;

    .popup__form & {
      margin: 0 0 5.2rem;
    }
  }

  .form-field__sublabel {
    .c-checkout-form & {
      margin: 0 0 .8rem;
    }

    .c-register & {
      margin: 0 0 -1.2rem;
    }

    .form-field.form-field--sublabel-offset-3-mod & {
      margin: 0 0 2rem;
    }

    &.form-field__sublabel--v1_mod {
      margin: 0 0 2rem;
    }

  }

  .form-field.form-field--limit-4-mod {
    max-width: 44.4rem;
  }

  .form-field__select {
  }

  .form-field__select_wrap {
    &.form-field__select--limit-mod {
      width: 45rem;
    }
  }


  .form-field__dropdown {
    .c-checkout-form__row__tab & {

    }
  }
}

// ----------------- >= DESKTOP###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
  .form-field__dropdown__link {
  }

  .form-field {
    &--offset-mod {
      margin: 0 0 $space-l;

      .c-checkout-register-form & {
        margin: 0 0 $space-l;

        &:last-child {
          margin: 0;
        }
      }
    }

    &--offset-2-mod {
      margin: 0 0 $space-l;

      .c-checkout-register-form & {
        margin: 0 0 $space-l;

        &:last-child {
          margin: 0;
        }
      }
    }

    &--offset-3-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--width-mod {
      max-width: 45.5rem;
      margin: 0 0 0 auto;
    }
  }

  .form-field__text--check {
    padding: 0 0 0 3rem;
    min-height: 1.8rem;

    .form-field--size-check-mod & {
      min-height: 3rem;
      padding: 0 0 0 4.1rem;
    }

    &:before {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: -.9rem;

      .form-field--size-check-mod & {
        width: 3rem;
        height: 3rem;
      }

      .form-field--color-check-mod & {
        border-color: getColor('gray-medium-dark');
      }
    }

    &:after {


      .form-field__radiocheck:hover + & {
        opacity: .2;
      }

      .form-field__radiocheck:checked + & {
        opacity: 1;
      }

      .form-field--size-check-mod & {
        top: 1.1rem;
        left: 1.1rem;

        width: 1.8rem;
        height: 1.8rem;
      }
    }

    .form-field.form-field--no-label-mod & {
      padding: 0 0 0 1.8rem;
    }
  }

  .form-field__input {
    &--v3-mod {
      height: 5.8rem;
      font-size: 2.2rem;
    }
  }

}

//---------------------------------------------TABLET + DESKTOP###

@include responsiveFrom('md') {
  .form-field__icon__hover {
    left: calc(100% + 3.2rem);

    width: 21rem;
    padding: 1.4rem 2.4rem;

    font-size: 1.4rem;
  }

  .form-field__text--radio {
    padding: 0 0 0 2.6rem;

    &:before {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: math.div(-1.8rem, 2);
    }

    &:after {
      width: 1rem;
      height: 1rem;
      margin-top: math.div(-1rem, 2);
    }
  }
}

// ----------------- TABLET
@include responsiveFromTo('lg', 'md') {
  .form-fields {
    margin: 0 0 3rem;
  }

  .form-field__sublabel {
    .c-checkout-form & {
      margin: 0 0 .8rem;
    }

    .form-field.form-field--sublabel-offset-3-mod & {
      margin: 0 0 1.2rem;
    }

    .form-field.form-field--sublabel-offset-4-mod & {
      margin: 0 1rem -1.2rem 0;
    }

    .form-field.form-field--sublabel-offset-5-mod & {
      margin: 0 0 .6rem;
    }
  }

  .form-field.form-field--limit-mod .form-field__input {
    max-width: 36.2rem;
  }

  .form-field {

    &.form-field--offset-tab-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-8-mod {
      margin: 0 0 $space-l;
    }

    .popup__form & {
      &--offset-mod {
        margin: 0 0 $space-l;
      }

      &--offset-2-mod {
        margin: 0 0 $space-l;
      }

      &--offset-3-mod {
        margin: 0 0 $space-l;
      }
    }
  }

  .form-field__select {
  }

  .form-field__select_wrap {
    &.form-field__select--limit-mod {
      width: 36rem;
    }
  }

  //.form-field__input__select__arrow {
  //	.form-field--limit-mod & {
  //		right: 37.6rem;
  //	}
  //}

  .popup_link {
    margin-bottom: 2rem;
  }
}

// ----------------- TABLET###

// ----------------- < DESKTOP
@include responsiveTo('lg') {
  .form-field__sublabel {
    &.form-field__sublabel--v1_mod {
      margin: 0 0 1.6rem;
    }
  }
}

// ----------------- < DESKTOP###

@include responsiveFromTo('lg', 'md') {
  .form-field__text.form-field__text--radio {
    font-size: 1.8rem;
  }
}


//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
  #checkTermsAndPrivacyLabel {
    display: block;
  }
  .form-field__sublabel {
    .c-checkout-form & {
      margin: 0 0 1rem;
    }

    .form-field.form-field--sublabel-offset-3-mod & {
      margin: 0 0 2rem;
    }

    .form-field.form-field--sublabel-offset-4-mod & {
      margin: 0 1rem -1.2rem 0;
    }

    .form-field.form-field--sublabel-offset-5-mod & {
      margin: 0 0 .6rem;
    }
  }

  .form-field__dropdown__link {
    .popup & {
    }
  }


  .form-fields {
    margin: 0 0 2.6rem;

    &--offset-mob-mod {
      margin: 0 0 4.6rem;
    }
  }

  .form-field__label {
    &--checkbox_field {
      min-height: 3.2rem;
      margin-bottom: 0;
    }
  }

  .form-field__text--check {
    padding-left: 4rem;

    .form-field--mob-size-check-mod &,
    .form-field--size-check-mod & {
      padding-left: 4rem;
      min-height: 3.2rem;
    }

    &:before {
      width: 3rem;
      height: 3rem;
      top: 0;
    }

    &:after {
      height: .8rem;
      width: 1.3rem;

      top: .8rem;
      left: .9rem;
    }

    .form-field.form-field--no-label-mod & {
      padding: 0 0 0 3.2rem;
    }
  }

  .form-field__input {

    &--v3-mod {
      height: 3.4rem;
      font-size: 1.6rem;
    }
  }


  .form-field {
    &--offset-mod {
      margin: 0 0 $space-l;

      &:last-child {
        margin: 0;
      }
    }

    &--offset-4-mod {
      margin: 0 0 $space-l;

      &:last-child {
        margin: 0;
      }
    }

    &--offset-6-mod {
      margin: 0 0 $space-l;
    }


    &.form-field--offset-mob-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-mob-2-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-mob-3-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-mob-4-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-mob-5-mod {
      margin: 0 0 $space-l;
    }

    &.form-field--offset-8-mod {
      margin: 0 0 $space-l;
    }
  }

  .form-field__text--radio {
    padding: 0 0 0 4rem;

    &:before {
      width: 3rem;
      height: 3rem;
      margin-top: math.div(-3rem, 2);
    }

    &:after {
      width: 2rem;
      height: 2rem;
      left: .5rem;
      margin-top: math.div(-2rem, 2);

      .form-field--radio-color-mod & {
        background-color: getColor('light');
      }
    }

    .form-field__label--v2-mod & {
      font-size: 1.4rem;
      padding-left: 4rem;

      &:before {
        width: 3rem;
        height: 3rem;
      }

      &:after {
        width: 2rem;
        height: 2rem;
        left: .5rem;
      }
    }

    .c-product-filter__checkboxes--radio-buttons-mod & {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 4.2rem;
      min-height: 3rem;

      font-size: 1.4rem;

      &:before {
        width: 3rem;
        height: 3rem;
        margin-top: math.div(-3rem, 2);

        border-color: getColor('gray');
      }

      &:after {
        left: .5rem;

        width: 2rem;
        height: 2rem;
        margin-top: math.div(-2rem, 2);
      }
    }
  }

  .form-field__icon__hover {
    left: calc(100% + 1.4rem);
    width: 9rem;
    padding: 1.4rem 1.4rem;
    font-size: 1.2rem;
  }

  .form-field__text--check {
  }

  .form-field__dropdown {

  }
}

//---------------------------------------------MOBILE ONLY###

.address-wrapper, .city-region-wrapper {
  display: flex;

  .form-field--offset-9-mod {
    flex-basis: 100%;
    margin-right: 16px;
  }

  .form-field--offset-4-mod {
    max-width: 90px;
  }

  &.account-address-wrapper {
    flex-wrap: wrap;
  }
}