
:root {
    --grid-gap: 20;
    --main-container-pad-x: 20;
}

@include responsiveFrom('md') {
    :root {
        --main-container-pad-x: 30;
    }
}

$mainContainerPadX: calc(var(--main-container-pad-x) * 1px);
$mainContainerPadY: calc(var(--main-container-pad-x) * 0.5px);
