// pointer-events none to all children popup trigger button
[data-popup-name] {
  // border: 1px solid #000;
  * {
    pointer-events: none;
  }
}

%base-button {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  padding: toRem(16);
  min-width: toRem(260);
  width: 100%;
  height: toRem(58);

  @extend %typo-sub; //diventerà h7
  text-decoration: none;
  text-align: center;

  border-radius: 5rem;
  appearance: none;
  cursor: pointer;

  transition: all .3s;

  &:hover, &:focus {
    text-decoration: none;
  }

  &[data-default-label] {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: attr(data-default-label);
      display: block;
    }

    &:disabled {
      &:before {
        content: attr(data-disabled-label);
      }
    }
  }


  &.button--large,
  &.button--static-large {
    min-width: toRem(460);
  }

  &.button--size-3-mod {
    min-width: 24rem;
  }

  &.button--wide {
    width: 100%;
    min-width: 100%;
  }

  &.button--wide-text {
    letter-spacing: 1.8px;
  }

  &.stroked {
    border-color: currentColor;
  }

  .icon {
    color: inherit;
    font-size: 1.8rem;
    margin-right: $space-s;
  }
}

%base-black {
  @extend %base-button;
  background: getColor('primary');
  color: getColor('light');
  border: 2px solid getColor('primary');

  &:hover, &:focus {
    &:not([disabled]) {
      background: getColor('light');
      color: getColor('primary');
      border-color: getColor('primary');
      transform: scale(1.005);
    }
  }
}

button {
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

}


.button {
  &--primary {
    @extend %base-black;
  }

  &--secondary {
    @extend %base-button;
    background: transparent;
    color: getColor('primary');
    border: 2px solid getColor('primary');

    &:hover, &:focus {
      &:not([disabled]) {
        background: getColor('primary');
        color: getColor('light');
        border-color: getColor('primary');
      }
    }
  }

  &--tertiary {
    @extend %base-button;
    background: getColor('light');
    color: getColor('primary');
    border: 2px solid getColor('light');

    &:hover, &:focus {
      &:not([disabled]) {
        background: getColor('primary');
        color: getColor('light');
        border-color: getColor('light');
      }
    }
  }

  &--icon-only {
    @extend %base-black;
    height: 5rem;
    width: 5rem;
    min-width: unset;

    span {
      display: none;
    }

    .button__icon {
      margin: 0;
    }
  }

  &--secondary, &--primary,  &--tertiary,  &--icon-only {
    &:disabled {
      background: getColor('gray-medium-dark');
      color: getColor('gray-dark');
      border-color: getColor('gray-medium-dark')
    }
  }


  &__text {
    @extend %typo-text-2;
    background: transparent;
    color: getColor('primary');
    text-decoration: underline;
    font-weight: 700;
    padding: 0;
    margin: 0;

    &.support{
      color: getColor('gray-dark');
      font-weight: 400;
    }

    &.back{
      font-weight: 400;
      text-decoration: none;
    }
  }

  &--padding {

  }

  span {
    .body--macos_state & {
      padding-top: .4rem;
    }

    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        padding-top: .4rem;
      }
    }
  }
}

.button--offset-mod {
  margin: 0 0 2.4rem;
}

.button--offset-2-mod {
  margin: 0 0 2rem;
}

.button--offset-3-mod {
  margin: 0 0 1.5rem;
}

.button-wrap {
  &.button-wrap--center-mod {
    width: 100%;
    text-align: center;
  }

  &.button-wrap--offset-mod {
    margin: 0 0 2.4rem;
  }

  &.button-wrap--offset-2-mod {
    margin: 0 0 12.8rem;
  }

  &.button-wrap--offset-4-mod {
    margin: 0 0 3rem;
  }

  &.button-wrap--limit-2-mod {
    max-width: 46rem;
    width: 100%;
  }

  &.button-wrap--pos-mod {
    margin-left: auto;
  }

  &.button-wrap--end-mod {
    @extend %global_flex_block_column_nowrap_flex-start_flex-end;
  }
}

.svg-hamburger {

  .line-top,
  .line-center,
  .line-bottom {
    transform-origin: center;
    transition: transform .7s ease-in-out;
  }

  .line-top {
    transform: translateY(-25%);
  }

  .line-bottom {
    transform: translateY(25%)
  }

  &.open-status {
    .line-top {
      transform: rotate(45deg);
    }

    .line-center {
      transform: scale(0);
    }

    .line-bottom {
      transform: rotate(-45deg);
    }
  }
}

/*
SWATCH COLOR (circle)
<div class="swatch-color">
    <div class="swatch-color__box">
        <img src="" alt="swatch-img" title="dark-cosmo">
    </div>
</div>
*/
.swatch-color {
  --swatch-size: #{toRrem(33)};
  --swatch-color-size: #{toRrem(27)};
  width: var(--swatch-size);
  height: var(--swatch-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid transparent;

  &__box {
    width: var(--swatch-color-size);
    height: var(--swatch-color-size);
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid getColor('smoke');
  }

  &.selected {
    border-color: getColor('gray');
  }
}

/*
SIZE OPTION
It's a size input selector GENERALLY AT PDPages
<label class="size-option">
    <input type="radio" data-size-option="38" name="id" value="option-value">
    <span class="size-option__box">
        <span class="size-option__box__label">
            38
        </span>
    </span>
</label>
*/
.size-option {
  --size-option: #{toRrem(40)};
  --border-box-color: transparent;
  --size-text-decoration: none;
  font-size: toRem(14);
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked {
      & ~ * {
        //--border-box-color: #{getColor('primary')};
        --size-text-decoration: underline;
        font-weight: $font-bold;
      }
    }
  }

  &__box {
    width: var(--size-option);
    height: var(--size-option);
    border: 1px solid var(--border-box-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__label {
      text-decoration: var(--size-text-decoration);
    }
  }
}

// ----------------- < TABLET
@include responsiveTo('md') {
  .button-wrap {
    &.button-wrap--offset-3-mod {
      margin-bottom: 1.7rem;
    }

    &.button-wrap--offset-4-mod {
      margin-bottom: 4.6rem;
    }
  }

  .button--mob-height-mod {
    height: toRem(60);
    line-height: toRem(56);
  }

  .button--large {
    height: toRem(58);
   // line-height: toRem(56);
    min-width: unset;

    &.button--primary {
      width: 100%;
      min-width: unset;
      line-height: 2.4rem;
    }
  }

  .button--font-med {
    font-size: 1.6rem;
  }

  .button--mob-size {
    height: 6rem;
  }

  .button {
    &.button--size-mob-mod {
      width: 55%;
      min-width: unset;
    }

    &.button--size-7-mod {
      min-width: unset;
      width: 100%;
    }

    &.button--size-2-mod {
      min-width: 30rem;
    }
  }

  .button--size-4-mod {
    width: 100%;
    min-width: unset;
    font-size: 1.6rem;
  }


  .button--mobile-hiden-state {
    display: none;
  }


  .button.button--size-5-mod {
    width: 100%;
    min-width: auto;
  }

  .button.button--full-mob-mod {
    min-width: 100%;
  }

  .button-wrap.button-wrap--offset-mod {
    .c-login & {
      margin: 0 0 1.2rem;
    }
  }
}

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg') {


}

//---------------------------------------------TABLET + MOBILE###


//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
  .button {
    &.button--size-8-mod {
      min-width: 23.9rem;
    }

    &.button--tablet-size-mod {
      min-width: 31.6rem;
    }

    &.button--size-4-mod {
      min-width: 36rem;
    }

    &.button--offset-2-mod {
      margin-bottom: 1.6rem;
    }

    &.button--xl {
      min-width: 23.8rem;
    }

    &.button--size-7-mod {
      min-width: 36.4rem;
    }

    &.button--size-2-mod {
      min-width: 36rem;
    }
  }

  .button {

  }

  .button-wrap {

    &.button-wrap--limit-mod {
      width: 49%;
    }

    &.button-wrap--limit-2-mod {
      max-width: 49rem;
    }

    &.button-wrap--limit-tablet-mod {
      max-width: 38.4rem;
    }

    &.button-wrap--limit-tablet-2-mod {
      max-width: 46rem;
    }

    &.button-wrap--offset-mod {
      .c-login & {
        margin: 0 0 4rem;
      }
    }
  }
}

//---------------------------------------------TABLET ONLY###

// ----------------- >= TABLET
@include responsiveFrom('md') {
  .button {

    &.button--lg {
      min-width: 30.4rem;
    }
  }

  .button.button--size-5-mod {
    min-width: 36rem;
  }

  .button-wrap--offset-3-mod {
    margin-right: 1.8rem;
  }
}

// ----------------- >= TABLET###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {
  .button {
    &.button--size-8-mod {
      min-width: 31.6rem;
    }

    &.button--xl {
      min-width: 36rem;
    }
  }

  .button--primary {

    &.button--size-6-mod {
      min-width: 29.8rem;
    }
  }

  .button--secondary {

    &.button--size-6-mod {
      min-width: 29.8rem;
    }

    &.button--size-2-mod {
      min-width: 30rem;
    }

    &.button--size-7-mod {
      min-width: 30.4rem;
    }

    &.button--size-5-mod {
      min-width: 46rem;
    }

    &.button--offset-2-mod {
      margin: 0 0 2rem;
    }

    &.button--xl {
      min-width: 36rem;
    }

  }


  .button-wrap {

    &.button-wrap--limit-mod {
      width: 50%;
    }

    &.button-wrap--offset-mod {
      .c-login & {
        margin: 0 0 4.2rem;
      }
    }
  }
}

//---------------------------------MOBILE ONLY
@include responsiveTo('md') {
  .button--secondary {
    &.button--size-2-mod {
      min-width: 33rem;
    }
  }

  .button{
    &--icon-only{
      padding:0;
      height: 4rem;
      width: 4rem;
    }
  }
}
