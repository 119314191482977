.banner__image {
  @include responsiveFrom('md') {
    min-height: 38.3rem;
  }
  @include responsiveFrom('lg') {
    min-height: 45.6rem;
  }
  @include responsiveTo('md') {
    min-height: 64.2rem;
  }

  .section__in {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;

    .banner {
      height: 100%;
      padding: 0 $space-s;
      @include responsiveTo('md') {
        align-items: center;
      }
      @include responsiveFrom('md') {
        padding: 0;
        display: block;
        @include grid-tablet-margin;
      }

      @include responsiveFrom('lg') {
        @include grid-desktop-margin;
      }

      div.banner__col.banner__col--content-mod {
        padding: 0;

        @include responsiveTo('md') {
          width: 100%;
        }
        @include responsiveFrom('md') {
          text-align: left;
          min-height: unset;
          height: 100%;
          grid-column: 2 / 6;
        }

        @include responsiveFrom('lg') {
          grid-column: 2 / 8;
        }

        @include responsiveFrom('xl') {
          grid-column: 2 / 7;
        }

      }

      .banner__col__content {
        padding: $space-l;
        @include responsiveFrom('md') {
          min-height: unset;
          min-width: unset;
          display: flex;
          padding: $space-xl $space-l;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          justify-content: center;
        }

        @include responsiveFrom('lg') {
          padding: $space-xxl;
        }
      }

      .banner__col__content__title {
        @extend %typo-h4;
        color: getColor('light');
        font-weight: 400;
        padding: $space-xxs 0;
        margin-bottom: $space-xxs;
      }

      .banner__col__content__subtitle {
        color: getColor('light');
        font-weight: 400;
        padding: $space-xxs 0;
        margin-bottom: $space-xxs;
      }

      .banner__col__content__descr {
        font-weight: 400;
        color: getColor('light');
        padding: $space-l 0 $space-xl;
        margin-bottom: $space-xxs;
      }

      .button.button--tertiary {
        max-width: 30.5rem;
        width: 100%;
         @include responsiveTo('md'){
           max-width: unset;
         }
      }
    }
  }

  .banner__col__bg {
    position: static;
    width: 100%;
    height: auto;
    display: block;

    img {
      height: auto;
      min-height: 38.3rem;
      @include responsiveFrom('lg') {
        min-height: 45.6rem;
      }
      @include responsiveTo('md') {
        min-height: 64.2rem;
      }

    }

  }

}
