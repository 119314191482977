.section {
  position: relative;

  font-size: 1rem;

  &.section--indent-0-mod {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &.section--no-indent-mod {
    padding: 0;
  }

  &.section--vert-indent-mod {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.section--indent-2-mod {
    padding-top: 5.2rem;
    padding-bottom: 14rem;
  }

  &.section--indent-4-mod {
    padding: $space-xxl $space-s;
  }

  &.section--indent-5-mod {
    padding-bottom: 16rem;
  }

  &.section--indent-6-mod,
  &.section--indent-8-mod {
    padding: 0 var(--gl-indent);
  }

  &.mod_test {
    padding-top: 25rem;
  }

  &.section--flex-mod {
    flex: 1 0 auto;
    display: flex;
  }

  //section for clean layout padding ongly for distance from header
  &.section--account-indent-mod {
    padding: $space-xxl 0 0;
  }

  //section for clean layout padding y
  &.section--account-y-mod {
    padding-top: $space-xxl;
    padding-bottom: $space-xxl;
  }
  
  &.section--account-x-mod {
    padding-left: $space-s;
    padding-right: $space-s;

    &--2{
      padding: 0 $space-s;
    }
    &--bottom{
      padding-bottom: $space-xxl;
    }
  }

  //cart pages
  &.section--cart-indent-mod {
    padding: $space-xxl $space-s $space-4xl;
  }

  &.section--bottom-content-mod {
    @extend %global_flex_block_row_wrap_flex-start_flex-end;
  }

  &.section--height-2-mod {
    min-height: 101rem;
  }

  &.section--center-content-mod {
    @extend %global_flex_block_row_wrap_center_center;
  }

  &.section--product-mod {
    padding: $space-l 0;
  }
}

.section__in {
  max-width: $page_width;
  width: 100%;
  margin: 0 auto;

  &.section__in--limit-mod {
    max-width: 164rem;
  }

  &.section__in--limit-2-mod {
    max-width: 165.6rem;
  }

  &.section__in--limit-3-mod {
    max-width: 125rem;
  }

  &.section__in--full-mod {
    max-width: unset;
  }

  &.section__in--style-mod {
    position: relative;
  }

  &.section__in--limit-4-mod {
    max-width: 156rem;
  }

  &.section__in--limit-5-mod {
    max-width: $page_width_2;
  }

  &.section__in--product{
    padding-top: $space-l;
  }

  &.section__in--nopadding{
    padding: 0;
  }
}

.section__heading {
  &.section__heading--search-query-mod {
    padding: 2.2rem 0;
  }
}

.section__subtitle {
  font-family: $base-font-family;
  font-weight: 700;

  &.section-subtitle__color-mod {
    color: getColor('light');
  }

}

.section__descr {
  width: 100%;
  font-weight: 400;
  line-height: math(2, 1.8);
  color: getColor('primary');

  &.section__descr--offset-mod {
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.section__descr--offset-3-mod {
    margin-bottom: 8rem;
  }


  &.section-descr__color_mod {
    color: getColor('light');
  }

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

  picture {
    width: 100%;
    height: 100%;
  }

}

.section__bg__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  .section__bg--left-mod & {
    object-position: left;
  }
}

.section__banner-content {
  z-index: 3;

  position: relative;
  width: 100%;

  text-align: center;
}

.section__banner-content__title {
  @extend %title_font;

  font-weight: 700;
  color: $white;

  text-align: center;
  letter-spacing: .05em;
}

//---------------------------------------------MOBILE - DESKTOP

@include responsiveTo('lg') {
  .section__descr {
    font-size: 1.8rem;

    &.section__descr--md-mod {
      font-size: 1.8rem;
    }
  }
  .section {
    // specification
    &.space-top-margin-small,
    &.space-top-margin-medium,
    &.space-top-margin-big {
      margin-top: 3rem;
    }

    &.space-bottom-margin-small,
    &.space-bottom-margin-medium,
    &.space-bottom-margin-big {
      margin-bottom: 3rem;
    }
  }
}

//---------------------------------------------MOBILE - DESKTOP###

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md') {
  .section__banner-content__title {
    font-size: 4rem;
    margin: 0 0 1.3rem;
  }

  .section {
    padding: 7.6rem var(--gl-indent);

    &.section--indent-mod {
      padding-top: 1.8rem;
    }

    &.section--indent-6-mod {
      padding-top: 3.4rem;
      padding-bottom: 0;
    }

    &.section--full-img-banner {
      padding: 1.5rem var(--gl-indent);
      min-height: 55.6rem;
    }

    &.section--full-height-mod {
      min-height: calc(100vh - 7rem);
    }

    &.section--indent-2-mod {
      padding-top: 1.4rem;
      padding-bottom: 0;
    }


    &.section--indent-8-mod {
      padding: 0 var(--gl-indent);
    }

    &.section--product-mod {
      padding-bottom: 4rem;
    }


    &.section--indent-3-mod {
      padding-top: 2.8rem;
      padding-bottom: 1rem;
    }
  }

  .section__subtitle {
    font-size: 1.8rem;

    &.section-subtitle__offset-mod {
      margin: 0 0 1.6rem;
    }
  }

  .section__descr {

    &.section__descr--offset-2-mod {
      margin-bottom: 2.5rem;
    }

    &.section__descr--line-height-mod {
      line-height: 1.3;
    }
  }

  .section__descr {
    font-size: 1.6rem; // can change*

    &.section__descr--lg-mod {
      font-size: 1.8rem;
    }

    &.section__descr--line-height-mod {
      line-height: 1.3;
    }
  }

  .section__in {
    &.section__in--style-mod {
      padding: 5.2rem 1rem 3.4rem;
    }

    &.section__in--indent_mod {
      padding: 4.8rem var(--gl-indent) 19rem;
    }

    &.section__in--indent-2-mod {
      padding: 3rem 1rem 3.4rem;
    }

    &.section__in--limit-5-mod {
      padding-top: .8rem;
    }
  }

  // .section__title--offset-2-mod {
  // 	margin-bottom: 1.8rem;
  // }
}

//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
  .section__banner-content__title {
    font-size: 5rem;
    margin: 0 0 1.8rem;
  }

  .section {

    &.section--full-img-banner {
      padding: 4rem var(--gl-indent);
      min-height: 50.2rem;
    }

    &.section--full-height-mod {
      min-height: calc(100vh - 10rem);
    }

    &.section--indent-6-mod,
    &.section--indent-8-mod {
      padding-top: 3.8rem;
      padding-bottom: 0;
    }


    &.section--indent-2-mod {
      padding-top: 4rem;
      padding-bottom: 0;
    }

  }

  .section__subtitle {
    font-size: 2.2rem;

    &.section-subtitle__offset-mod {
      margin: 0 0 2.8rem;
    }
  }

  .section__descr {
    &.section__descr--offset-2-mod {
      margin-bottom: 2.4rem;
    }
  }

  .section__descr {

    &.section__descr--lg-mod {
      font-size: 1.8rem;
    }

    &.section__descr--md-mod {

    }

    &.section__descr--line-height-mod {
      line-height: 1.3;
    }
  }
  .section__in {
    &.section__in--style-mod {
      padding: 2.6rem 5.4rem 8.4rem;
    }

    &.section__in--v1-mod {
      max-width: 71rem;
    }

    &.section__in--indent_mod {
      padding: 8.2rem var(--gl-indent) 21rem;
    }

    &.section__in--indent-2-mod {
      padding: 8.2rem 12.4rem 10rem;
    }

    &.section__in--indent-3-mod {
      padding: 9.2rem var(--gl-indent) 21rem;
    }
  }
}

//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
  .section {
    padding: 7.6rem var(--gl-indent);

    &.section--indent-mod {
      min-height: calc(100vh - 20rem - 140px);
      padding-top: 0;
    }

    &.section--indent-3-mod {
      padding-top: 2.8rem;
      padding-bottom: 2.8rem;
    }

    &.section--indent-4-mod {
      padding: $space-xxl $space-l;
    }

    &.section--account-x-mod {
      padding-left: 0;
      padding-right: 0;
      &--2{
        padding-left: $space-l;
        padding-right: $space-l;
      }

    }

    &.section--cart-indent-mod {
      padding: $space-l $space-l $space-4xl;
    }
  }

  .section__banner-content {
    width: 100%;
    margin: 0 10rem;
  }
}

//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {

  .section__banner-content__title {
    font-size: 7rem;
    margin: 0 0 2.6rem;
  }

  .section__in--flex-mod {
    @include flex_block(row, nowrap, flex-start, stretch);
  }

  .section {

    &.section--full-img-banner {
      padding: 5.5rem var(--gl-indent);
      min-height: calc(100vh - var(--header-height));
    }

    &.section--full-height-mod {
      // min-height: 50.2rem;
      min-height: calc(100vh - var(--header-height));
    }

    &.section--indent-6-mod {
      padding: 4rem 18rem 10rem 8.6rem;
    }

    &.section--height-mod {
      // min-height: 106rem;
    }

    // specification
    &.space-top-margin-small {
      margin-top: 6rem;
    }

    &.space-top-margin-medium {
      margin-top: 8rem;
    }

    &.space-top-margin-big {
      margin-top: 10rem;
    }

    &.space-bottom-margin-small {
      margin-bottom: 6rem;
    }

    &.space-bottom-margin-medium {
      margin-bottom: 8rem;
    }

    &.space-bottom-margin-big {
      margin-bottom: 10rem;
    }

    &.section--account-indent-mod {
      padding-bottom: $space-xl;
    }

  }

  .section__subtitle {
    font-size: 2.8rem;

    &.section-subtitle__offset-mod {

      margin: 0 0 6.4rem;
    }
  }

  .section__in {
    &.section__in__login-service {
      padding-bottom: $space-4xl;
    }

    &.section__in--v1-mod {
      max-width: 84rem;
    }

    &.section__in--style-mod {
      padding: 3.5rem var(--gl-indent) 11.8rem;
    }

    &.section__in--indent_mod {
      padding: 9.3rem var(--gl-indent) 28.1rem;
    }

    &.section__in--indent-2-mod {
      padding: 7rem 15.6rem 10rem;
    }

    &.section__in--indent-3-mod {
      padding: 5.4rem var(--gl-indent) 21.5rem;
    }
  }

  .section__descr {
    font-size: 1.8rem;

    &.section__descr--md-mod {
      font-size: 2.2rem;
      line-height: 1.4;
    }

    &.section__descr--lg-mod {
      font-size: 3.2rem;
      line-height: 1.6;
    }

    &.section__descr--line-height-mod {
      line-height: 1.3;
    }

    &.section__descr--offset-2-mod {
      margin-bottom: 4.6rem;
    }


  }

}

//---------------------------------------------DESKTOP ONLY###

@include responsiveFrom('xxl') {
  .section {
    &.section--indent-side-mod {
      padding-right: 17rem;
      padding-left: 10rem;
    }
  }
}

@include responsiveFromTo('xxl_2', 'lg') {
  .section {
    &.section--indent-6-mod {
      padding: 4rem 4rem 10rem 4rem;
    }
  }
}
