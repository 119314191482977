
.swiper {
	.c-banner-carousel &,
	.c-banner-bg-carousel & {
		height: 100%;
	}
}

.c-banner-carousel__item {
	position: relative;
	display: flex;
	align-items: center;
}

.c-banner-carousel__wrap {
	display: flex;
	align-items: center;
	align-content: center;
	height: calc(100vh - var(--header-height));

	body.disable_top_bar_state & {
		height: calc(100vh - var(--header-height) + var(--c-header-top-height));
	}
}

.c-banner-carousel-content {
	position: relative;

	height: 100%;
	width: 100%;

	border: .5rem solid $white;
	border-radius: 3rem;

	&:before {
		content: '';
		position: absolute;
		border-radius: 3rem;
		border: .2rem solid $white;
		pointer-events: none;
	}
}

.c-banner-carousel-content-title {
	@extend %title_base;

	position: relative;
	z-index: 2;

	font-family: $base-font-family;
	color: $white;
	letter-spacing: 0.05em;
}

.c-banner-carousel {
	position: absolute;
	width: 100vw;
	height: 100%;
	left: 50%;
	top: 0;
	transform: translateX(-50%);

	.swiper {
		overflow: visible;
	}
}

.c-banner-carousel__img {
	position: absolute;
	// height: 0;
	left: 50%;
	transform: translateX(-50%);

	&:before {
		content: '';
		display: block;
		padding-bottom: 62.2%;
	}
}

.c-banner-carousel__img__in {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.c-banner-carousel__bg__img {
	@extend %section_bg_image_style;
}

.c-banner-bg-carousel {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.c-banner-bg-carousel__bg {
	width: 100%;
	height: 100%;
}

//---------------------------------------------MOBILE ONLY
@include responsiveTo('md'){
	.c-banner-carousel__wrap {
		padding: 8.2rem $gl_indent;
	}

	.c-banner-carousel-content-title {
		font-size: 3rem;
	}

	.c-banner-carousel-content {
		max-width: 27.8rem;
		max-height: 35rem;
		padding: 3.8rem 3rem;
		margin: -11% auto 0;

		&:before {
			top: .6rem;
			bottom: .6rem;
			right: .6rem;
			left: .6rem;
		}
	}

	.c-banner-carousel__img {
		width: clamp(1rem, 103vw, 38.5rem);
    bottom: -8.4rem;
    // height: 0;
    // padding-bottom: 64.8%;
	}
}
//---------------------------------------------MOBILE ONLY###

//---------------------------------------------TABLET + MOBILE
@include responsiveTo('lg'){
	.c-banner-carousel__wrap {
		display: flex;
		align-items: center;
	}
}
//---------------------------------------------TABLET + MOBILE###

//---------------------------------------------TABLET ONLY
@include responsiveFromTo('lg', 'md') {
	.c-banner-carousel__wrap{
		padding: 8.2rem $gl_indent 10.3rem;
	}

	.c-banner-carousel-content-title {
		font-size: 5rem;
		line-height: 1.36;
		letter-spacing: 0.05em;
	}

	.c-banner-carousel-content {
		max-width: 73rem;
		max-height: 38.9rem;
		padding: 2.6rem 3.8rem;

		&:before {
			top: 1.2rem;
			bottom: 1.2rem;
			right: 1.4rem;
			left: 1.4rem;
		}
	}

	.c-banner-carousel__img {
		bottom: -10rem;
    width: 55.1rem;
		// height: 0;
		// padding-bottom: 35.8%;
	}
}
//---------------------------------------------TABLET ONLY###

//---------------------------------------------TABLET + DESKTOP
@include responsiveFrom('md') {
	.c-banner-carousel__img {
		max-width: 100%;
	}

	.c-banner-carousel-content {
		margin: 0 auto;
	}
}
//---------------------------------------------TABLET + DESKTOP###

//---------------------------------------------DESKTOP ONLY
@include responsiveFrom('lg') {

	.c-banner-carousel__wrap {
		padding: 11.2rem $gl_indent 9.2rem;
	}

	.c-banner-carousel-content-title {
		line-height: 1.28;
		font-size: calc((100vw / 1920) * 96);
	}

	.c-banner-carousel-content {
		width: clamp(1rem, 67.6%, 127rem);
		max-height: clamp(1rem, 38.5vw, 73.8rem);
		padding: 4.8rem 7rem 4.8rem;

		&:before {
			top: 1.7rem;
			bottom: 1.7rem;
			left: 1.6rem;
			right: 1.6rem;
		}
	}

	.c-banner-carousel__img {
		bottom: -9.6rem;
    width: clamp(1rem, 49vw, 92.5rem);
		// padding-bottom: 29.8%;
	}
}
//---------------------------------------------DESKTOP ONLY###